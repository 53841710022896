;(function() {
/* @ngInject */
function CategoriesSliderController($document, $scope, $location, $filter, normalize, $rootScope) {
  let vm = this;
  
  vm.onLeftClick = onLeftClick;
  vm.onRightClick = onRightClick;
  vm.onImageClick = onImageClick;

  $scope.$on("update-subjects", function(event, data){
    onImageClick(data);
  });
  
  function onLeftClick() {
    let row = $document.find(".img_items_in_row");
    let keeper = $document.find(".img_row_keeper");

    let marginLeft = parseFloat(row.css("margin-left").replace("px", ""));

    let offset = 1;
    if (window.matchMedia('(min-width: 45em)').matches)
      offset = 0.8;

    let keeperWidth = keeper.width()*offset;

    let newMargin = marginLeft + keeperWidth;

    if(newMargin > 0) {
      newMargin = 0;
    }

    row.animate({
      marginLeft: newMargin + "px"
    }, 500);
  }
  
  function onRightClick() {
    let row = $document.find(".img_items_in_row");
    let keeper = $document.find(".img_row_keeper");

    let marginLeft = parseFloat(row.css("margin-left").replace("px", ""));
    let rowWidth = row.width();

    let offset = 1;
    if (window.matchMedia('(min-width: 45em)').matches)
      offset = 0.8;

    let keeperWidth = keeper.width()*offset;

    let newMargin = marginLeft - keeperWidth;

    if(rowWidth < (Math.abs(newMargin) + keeperWidth)) {
      newMargin = (rowWidth - keeperWidth ) * -1;
    }
    
    row.animate({
      marginLeft: newMargin + "px"
    }, 500);
  }

  function normalizeParam(tag) {
    return normalize(tag.toLowerCase().replace(new RegExp(" ", 'g'), "_").replace(/\./g,''));
  }

  function onImageClick(name) {
    console.log(name);
    $location.path("/" + normalizeParam($filter("translate")('businessSubjects.categories.' + name)));
    $scope.onCategoryClick({tag:name});
  }
}

function CategoriesSlider() {
  return {
    restrict: 'E',
    templateUrl: "/app/shared/categories-slider/categories-slider.html",
    controller: CategoriesSliderController,
    controllerAs: "vm",
    scope: {
      categories: "=",
      onCategoryClick: "&"
    }
  };
}


angular.module("firmaren")
  .controller("CategoriesSliderController", CategoriesSliderController)
  .directive("categoriesSlider", CategoriesSlider);
}());
