;(function() {
/* @ngInject */
function ReadyMadeKrok2Controller(ReadyMadeKrok2Service, $window, Helpers, $scope, _, UserService, $rootScope, ModalFinishLater, $interval, $filter, ModalForgotPassword, ModalStreetNumber) {
    let vm = this;
    let service = ReadyMadeKrok2Service;

    vm.cart = {};
    vm.valid = false;
    vm.onBackClick = onBackClick;
    vm.onSaveAndContinueClick = onSaveAndContinueClick;

    vm.onEmailChange = onEmailChange;
    vm.onPasswordChange = onPasswordChange;
    vm.onSaveForLaterClick = onSaveForLaterClick;
    vm.onResetCountry = onResetCountry;
    vm.onIcoChange = onIcoChange;
    vm.onCityChange = onCityChange;
    vm.onCodeChange = onCodeChange;
    vm.scrollTo = Helpers.scrollTo;
    vm.showModalForgotPassword = showModalForgotPassword;
    vm.onPersonalInfoClick = onPersonalInfoClick;
    vm.fullName = Helpers.fullName;
    vm.onClick = onClick;

    window.showSpinner(8);
    service.getMaxStep().then( (ms) => { vm.maxStep = ms;  window.hideSpinnerWhenAllResolved(); });
    service.getCart().then(onCartLoad);
    service.getCities().then(onCitiesLoad);
    service.getCountries().then(onCountriesLoad);
    service.getInvoiceInfo().then(onInvoiceInfoLoad);
    service.getOrder().then(onOrderLoad);
    service.getVoucher().then(onVoucherLoad);
    service.getCompanyName().then(onCompanyNameLoad);

    let server = UserService;

    vm.loggedUser = {};
    vm.user = {};
    vm.errorMessage = null;
    vm.showData = "";
    vm.userExists = false;
    vm.contactInfo = {};
    vm.order = {
        paymentInfo: {
            type: "",
            address: {},
            sendInvoice: true
        },
        contactInfo: {
            address: {}
        },
        additionalInfo: {}
    };
    vm.requiredMessage = false;
    vm.nextStep = 3;
    vm.loginSpinner = false;
    vm.loginDisabled = true;
    vm.showContactInfoForm = false;
    vm.showContactInfoDateOfBirth = false;
    vm.showContactInfoSelect = true;
    vm.persons = [];

    vm.dateOfBirth = {};
    vm.showStreetNumberModals = true;

    userLoad();

    $scope.$on('user-changed', function(event, args) {
        userLoad();
    });

    $scope.$on('on-password-change', function(event, data) {
        if(vm.order.contactInfo)
            vm.order.contactInfo.password = data;

        onPasswordChange();
    });

    $scope.$on('on-email-change', function(event, args) {
        onEmailChange();
    });

    function onClick(item, elem) {
        if(elem === "day") {
            vm.dateOfBirth.day = item;
            vm.mouseoverDay = false;
        } else if(elem === "month") {
            vm.dateOfBirth.month = item;
            vm.mouseoverMonth = false;
        } else if(elem === "year") {
            vm.dateOfBirth.year = item;
            vm.mouseoverYear = false;
        }
    }

    function onCompanyNameLoad(cn) {
        vm.companyName = cn;
        window.hideSpinnerWhenAllResolved();
    }

    function onPersonalInfoClick(elem, item) {
        vm.showContactInfoDateOfBirth = false;
        if(elem === "other") {
            vm.showContactInfoForm = true;
            vm.order.contactInfo.type = $filter('translate')('paymentInfo.other');
        } else {
            vm.showContactInfoForm = false;
            vm.contactInfo = item;
            vm.order.contactInfo.type = vm.fullName(item);

            if(!item.dateOfBirth)
                vm.showContactInfoDateOfBirth = true;
        }
        vm.mouseoverContactInfoType = false
    }

    function showModalForgotPassword() {
        ModalForgotPassword(vm.order.contactInfo.email)
            .add()
            .result
            .then();
    }

    function onVoucherLoad(voucher) {
        if(voucher && voucher.voucher) {
            vm.order.additionalInfo.voucher = voucher.voucher;
        }

        window.hideSpinnerWhenAllResolved();
    }

    function onOrderLoad(o) {
        vm.ico = o.ico;
        service.getBackContent().then((data) => {
            if(data) {
                vm.order = JSON.parse(data);
                userLoad();
            } else if(o && !vm.order.paymentInfo.ico) {
                //console.log(o);
                vm.order.paymentInfo.ico = o.ico;
                vm.order.paymentInfo.companyName = o.name;
                vm.order.paymentInfo.address = o.address;
                vm.order.paymentInfo.dic = o.dic;
                vm.order.paymentInfo.icdph = o.icDph;
                vm.order.paymentInfo.address.country = vm.countries[0]._2;
                //onIcoChange();
            }
        });

        if(vm.order.uploadType === 2)
            vm.orderNewCompany = true;
        else if(vm.order.uploadType === 1)
            vm.importFile = true;

        vm.persons = [];
        o.persons.forEach(person => {
            if(person.newData)
                vm.persons.push(person.newData);
            else vm.persons.push(person);
        });

        if(!vm.order.contactInfo)
            vm.order.contactInfo = {};

        if(vm.persons.length > 0)
            vm.contactInfo = vm.persons[0];
        else {
            vm.showContactInfoForm = true;
            vm.showContactInfoSelect = false;
        }

        console.log(vm.showContactInfoForm);
        console.log(vm.contactInfo.dateOfBirth);
        console.log(vm.loggedUser);

        if(!vm.showContactInfoForm && !vm.contactInfo.dateOfBirth)
            vm.showContactInfoDateOfBirth = true;

        vm.order.contactInfo.type = vm.fullName(vm.contactInfo);


        window.hideSpinnerWhenAllResolved();
    }

    function onCodeChange() {
        if(vm.order.additionalInfo.voucher) {
            service.setDiscount(vm.order.additionalInfo).then(({ success, error }) => {
                if(success) {
                    vm.voucherError = null;
                } else {
                    vm.voucherError = error;
                }
            });
        }
        onPriceChange();
    }

    function onPriceChange() {
        service.getCart().then(onCartLoad);
    }

    function onCityChange($item) {
        vm.order.paymentInfo.address.zipCode = $item && $item._3;
    }

    function onIcoChange() {
        service.getFinstatInfo({name: vm.order.paymentInfo.ico}).then( data => {
            if(data) {
                vm.order.paymentInfo.companyName = data.name;
                vm.order.paymentInfo.address = data.address;
                vm.order.paymentInfo.dic = data.dic;
                vm.order.paymentInfo.icdph = data.icdph;
                vm.order.paymentInfo.address.country = vm.countries[0]._2;
            }
        });
    }

    function onInvoiceInfoLoad(o) {
        if(o) {
            vm.order = o;
            if(!vm.order.contactInfo)
                vm.order.contactInfo = {};
            userLoad();
        } else {
            service.getStringContent().then((data) => {
                if (data) {
                    vm.order = JSON.parse(data);
                    userLoad();

                    if(vm.order.contactInfo) {
                        vm.contactInfo.name = vm.order.contactInfo.name;
                        vm.contactInfo.surname = vm.order.contactInfo.surname;
                        vm.contactInfo.address = vm.order.contactInfo.address;
                        vm.contactInfo.dateOfBirth = vm.order.contactInfo.dateOfBirth;
                    }
                }
            });
        }

        window.hideSpinnerWhenAllResolved();
    }

    function onCartLoad(c) {
        vm.cart.price = c.totalPrice;
        vm.cart.placeOfWork = c.placeOfBusinessPrice;
        vm.cart.size = c.subjectCount;

        window.hideSpinnerWhenAllResolved();
    }

    function onCitiesLoad(c) {
        vm.cities = c;

        window.hideSpinnerWhenAllResolved();
    }

    function onCountriesLoad(c) {
        vm.countries = c;
        vm.order.paymentInfo.address.country = vm.countries[0]._2;

        if(vm.order.contactInfo.address)
            vm.order.contactInfo.address.country = vm.countries[0]._2;

        window.hideSpinnerWhenAllResolved();
    }

    function checkStreetNumber() {
        let addresses = [vm.order.paymentInfo && vm.order.paymentInfo.address,
            vm.order.contactInfo && vm.order.contactInfo.address];

        var result = false;

        addresses.forEach(address => {
            if (address && address.streetNo && (!address.streetNoPrefix || address.streetNoPrefix === ""))
                result = true;
        });

        return result;
    }

    function onSaveAndContinueClick(steps) {
        vm.order.additionalInfo.comment = $filter("removeEmojiFilter")(vm.order.additionalInfo.comment);

        if($scope.step2.$invalid) {
            vm.requiredMessage = true;
            //scrollTo("content");
            vm.submitted=true;
        } else {
            if (vm.showStreetNumberModals && checkStreetNumber()) {
                ModalStreetNumber()
                    .add()
                    .result
                    .then();

                vm.showStreetNumberModals = false;
            } else {
                if (vm.orderNewCompany)
                    vm.order.uploadType = 2;
                else if (vm.importFile)
                    vm.order.uploadType = 1;
                else vm.order.uploadType = 0;

                if (!vm.showContactInfoForm) {
                    vm.order.contactInfo.name = vm.contactInfo.name;
                    vm.order.contactInfo.surname = vm.contactInfo.surname;
                    vm.order.contactInfo.address = vm.contactInfo.address;
                    vm.order.contactInfo.dateOfBirth = vm.contactInfo.dateOfBirth;
                }

                if (vm.showContactInfoDateOfBirth)
                    vm.order.contactInfo.dateOfBirth = vm.dateOfBirth;

                //console.log(vm.order.uploadType);
                userLoad();
                service.setInvoiceInfo(vm.order).then(({success, data}) => {
                    if (steps){
                        vm.valid = true;
                    } else {
                        $window.location = "krok3";
                    }
                });
            }
        }
    }

    function onBackClick() {
        service.setBackContent(JSON.stringify(vm.order)).then(({success, data}) => {
            if (success) {
                $window.location = "krok1?ico=" + vm.ico;
            } else {
                // todo validation
            }
        });
    }

    function userLoad() {
        onEmailChange();
        onPasswordChange();
        onUserChange();
    }

    function onPasswordChange(){
        if(vm.order.contactInfo && vm.order.contactInfo.email) {
            server.login({
                email: vm.order.contactInfo.email,
                password: vm.order.contactInfo.password
            }).then(({success, data}) => {
                if (success) {
                    vm.errorMessage = null;
                    onUserChange();
                    $rootScope.$broadcast('user-changed-contact');
                } else {
                    vm.errorMessage = "bad";
                }
            }).catch(vm.errorMessage = "bad");
        }
    }

    function onUserChange() {
        vm.errorMessage = null;
        server.getLoggedUser().then(onLoggedUserLoad);
    }

    function onLoggedUserLoad(user) {
        vm.loggedUser = user;
        if(!vm.order.contactInfo) {
            vm.order.contactInfo = {};
        }
        if(user) {
            vm.order.contactInfo.email = user.email;
            vm.order.contactInfo.phoneNumber = $filter("whitespaceFilter")(user.phoneNumber);
            vm.order.contactInfo.name = user.firstName;
            vm.order.contactInfo.surname = user.lastName;
            vm.order.contactInfo.address = user.address;
            vm.order.contactInfo.dateOfBirth = user.dateOfBirth;
            vm.order.contactInfo.hasCompany = user.hasCompany;
            vm.order.contactInfo.companyName = user.companyName;
            vm.order.contactInfo.ico = user.ico;
            vm.order.contactInfo.dic = user.dic;
            vm.order.contactInfo.icDph = user.icDph;

            if(user.firstName !== "" && user.lastName !== "") {
                vm.showContactInfoForm = true;
                vm.showContactInfoSelect = false;
            }

            service.getPrevOrder().then( (ms) => { if(!vm.order.uploadType) vm.orderNewCompany = ms; });
        }

        if(!vm.order.contactInfo.phoneNumber) {
            vm.order.contactInfo.phoneNumber = "+421";
        }

        if(vm.loggedUser)
            vm.showContactInfoDateOfBirth = false;
    }

    function onEmailChange() {
        vm.loginDisabled = true;
        vm.errorMessage = "";
        if(vm.order.contactInfo && vm.order.contactInfo.email) {
            vm.loginSpinner = true;
            server.isFree({email: vm.order.contactInfo.email}).then(({success, data}) => {
                vm.loginSpinner = false;
                vm.loginDisabled = false;
                if (success) {
                    vm.userExists = false;
                } else {
                    if (vm.order.contactInfo && vm.order.contactInfo.password && vm.order.contactInfo.password !== "")
                        onPasswordChange();
                    vm.userExists = true;
                }
            }).catch(vm.userExists = false);
        }
    }

    function onSaveForLaterClick() {
        if(!vm.showContactInfoForm) {
            vm.order.contactInfo.name = vm.contactInfo.name;
            vm.order.contactInfo.surname = vm.contactInfo.surname;
            vm.order.contactInfo.address = vm.contactInfo.address;
            vm.order.contactInfo.dateOfBirth = vm.contactInfo.dateOfBirth;
        }

        ModalFinishLater(service, JSON.stringify(vm.order))
            .add(service, JSON.stringify(vm.order))
            .result
            .then();
    }

    function onResetCountry() {
        vm.order.paymentInfo.address.country = "";
        $interval(function() {
            let element = $window.document.getElementById("address");
            if(element)
                element.focus();
        }, 100, 1);


    }
}

angular.module("firmaren")
    .controller("ReadyMadeKrok2Controller", ReadyMadeKrok2Controller);
}());
