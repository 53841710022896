;(function() {
/* @ngInject */
function AddressAutocompleteController($window, $timeout, $translate, Helpers, $filter) {
    let vm = this;

    $timeout(function() {

        vm.addressStreetNoPrefixFocus = false;
        vm.addressStreetNoFocus = false;
        vm.firstAnimOpen = true;
        vm.showAnimText = false;
        vm.showAnimationNo = true;
        vm.animLink = "";
        vm.lang = $translate.use();

        $window.gm_authFailure = function () {
            let elems = document.querySelectorAll('*[id^="address.street."]');

            elems.forEach((elem) => {
                elem.disabled = false;
                elem.placeholder = $filter("translate")('address.streetPlaceholder');
                elem.style.removeProperty("background-image");
            });

            console.log('General Error with Google Api ');
            return false;
        };

        let autocomplete;

        if (vm.lang !== 'en') {
            autocomplete = new google.maps.places.Autocomplete(
                document.getElementById('address.street.' + vm.id), {
                    fields: ['address_components'],
                    types: ['address'],
                    componentRestrictions: {country: "sk"}
                });

            // When the user selects an address from the drop-down, populate the
            // address fields in the form.
            autocomplete.addListener('place_changed', fillInAddress);
        }

        let componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            sublocality_level_1: 'long_name',
            locality: 'long_name',
            postal_code: 'short_name',
            administrative_area_level_2: 'short_name'
        };

        let nameModel = {
            street_number: 'streetNo',
            route: 'street',
            sublocality_level_1: 'city',
            locality: 'cityTmp',
            postal_code: 'zipCode',
            administrative_area_level_2: 'cityPart'
        };

        vm.onElementFocus = Helpers.onElementFocus;
        vm.onElementBlur = Helpers.onElementBlur;
        vm.onZipCodeChange = onZipCodeChange;
        vm.getAnimLink = getAnimLink;
        vm.onStreetNoChange = onStreetNoChange;

        function onStreetNoChange() {
            vm.showAnimationNo = !(vm.model.streetNo && vm.model.streetNo !== "");
            vm.showAnimText = (vm.model.streetNo && vm.model.streetNo !== "") && (!vm.model.streetNoPrefix || vm.model.streetNoPrefix === "") && !vm.addressStreetNoPrefixFocus;
        }

        function getAnimLink(focus) {
            if (focus && vm.firstAnimOpen) {
                vm.firstAnimOpen = false;
                vm.animLink = "/assets/images/anim/id_anim_" + (vm.addressStreetNoPrefixFocus ? "1" : "2") + ".gif" + "?a = " + Math.random();
            }
        }

        function fillInAddress() {
            let place = autocomplete.getPlace();

            vm.model.street = "";
            vm.model.streetNo = "";
            vm.model.streetNoPrefix = "";
            vm.model.city = "";
            vm.model.zipCode = "";

            place.address_components.forEach(item => {
                let addressType = item.types[0];
                if (componentForm[addressType]) {
                    if (addressType === "street_number" && item[componentForm[addressType]].indexOf("/") !== -1) {
                        let streetNumbers = item[componentForm[addressType]].split("/");
                        vm.model.streetNoPrefix = streetNumbers[0];
                        vm.model.streetNo = streetNumbers[1];
                    } else {
                        vm.model[nameModel[addressType]] = item[componentForm[addressType]];
                    }
                }
            });

            if (!vm.model.city)
                vm.model.city = vm.model.cityTmp;

            if(vm.model.cityPart && vm.model.cityPart !== vm.model.city && vm.model.cityPart.indexOf("Bratislava") !== -1)
                vm.model.city = "Bratislava";

            if(vm.model.cityPart && vm.model.cityPart !== vm.model.city && vm.model.cityPart.indexOf("Košice") !== -1)
                vm.model.city = "Košice";

            vm.onElementFocus("address.city." + vm.id);
            vm.onElementBlur("address.city." + vm.id);
        }

        function onZipCodeChange($item) {
            vm.model.zipCode = $item && $item._3;
        }
    });
}

angular.module("firmaren")
    .component("addressAutocomplete", {
        templateUrl: "/app/shared/address-autocomplete/address-autocomplete.html",
        controller: AddressAutocompleteController,
        controllerAs: "vm",
        bindings: {
            model: "=",
            submitted: "=",
            form: "=",
            cities: "=",
            country: "=",
            required: "=",
            streetLabel: "=",
            elem: "=",
            nameStreet: "=",
            id: "=",
            oldAddress: "="
        }
    });
}());
