;(function() {
/* @ngInject */
function stepsFactory(routes) {
  return {
    sro: {
      zalozenie: [
        { label: "sro.zalozenie.krok1.label", route: routes.sro.zalozenie.krok1 },
        { label: "sro.zalozenie.krok2.label", route: routes.sro.zalozenie.krok2 },
        { label: "sro.zalozenie.krok3.label", route: routes.sro.zalozenie.krok3 },
        { label: "sro.zalozenie.krok4.label", route: routes.sro.zalozenie.krok4 },
        { label: "sro.zalozenie.krok5.label", route: routes.sro.zalozenie.krok5 }
      ],
      zmena: [
        { label: "sro.zmena.krok1.label", route: routes.sro.zmena.krok1 },
        { label: "sro.zmena.krok2.label", route: routes.sro.zmena.krok2 },
        { label: "sro.zmena.krok3.label", route: routes.sro.zmena.krok3 },
      ],
      readymade: [
        { label: "sro.readymade.krok1.label", route: routes.sro.readymade.krok1 },
        { label: "sro.readymade.krok2.label", route: routes.sro.readymade.krok2 },
        { label: "sro.readymade.krok3.label", route: routes.sro.readymade.krok3 },
      ],
      usszmena: [
        { label: "sro.zmena.krok1.label", route: routes.sro.usszmena.krok1 },
        { label: "sro.zmena.krok2.label", route: routes.sro.usszmena.krok2 },
        { label: "sro.zmena.krok3.label", route: routes.sro.usszmena.krok3 },
      ],
    },
    zivnost: {
      zalozenie : [
        {label: "zalozenieZivnosti.krok1.label", route: routes.zivnosti.zalozenie.krok1},
        {label: "zalozenieZivnosti.krok2.label", route: routes.zivnosti.zalozenie.krok2},
        {label: "zalozenieZivnosti.krok3.label", route: routes.zivnosti.zalozenie.krok3}
      ],
      rozsirenie : [
        {label: "zalozenieZivnosti.krok1.label", route: routes.zivnosti.rozsirenie.krok1},
        {label: "zalozenieZivnosti.krok2.label", route: routes.zivnosti.rozsirenie.krok2},
        {label: "zalozenieZivnosti.krok3.label", route: routes.zivnosti.rozsirenie.krok3}
      ],
      zmena : [
        {label: "zmenaZivnosti.krok1.label", route: routes.zivnosti.zmeny.krok1},
        {label: "zmenaZivnosti.krok2.label", route: routes.zivnosti.zmeny.krok2}
      ]
    },
    obcianske: [
      {label: "zalozenieOZ.krok1.label", route: routes.sluzby.obcianske.krok1},
      {label: "zalozenieOZ.krok2.label", route: routes.sluzby.obcianske.krok2},
      {label: "zalozenieOZ.krok3.label", route: routes.sluzby.obcianske.krok3}
    ],
    ussobcianske: [
      {label: "zalozenieOZ.krok1.label", route: routes.sluzby.ussobcianske.krok1},
      {label: "zalozenieOZ.krok2.label", route: routes.sluzby.ussobcianske.krok2},
      {label: "zalozenieOZ.krok3.label", route: routes.sluzby.ussobcianske.krok3}
    ],
    burza: [
      {label: "burza.krok1.label", route: routes.sluzby.burza.krok1},
      {label: "burza.krok2.label", route: routes.sluzby.burza.krok2}
    ],
    virtualnesidlo: [
      {label: "virtualneSidlo.krok1.label", route: routes.virtualneSidlo.krok1},
      {label: "virtualneSidlo.krok2.label", route: routes.virtualneSidlo.krok2},
      {label: "virtualneSidlo.krok3.label", route: routes.virtualneSidlo.krok3}
    ],
    konverzia: [
      {label: "konverzia.krok1.label", route: routes.sluzby.konverzia.krok1},
      {label: "konverzia.krok2.label", route: routes.sluzby.konverzia.krok2}
    ],
    en: {
      zivnost: {
        zalozenie: [
          {label: "zalozenieZivnosti.krok1.label", route: routes.en.zivnosti.zalozenie.krok1},
          {label: "zalozenieZivnosti.krok2.label", route: routes.en.zivnosti.zalozenie.krok2},
          {label: "zalozenieZivnosti.krok3.label", route: routes.en.zivnosti.zalozenie.krok3}
        ]
      }
    }
  };
}

/* @ngInject */
function onStart(steps, $rootScope) {
  $rootScope.steps = steps;
}

angular.module("firmaren")
  .factory("steps", stepsFactory)
  .run(onStart);
}());
