;(function() {
/* @ngInject */
function SimpleSelectController($filter) {
  let vm = this;

  vm.onClick = onClick;
  
  function onClick(item) {
    if(vm.object) {
      vm.model.type = item.value;
      vm.model.name = item.name;
    } else vm.model = vm.prefix && !vm.nottranslated ? $filter('translate')(vm.prefix + item) : item;
    if(vm.help) {
      vm.help = vm.prefix ? $filter('translate')(vm.prefix + item) : item;
    }
    vm.mouseover = false;
    vm.show = false;
  }
}

angular.module("firmaren")
  .component("simpleSelect", {
    templateUrl: "/app/shared/simple-select/simple-select.html",
    controller: SimpleSelectController,
    controllerAs: "vm",
    bindings: {
      items: "=",
      model: "=",
      show: "=",
      prefix: "=",
      nottranslated: "=",
      help: "=",
      object: "="
    }
  });
}());
