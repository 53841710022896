;(function() {
/* @ngInject */
function RozsirenieZivnostiKrok1Controller(RozsirenieZivnostiKrok1Service, BusinessSubjects, routes, $window, $scope, ModalFinishLater, $location) {
  let vm = this;
  let server = RozsirenieZivnostiKrok1Service;

  vm.maxStep = 1;
  vm.valid = false;
  vm.subjects = BusinessSubjects;
  vm.onSaveAndContinueClick = onSaveAndContinueClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.onTagClick = onTagClick;
  vm.totalPrice = 0;
  vm.onLeftClick = onLeftClick;
  vm.onRightClick = onRightClick;
  vm.nextStep = 2;

  window.showSpinner(4);
  server.getSubjects().then( (subjects) =>  { BusinessSubjects.init(subjects); window.hideSpinnerWhenAllResolved(); } );
  server.getMaxStep().then( (ms) => { vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); } );
  server.getSelectedSubjects().then(onSelectedSubjectsLoad);
  server.getTotalPrice().then( (tp) =>{ vm.totalPrice = tp; window.hideSpinnerWhenAllResolved(); });

  vm.categories = [
    {name: "graphic", image: "/assets/images/graphic.png"},
    //{name: "blog", image: "/assets/images/designer.png"},
    {name: "handmade", image: "/assets/images/handmade.png"},
    //{name: "bar", image: "/assets/images/pilic.png"},
    //{name: "restaurant", image: "/assets/images/eshop.png"},
    //{name: "bakery", image: "/assets/images/bakery.png"},//
    {name: "traffic", image: "/assets/images/traffic.png"},
    {name: "administration", image: "/assets/images/administration.png"},
    {name: "architecture", image: "/assets/images/architecture.png"},
    {name: "science", image: "/assets/images/science.png"},
    {name: "agriculture", image: "/assets/images/agriculture.png"},
    //{name: "clothing", image: "/assets/images/clothing.png"},//
    {name: "accounting", image: "/assets/images/accounting.png"},
    {name: "health", image: "/assets/images/health.png"},
    //{name: "language", image: "/assets/images/pilic.png"},
    //{name: "finance", image: "/assets/images/eshop.png"},
    {name: "advertisement", image: "/assets/images/marketing.png"},
    //{name: "software", image: "/assets/images/murar.png"},
    {name: "course", image: "/assets/images/course.png"},
    //{name: "courier", image: "/assets/images/designer.png"},
    //{name: "eshop", image: "/assets/images/murar.png"},
    {name: "business", image: "/assets/images/business.png"}
  ];

  BusinessSubjects.onChange($scope, ($event, subjects) => onSelectionChange(subjects));

  function onSelectedSubjectsLoad(ss) {
    if (angular.isDefined(ss)) {
      BusinessSubjects.select(ss.subjects);
    }
    window.hideSpinnerWhenAllResolved();
  }

  function onSelectionChange(subjects) {
    server.setSelectedSubjects({ subjects })
      .then(tp => vm.totalPrice = tp);
  }

  function onSaveAndContinueClick(steps) {
    onSelectionChange(vm.subjects.selected);

    server.saveAndContinue(false).then(data => {
      if (data.success) {
        if (steps) {
          vm.valid = true;
        } else {
          $window.location = routes.zivnosti.rozsirenie.krok2;
        }
      }
      else {
        vm.input.hasError = true;
        vm.input.message = data;
      }
    });
  }

  function onTagClick(tag) {
    console.log(tag);
    BusinessSubjects.selectByTag(tag);
  }

  function onSaveForLaterClick() {
    ModalFinishLater(server)
      .add(server)
      .result
      .then();
  }

  function onLeftClick() {
  }

  function onRightClick() {

  }
}

angular.module("firmaren")
  .controller("RozsirenieZivnostiKrok1Controller", RozsirenieZivnostiKrok1Controller);
}());
