;(function() {
/* @ngInject */
function BusinessSubjectsResultsTableController(normalize, $filter, $timeout) {
  let vm = this;

  vm.foundSubstr = foundSubstr;
  vm.addSubject = addSubject;
  vm.isContent = isContent;
  vm.toggleInfo = toggleInfo;

  let promise;

  function toggleInfo(s) {
    let tmp = s.isShown;
    vm.results.forEach(res => {
      res.isShown = false;
    });
    s.isShown = !tmp;

    $timeout(function () {
      scrollTo(s.id);
    });
  }

  function scrollTo(scrollLocation) {
    if (window.matchMedia('(min-width: 45em)').matches) {
      $("#result-table-desktop-scroll").animate({
        scrollTop: $("#" + scrollLocation).position().top
      }, 500);
    } else {
      $('html, body').animate({
        scrollTop: $("#mobile-" + scrollLocation).offset().top
      }, 500);
    }
  }

  function addSubject(subject, isRemove, $event) {
    if(vm.isSelected(subject)) {
      if(isRemove)
        vm.deselect(subject, $event, true);
      else {
        if($event) {

          if(promise)
            $timeout.cancel(promise);

          if (window.matchMedia('(min-width: 45em)').matches) {
            $('#info_popup').css({'top': ($event.clientY - ($("#business_subject_search").offset().top - (window.scrollY || window.pageYOffset || document.body.scrollTop)) - 22) + 'px', 'left': $event.clientX - $("#business_subject_search").offset().left + 'px'});
          }

          $('#info_popup').show();

          promise = $timeout(function () {
            $('#info_popup').hide();
          }, 2000);
        }
      }
    } else {
      if (vm.selected.length >= 30) {
        if($event) {
          if(promise)
            $timeout.cancel(promise);

          if (window.matchMedia('(min-width: 45em)').matches) {
            $('#limit_popup').css({'top': ($event.clientY - ($("#business_subject_search").offset().top - (window.scrollY || window.pageYOffset || document.body.scrollTop)) - 22) + 'px', 'left': $event.clientX - $("#business_subject_search").offset().left + 'px'});
          }

          $('#limit_popup').show();

          promise = $timeout(function () {
            $('#limit_popup').hide();
          }, 5000);
        }
      } else {
        if(subject.type === "1" || subject.type === "2")
          subject.isShownForm = true;
        else vm.select(subject, $event);
      }
    }
    if(vm.isSearchBar)
      $('#btn_search_bar').select();
  }

  function foundSubstr(str) {
    let searchStr = normalize(vm.results[vm.results.length-1].name);
    let position = normalize(str).indexOf(searchStr);

    if(position !== -1) {
      var substr = str.substring(position, position + searchStr.length);
      return str.replace(substr, "<span class='found'>" + substr + "</span>");
    } else return str;
  }

  function isContent(content, name){
      let searchStr = normalize(vm.results[vm.results.length-1].name);
      return (content.indexOf(searchStr) !== -1 && name.indexOf(searchStr) === -1);
  }
}

angular.module("firmaren")
  .component("businessSubjectsResultsTable", {
    templateUrl: "/app/shared/business-subjects-results-table/business-subjects-results-table.html",
    controller: BusinessSubjectsResultsTableController,
    controllerAs: "vm",
    bindings: {
      results: "=",
      select: "=",
      deselect: "=",
      isSelected: "=",
      founders: "=",
      selected: "=",
      isSearchBar: "=",
      isChangeCompany: "=",
      lang: "="
    }
  });
}());
