;(function() {
/* @ngInject */
function ZalozenieZivnostiObjednaneController(ZalozenieZivnostiPaymentsService, $window, $filter, ModalInfo, $scope, Helpers){
  let vm = this;
  let server = ZalozenieZivnostiPaymentsService;

  vm.onPayClick = onPayClick;
  vm.generateDocs = generateDocs;
  vm.generateForeignerDocs = generateForeignerDocs;
  vm.getFileName = Helpers.getFileName;
  vm.payment = "";
  vm.errorPayment = false;
  vm.showMovement = false;
  vm.isUnzipFiles = true;
  vm.showLoading = false;
  vm.showProforma = false;
  vm.hideDownloadDocuments = true;
  vm.downloadLabel = $filter("translate")('payments.documents.zivnost.zaloz');

  $scope.$watch('vm.payment', function() {
    if(vm.payment !== "")
      vm.errorPayment = false;
  }, true);

  server.getPaymentInfo().then(onPaymentInfoLoad);

  let dataLayerStorage = $window.localStorage.getItem('dataLayer');
  $window.localStorage.removeItem('dataLayer');
  var dataLayer = $window.dataLayer = $window.dataLayer || [];
  if (dataLayerStorage)
    dataLayer.push(JSON.parse(dataLayerStorage));

  function onPaymentInfoLoad(payment) {
    if(payment) {
      vm.orderId = payment.orderId;
      vm.price = payment.price;
      vm.hideDownloadDocuments = payment.hideDownloadDocuments;
      vm.paymentData = payment;
      vm.invoiceHref = `/invoice/download?orderId=${payment.orderId}`;
      vm.proformaHref = `/invoice/download?proforma=true&orderId=${payment.orderId}`;
      vm.documentsHref = `/objednavka/detail?o=${payment.documentsLink}`;
      vm.documentsForeignerHref = `/objednavka-cudzinci/detail?o=${payment.documentsLink}`;

      if (Date.now() < new Date("2021-03-01") && vm.paymentData.nameForeignerAtt.length > 0 && payment.price !== payment.paidAmount)
        ModalInfo("sro.actualInfoTitle", "sro.actualInfoText", "buttons.understand")
            .add()
            .result
            .then();

    } else {
      $window.location = "krok1";
    }
  }

  function generateDocs() {
    vm.showLoading = true;
    server.regenerateDocs().then(() => {
      server.getPaymentInfo().then((p) => {
        if(p.documentsLink) {
          let url = '/objednavka/detail?o=' + p.documentsLink;
          $window.location = url;
        }
      });
      vm.showLoading = false;
    });
  }

  function generateForeignerDocs() {
    vm.showLoading = true;
    server.regenerateForeignerDocs().then(() => {
      server.getPaymentInfo().then((p) => {
        if(p.documentsLink) {
          let url = '/objednavka-cudzinci/detail?o=' + p.documentsLink;
          $window.location = url;
        }
      });
      vm.showLoading = false;
    });
  }

  function onPayClick() {
    if (vm.payment !== "") {
      if (vm.payment === "movement") {
        if (vm.paymentData.hash && vm.paymentData.hash !== "") {
          let url = '/bank-transfer/' + vm.paymentData.hash;
          $window.location = url;
        } else vm.showMovement = true;
      } else {
        server.pay({payment: vm.payment}).then(({success, data, error}) => {
          if (success) {
            //console.log(data);
            $window.location = data;
          }

        })
      }
    } else {
      vm.errorPayment = true;
    }
  }
}

angular.module("firmaren")
  .controller("ZalozenieZivnostiObjednaneController", ZalozenieZivnostiObjednaneController);
}());
