;(function() {
/* @ngInject */
function ModalTatraBanka($uibModal, $rootScope) {
    let title;
    let desc;
    let discount;

    return function init(title_, desc_, discount_) {
        title = title_;
        desc = desc_;
        discount = discount_;

        return { add };
    };

    function open(options = {}) {
        return $uibModal.open(angular.extend({
            templateUrl: "/app/shared/modal-tatra-banka/modal-tatra-banka.html",
            controller: "ModalTatraBankaController",
            controllerAs: "vm",
            bindToController: true,
            backdrop: "static",
            resolve: {
                title: () => title,
                desc: () => desc,
                discount: () => discount
            },
            size: "md",
        }, options));
    }

    function add(scope = {}) {
        return open({ scope: angular.extend($rootScope.$new(true), scope) })
    }
}

/* @ngInject */
function ModalTatraBankaController($uibModalInstance, title, desc, discount) {
    let vm = this;

    vm.title = title;
    vm.desc = desc;
    vm.discount = discount;

    vm.close = () => $uibModalInstance.close("cancel");

    vm.confirm = () => $uibModalInstance.close("confirm");

}

angular.module("firmaren")
    .factory("ModalTatraBanka", ModalTatraBanka)
    .controller("ModalTatraBankaController", ModalTatraBankaController);
}());
