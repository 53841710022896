;(function() {
/* @ngInject */
function ModalRedirectToExtension($uibModal, $rootScope) {
  let service, link, callFunction;

  return function init(service_, link_, callFunction_) {
    service = service_;
    link = link_;
    callFunction = callFunction_;

    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-extension/modal-extension.html",
      controller: "ModalRedirectToExtensionController",
      controllerAs: "vm",
      bindToController: true,
      size: "md",
      resolve: {
        service: () => service,
        link: () => link,
        callFunction: () => callFunction
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) })
  }
}

/* @ngInject */
function ModalRedirectToExtensionController($uibModalInstance, $window, $rootScope, service, link, callFunction) {
  let vm = this;

  vm.service = service;
  vm.link = link;
  vm.callFunction = callFunction;
  
  vm.redirect = redirect;
  
  function redirect() {
    if(vm.callFunction)
      $rootScope.$broadcast('set-string-content');
    else $window.location = vm.link;
    vm.close();
  }

  vm.close = () => $uibModalInstance.dismiss("cancel");
}

angular.module("firmaren")
  .factory("ModalRedirectToExtension", ModalRedirectToExtension)
  .controller("ModalRedirectToExtensionController", ModalRedirectToExtensionController);
}());
