;(function() {
/* @ngInject */
function BurzaFiriemKrok2Controller(BurzaFiriemKrok2Service, UserService, Helpers, $window, ModalFinishLater) {
  let vm = this;
  let server = BurzaFiriemKrok2Service;

  let userService = UserService;

  vm.maxStep = 2;
  vm.onFinishClick = onFinishClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.dateToString = Helpers.dateToString;
  vm.onBackClick = onBackClick;
  vm.scrollTo = Helpers.scrollTo;

  vm.totalPrice = 0;
  vm.requiredCheckbox = false;

  vm.order = {};
  vm.cart = {
    text: "/mesiac"
  };

  vm.agreements = {
    agrees: false,
    representation: true
  };

  vm.inProgress = false;
  vm.orderId = 0;
  vm.user = {};

  window.showSpinner(5);
  server.getMaxStep().then( (ms) => { vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
  server.getPrice().then( (tp) => { vm.cart.totalPrice = tp; window.hideSpinnerWhenAllResolved(); });
  server.getOrder().then(onOrderLoad);
  server.getOrderId().then((id) => { vm.orderId = id; window.hideSpinnerWhenAllResolved(); });
  userService.getLoggedUser().then((user) => {vm.user = user; window.hideSpinnerWhenAllResolved()});

  function onFinishClick() {
    if(!vm.agreements.agrees) {
      vm.requiredCheckbox = true;
      vm.scrollTo("scroll_checkbox");
    } else {
      var dataLayer = Helpers.getDataLayer(vm.orderId,
          'predaj_firmy',
          'sro', '', vm.user);
      $window.localStorage.setItem('dataLayer', JSON.stringify(dataLayer));
      vm.inProgress = true;
      server.finish({
        agrees: vm.agreements.agrees,
        requestsRepresentation: vm.agreements.representation
      }).then(function (_ref) {
        var success = _ref.success;
        var data = _ref.data;
        var error = _ref.error;

        if (success) {
          $window.location = "objednane";
        }
        vm.inProgress = false;
      }).catch(function (error) {
        console.log(error);
        vm.inProgress = false;
      });
    }
  }

  function onSaveForLaterClick() {
    ModalFinishLater(server)
      .add(server)
      .result
      .then();
  }

  function onBackClick() {
    $window.location = "krok1";
  }

  function onOrderLoad(order) {
    if(order) {
      vm.order = order;
    }
    console.log(vm.order);
    window.hideSpinnerWhenAllResolved();
  }
}

angular.module("firmaren")
  .controller("BurzaFiriemKrok2Controller", BurzaFiriemKrok2Controller);
}());
