;(function() {
function updateLanguage($rootScope, $translate) {
    return {
        link: function (scope, element) {
            var defaultLang = "sk";
            element.attr("lang", $translate.use() || defaultLang);

            var listener = function (event, translationResp) {
                var currentlang = translationResp.language;
                element.attr("lang", currentlang || $translate.use() || defaultLang);
            };

            $rootScope.$on('$translateChangeSuccess', listener);
        }
    };
}

angular.module("firmaren")
    .directive("updateLanguage", updateLanguage);
}());
