;(function() {
/* @ngInject */
function VirtualneSidloElementController(Helpers, IndexService, routes, $window) {
    let vm = this;

    let service = IndexService;

    vm.mergeStreetNo = Helpers.mergeStreetNo;
    vm.mergeAddress = mergeAddress;
    vm.getTextForContract = getTextForContract;
    vm.setStats = setStats;
    vm.onClick = onClick;

    function mergeAddress(address){
        if(address) {
            return address.street + " " + vm.mergeStreetNo(address) + ", " + address.zipCode;
        }
    }

    function getTextForContract(contract){
        switch(contract) {
            case 0: return "Bez obmedzenia";
            case 1: return "1 rok";
            case 2: return "2 roky";
            case 3: return "3 mesiace";
            case 6: return "6 mesiacov";
            default: return contract;
        }
    }

    function setStats(id) {
        service.setStats({
            url: "https://www.firmaren.sk/virtualne-sidlo/detail?id=" + id,
            source: "Firmaren-VirtualneSidlo",
            medium: "ZoznamSidiel",
            campaign: "Button"
        }).then();
    }

    function onClick(type) {
        service.setSeat({
            service: type,
            id: vm.seat.id
        }).then(() => {
            switch (type) {
                case 'zalozenieSro':
                    $window.location.href = (routes.sro.zalozenie.krok1);
                    break;
                case 'zmenaSro':
                    $window.location.href = (routes.sro.zmena.krok1);
                    break;
                case 'zalozenieZivnosti':
                    $window.location.href = (routes.zivnosti.zalozenie.krok1);
                    break;
                case 'zmenyZivnosti':
                    $window.location.href = (routes.zivnosti.zmeny.krok1);
                    break;
            }
        })
    }
}

angular.module("firmaren")
    .component("virtualneSidloElement", {
        templateUrl: "/app/shared/virtualne-sidlo-element/virtualne-sidlo-element.html",
        controller: VirtualneSidloElementController,
        controllerAs: "vm",
        bindings: {
            seat: "=",
            detail: "="
        }
    });
}());
