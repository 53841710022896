;(function() {
/* @ngInject */
function Login() {
  return {
    restrict: 'E',
    templateUrl: "/app/shared/login/login-view.html",
    controller: "LoginController",
    controllerAs: 'vm',
    bindToController: true
  };
}

/* @ngInject */
function LoginController(UserService, $filter, $rootScope, $scope, $interval, $window, $timeout) {
  let vm = this;
  let server = UserService;

  vm.loggedUser = {};
  vm.loginForm = {};
  vm.user = {};
  vm.errorMessage = null;
  vm.loginUser = loginUser;
  vm.logoutUser = logoutUser;
  vm.goToDashboard = goToDashboard;
  vm.forgotPassword = forgotPassword;
  vm.isLoggedUser = isLoggedUser;
  vm.changePasswordInputType = changePasswordInputType;
  vm.showData = "";
  vm.isForgotPassword = false;
  vm.isSentPassword = false;
  vm.testValue = false;
  vm.showNewBadge = false;
  vm.passwordInputType = 'password';
  vm.showSpinner = true;
  vm.showSpinnerPopover = false;
  vm.showContent = false;

  server.getLoggedUser().then(onLoggedUserLoadFirst);

  $timeout(function() {
    vm.showNewBadge = true;
  });

  $scope.$on('user-changed-contact', function(event, args) {
    server.getLoggedUser().then(onLoggedUserLoad);
  });

  function changePasswordInputType() {
    return vm.passwordInputType === 'password' ? vm.passwordInputType = 'text' : vm.passwordInputType = 'password';
  }

  function goToDashboard() {
    server.getMojaFirmaUrl().then(data => {
      if (data) {
        $window.location = data;
      }
    }).catch();
  }

  function onLoggedUserLoadFirst(user) {
    if(user && $window.location.pathname === "/login") {
      goToDashboard(true);
    } else {
      vm.showContent = true;
    }
    onLoggedUserLoad(user);
  }

  function onLoggedUserLoad(user) {
    vm.loggedUser = user;
    onUserChange();
    if(!vm.loggedUser) {
      vm.showSpinner = false;
    }
  }

  function loginUser(isLoginPage) {
    if(vm.loginForm.$invalid) {
      vm.submitted = true;
    } else {
      vm.showSpinnerPopover = true;
      vm.showSpinner = true;
      server.login({email: vm.user.email, password: vm.user.password}).then(({success, data}) => {
        if (success) {
          vm.errorMessage = null;
          server.getLoggedUser().then(onLoggedUserLoad);
          vm.submitted = false;
          goToDashboard(isLoginPage);
        } else {
          vm.errorMessage = "bad";
        }
      }).catch(function (e) {
        vm.errorMessage = "bad";
      }).finally(_ => {
        vm.showSpinnerPopover = false;
        vm.showSpinner = false;
      });
      onUserChange();
      broadCast();
    }
  }

  function logoutUser() {
    server.logout()
      .then(
      () => {
        vm.loggedUser = {};
        $window.location.reload(false);
      });
    onUserChange();

  }

  function isLoggedUser() {
    return vm.loggedUser && vm.loggedUser.email;
  }

  function onUserChange() {
    if(vm.loggedUser && vm.loggedUser.firstName) {
      vm.showData = vm.loggedUser.firstName + " " + vm.loggedUser.lastName;
    } else if (vm.loggedUser && vm.loggedUser.email){
      vm.showData = vm.loggedUser.email;
    }
  }

  function forgotPassword() {

    server.forgotPassword({email: vm.user.email}).then(({ success, data }) => {
      vm.isSentPassword = true;
    }).catch();
  }

  function broadCast() {
    $rootScope.$broadcast('user-changed');
  }

}

angular.module("firmaren")
  .controller("LoginController", LoginController)
  .directive("firmarenLogin", Login);
}());
