;(function() {
let patterns = {
  phoneNumber: "^[0-9]+",
  day: "^[1-3]{0,1}[0-9]{1}$",
  month: "^[1-2]{0,1}[0-9]{1}$",
  year: "^[1-2]{1}[0-9]{3}$",
  idnumber: "^[0-9]{3,5}$",
  ico: "^[0-9]{8}$",
  password: ".{8,}$",
  idnumberprefix: "^[0-9]+",
  email: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,63}$"
};

/* @ngInject */
function onStart($rootScope) {
  $rootScope.patterns = patterns;
}

angular.module("firmaren")
  .constant("patterns", patterns)
  .run(onStart);
}());
