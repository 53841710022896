;(function() {
/* @ngInject */
function setDecimals($filter) {
  return function(value) {

    let fractionSize = 2;
    if(value%1 === 0)
      fractionSize = 0;

    return $filter('number')(value, fractionSize);

  }
}
angular.module("firmaren")
  .filter("setDecimals", setDecimals);
}());
