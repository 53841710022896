;(function() {
/* @ngInject */
function capitalAmountValidator() {

  return {
    require: "ngModel",
    scope: {
      otherModelValue: "=capitalAmountValidator"
    },
    link: function(scope, element, attributes, ngModel) {

      ngModel.$validators.capitalAmount = function(modelValue) {
        if(scope.otherModelValue !== 0)
          return modelValue === scope.otherModelValue;
        else return true;
      };

      scope.$watch("otherModelValue", function() {
        ngModel.$validate();
      });
    }
  };
};

angular.module("firmaren")
  .directive("capitalAmountValidator", capitalAmountValidator);
}());
