;(function() {
/* @ngInject */
function ModalStreetNumber($uibModal, $rootScope) {
    return function init() {
        return { add };
    };

    function open(options = {}) {
        return $uibModal.open(angular.extend({
            templateUrl: "/app/shared/modal-street-number/modal-street-number.html",
            controller: "ModalStreetNumberController",
            controllerAs: "vm",
            bindToController: true,
            size: "md"
        }, options));
    }

    function add(scope = {}) {
        return open({ scope: angular.extend($rootScope.$new(true), scope)})
    }
}

/* @ngInject */
function ModalStreetNumberController($uibModalInstance) {
    let vm = this;

    vm.close = () => $uibModalInstance.dismiss("cancel");

}

angular.module("firmaren")
    .factory("ModalStreetNumber", ModalStreetNumber)
    .controller("ModalStreetNumberController", ModalStreetNumberController);
}());
