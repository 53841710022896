;(function() {
/* @ngInject */
function RegisterController(UserService, IndexService, $window, $scope, $location, $interval, $rootScope) {
  let vm = this;
  let server = UserService;
  let indexService = IndexService;

  vm.registerUser = {
    phoneNumber: {
      prefix: "+421"
    },
    onlyRegister: true,
    hasCompany: false,
    userType: false
  };

  vm.showLoading = false;
  vm.regFrom = "";

  vm.onRegisterClick = onRegisterClick;
  vm.onForgotPasswordClick = onForgotPasswordClick;
  vm.onCityChange = onCityChange;
  vm.onIcoChange = onIcoChange;

  window.showSpinner(2);
  server.getCities().then(onCitiesLoad);
  server.getRegFrom().then(onRegFromLoad);

  vm.error = false;
  vm.success = false;

  function onCityChange($item) {
    vm.registerUser.address.zipCode = $item && $item._3;
  }

  function onRegFromLoad(res) {
    if(res.length > 0) {
      vm.regFrom = res;
    }
    console.log(vm.regFrom);
    window.hideSpinnerWhenAllResolved();
  }

  function onCitiesLoad(cities) {
    vm.cities = cities;
    window.hideSpinnerWhenAllResolved();
  }

  function onIcoChange() {
    server.getFinstatInfo(vm.registerUser.ico).then( data => {
      if(data) {
        vm.registerUser.companyName = data.name;
        vm.registerUser.dic = data.dic;
        vm.registerUser.icDph = data.icdph;
      }
    });
  }

  function onRegisterClick() {
    if($scope.register.$invalid) {
      vm.submitted = true;
      vm.success = false;
    } else {
      vm.showLoading = true;
      server.register(vm.registerUser).then(({success, data}) => {
        if (success) {
          if(vm.regFrom.length > 0) {
            setStats(vm.registerUser.email);
          }

          vm.errorMessage = null;
          vm.success = true;
          vm.registerUser = {
            phoneNumber: {
              prefix: "+421"
            }
          };
          vm.submitted = false;
          $rootScope.$broadcast('user-changed-contact');
          $scope.register.$setPristine();
          $scope.register.$setUntouched();
        } else {
          vm.errorMessage = "bad";
        }
        vm.showLoading = false;
      }).catch(function (e) {
        vm.errorMessage = "bad";
        vm.showLoading = false;
      });
    }
  }

  function setStats(email) {
    indexService.setStats({
      url: $location.absUrl(),
      source: "Firmaren-Registracia",
      medium: vm.regFrom,
      campaign: email
    }).then();
  }

  function onForgotPasswordClick() {
    server.restorePassword(vm.forgotPasswordUser).then(({ success, data }) => {
      if (success) {
        vm.error= false;
        vm.success = true;
        vm.forgotPasswordUser = {};
        $scope.forgotPasswordForm.$setPristine();
        $scope.forgotPasswordForm.$setUntouched();
      } else {
        vm.error = true;
      }
    }).catch(function(e) {
      vm.error = true;
    });
  }


}

angular.module("firmaren")
  .controller("RegisterController", RegisterController);
}());
