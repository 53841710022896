;(function() {
/* @ngInject */
function FirmarenPlacesController($filter, $location, IndexService) {
  let vm = this;

  let service = IndexService;

  vm.show = false;

  vm.onClick = onClick;
  vm.onFirmarenAddressClick = onFirmarenAddressClick;
  vm.setFirmarenPlaceOfBusiness = setFirmarenPlaceOfBusiness;

  function onClick(item) {
    vm.model = vm.prefix ? $filter('translate')(vm.prefix + item) : item;
  }

  function onFirmarenAddressClick() {
    vm.show = !vm.show;
  }

  function setStats(place) {
    service.setStats({
      url: $location.absUrl(),
      source: "Firmaren-VirtualneSidlo",
      medium: "Places-" + place.street,
      campaign: "GridItem"
    }).then();
  }

  function setFirmarenPlaceOfBusiness(place) {
    vm.model = place;
    vm.show = false;
    vm.cart.placeOfBusinessPrice = place.price;
    setStats(place);
  }
}

angular.module("firmaren")
  .component("firmarenPlaces", {
    templateUrl: "/app/shared/firmaren-places/firmaren-places.html",
    controller: FirmarenPlacesController,
    controllerAs: "vm",
    bindings: {
      items: "=",
      model: "=",
      cart: "=",
      isShort: "="
    }
  });
}());
