;(function() {
/* @ngInject */
function PasswordValidator() {

  return {
    require: "ngModel",
    scope: {
        otherModelValue: "=passwordValidator"
    },
    link: function(scope, element, attributes, ngModel) {

        ngModel.$validators.password = function(modelValue) {
            return modelValue === scope.otherModelValue;
        };

        scope.$watch("otherModelValue", function() {
            ngModel.$validate();
        });
    }
  };
};

angular.module("firmaren")
  .directive("passwordValidator", PasswordValidator);
}());
