;(function() {
/* @ngInject */
function KontaktController(IndexService, $interval, $scope, ModalImg, ModalMap) {

  let vm = this;

  let server = IndexService;

  vm.agreement = false;

  vm.onSendClick = onSendClick;
  vm.openImage = openImage;
  vm.openMap = openMap;

  vm.contact = {
    phoneNumber: {
      prefix: "+421"
    }
  };

  vm.map = {
    ba: {
      map: "48.141199,17.1252475",
      marker: "48.1412149,17.1272336"
    },
    ke: {
      map: "48.7067992,21.2654854",
      marker: "48.7067992,21.2654854"
    },
    bb: {
      map: "48.7371793,19.1548417",
      marker: "48.7371793,19.1548417"
    }
  }

  function onSendClick() {
    if($scope.contact.$invalid) {
      vm.submitted = true;
    } else {
      if (vm.contact) {
        server.sendContactMail(vm.contact).then((data) => {
          vm.success = true;
          vm.contact = {};
          $scope.order.$setUntouched();
          $interval(function () {
            vm.success = false;
          }, 2000, 1);
        });
      }
    }
  }

  function openImage(image) {
    ModalImg(image)
        .add()
        .result
        .then();
  }

  function openMap(city) {
    ModalMap(vm.map[city].map, vm.map[city].marker)
        .add()
        .result
        .then();
  }

}

angular.module("firmaren")
  .controller("KontaktController", KontaktController);
}());
