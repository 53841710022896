;(function() {
function VirtualneSidloDetailController(VirtualneSidloDetailService, ModalPhotoGallery, getUrlParams) {
  let vm = this;

  vm.server = VirtualneSidloDetailService;

  vm.company = {};

  let param = getUrlParams().id;

  if (param) {
    vm.id = param;
  }

  vm.showMoreImg = showMoreImg;

  window.showSpinner(1);
  vm.server.getDetail(vm.id).then(onDetailLoad);

  function onDetailLoad(item) {
    if (item){
      vm.seat = item;
      vm.company = {
        id: vm.id,
        companyName: item.companyName,
        contact: item.email,
        web: item.web,
        phone: item.phoneNumber
      }
    }

    window.hideSpinnerWhenAllResolved();
  }

  function showMoreImg(index) {
    ModalPhotoGallery(vm.seat.gallery, index)
      .add()
      .result
      .then();
  }

}

angular.module("firmaren")
  .controller("VirtualneSidloDetailController", VirtualneSidloDetailController);
}());
