;(function() {
/* @ngInject */
function ZalozenieOZKrok1Controller(ZalozenieOZKrok1Service, $window, UserService, $scope, Helpers, $rootScope, ModalFinishLater, $filter, ModalConfirmDelete, ModalStreetNumber) {
  let vm = this;
  let service = ZalozenieOZKrok1Service;

  vm.order = {
    placeOfBusiness : {
      type: "own",
      address: {}
    },
    selectedGoals: [],
    otherInfo: {},
    additionalInfo: {},
    domain: {
      name: "",
      isFree: false,
      active: false
    }
  };

  vm.cities = [];
  vm.countries = [];

  vm.statuses = ["chairman", "headmaster", "president", "other"];
  vm.heads = ["meeting","council","other"];
  vm.controls = ["revision", "other"];

  vm.order.otherInfo.status = $filter("translate")('zalozenieOZ.krok1.otherInfo.status.' + vm.statuses[0]);
  vm.order.otherInfo.statusSelect = vm.order.otherInfo.status;
  vm.order.otherInfo.head = $filter("translate")('zalozenieOZ.krok1.otherInfo.head.' + vm.heads[0]);
  vm.order.otherInfo.headSelect = vm.order.otherInfo.head;
  vm.order.otherInfo.duration = 1;

  vm.isCollapsed = {
    firmarenAddress: true,
    statusOther: false,
    headOther: false
  };

  vm.errorGoals = false;
  vm.showStreetNumberModals = true;

  vm.cart = {};
  vm.nextStep = 2;
  vm.valid = false;

  vm.loadingSteps = false;

  let res = $window.location.pathname.split("/");
  if (res[1] === "zalozenie-obcianskeho-zdruzenia")
    vm.order.isSelf = false;
  else vm.order.isSelf = true;
  service.setSelfBoolean(vm.order.isSelf.toString()).then();

  vm.onSaveAndContinueClick = onSaveAndContinueClick;
  vm.firmarenPlacesOfBusiness = [];
  vm.setFirmarenPlaceOfBusiness = setFirmarenPlaceOfBusiness;
  vm.onFirmarenAddressClick = onFirmarenAddressClick;
  vm.onFirmarenPlaceChange = onFirmarenPlaceChange;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.onCityChange = onCityChange;
  vm.onCodeChange = onCodeChange;
  vm.scrollTo = Helpers.scrollTo;
  vm.setOtherInfoStatus = setOtherInfoStatus;
  vm.setOtherInfoHead = setOtherInfoHead;
  vm.setOtherInfoControl = setOtherInfoControl;
  vm.deselect = deselect;

  window.showSpinner(7, "new_oz_run_placeholder");
  service.getMaxStep().then( (ms) => { vm.maxStep = ms;   window.hideSpinnerWhenAllResolved(); });
  service.getCities().then(onCitiesLoad);
  service.getFirmarenPlacesOfBusiness().then(onFirmarenPlacesOfBusinessLoad);
  service.getOzInfo().then(onGetOzInfo);
  service.getPrice().then(onGetPrice);
  service.getVoucher().then(onVoucherLoad);
  service.getGoals().then(onGoalsLoad);
  service.getCompanyName().then(data => {if(data && data.length > 0) vm.order.name = data});
  onPriceChange();

  $scope.$on('new_oz_run_placeholder', function(event, args) {
    var content = $filter("translate")("zalozenieOZ.krok1.namePlaceholder");
    vm.icoPlaceholder = "";

    var i = 0;

    var timer = setInterval(function () {
      if (i < content.length)
        vm.icoPlaceholder += content[i];
      else clearInterval(timer);
      i++;
      $scope.$apply();
    }, 100);
  });

  function onSaveForLaterClick() {
    ModalFinishLater(service, JSON.stringify(vm.order))
      .add(service, JSON.stringify(vm.order))
      .result
      .then();
  }

  vm.loggedUser = {};
  vm.user = {};
  vm.errorMessage = null;
  vm.showData = "";
  vm.userExists = false;
  vm.requiredMessage = false;

  function deselect(goal) {
    ModalConfirmDelete("confirmDelete.infoGoal").add().result.then(x => {
      vm.order.selectedGoals = $filter('filter')(vm.order.selectedGoals, function(g) {
          return g.goal !== goal.goal;
        });
      }
    );
  }

  function onGoalsLoad(goals) {
    vm.goals = goals;

    window.hideSpinnerWhenAllResolved();
  }

  function onVoucherLoad(voucher) {
    if(voucher && voucher.voucher) {
      vm.additionalInfo.voucher = voucher.voucher;
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onPriceChange() {
    service.getCart().then(onCartLoad);
  }

  function onCartLoad(cart) {
    vm.cart = cart;
  }

  function onCodeChange() {
    if(vm.order.additionalInfo.voucher) {
      service.setDiscount(vm.order.additionalInfo).then(({ success, error }) => {
        if(success) {
          vm.voucherError = null;
        } else {
          vm.voucherError = error;
        }
      });
    }
    onPriceChange();
  }

  function setOtherInfoControl(item) {
    vm.order.otherInfo.controlSelect = $filter("translate")('zalozenieOZ.krok1.otherInfo.control.' + item);
    if(item !== "other") {
      vm.isCollapsed.controlOther = false;
      vm.order.otherInfo.control = $filter("translate")('zalozenieOZ.krok1.otherInfo.control.' + item);
    } else {
      vm.order.otherInfo.control = "";
      vm.isCollapsed.controlOther = true;
    }
  }

  function setOtherInfoStatus(item) {
    vm.order.otherInfo.statusSelect = $filter("translate")('zalozenieOZ.krok1.otherInfo.status.' + item);
    if(item !== "other") {
      vm.isCollapsed.statusOther = false;
      vm.order.otherInfo.status = $filter("translate")('zalozenieOZ.krok1.otherInfo.status.' + item);
    } else {
      vm.order.otherInfo.status = "";
      vm.isCollapsed.statusOther = true;
    }
  }

  function setOtherInfoHead(item) {
    vm.order.otherInfo.headSelect = $filter("translate")('zalozenieOZ.krok1.otherInfo.head.' + item);
    if(item !== "other") {
      vm.isCollapsed.headOther = false;
      vm.order.otherInfo.head = $filter("translate")('zalozenieOZ.krok1.otherInfo.head.' + item);
    } else {
      vm.order.otherInfo.head = "";
      vm.isCollapsed.headOther = true;
    }
  }

  function onCityChange(elem, $item) {
    if(elem === "placeOfBusiness")
      vm.order.placeOfBusiness.address.zipCode = $item && $item._3;
  }

  function onFirmarenPlaceChange() {
    if(vm.order.placeOfBusiness.type == "firmaren") {
      vm.cart.placeOfBusinessPrice = vm.order.placeOfBusiness.price;
      vm.firmarenAddress = vm.firmarenPlacesOfBusiness[0];
      //vm.order.placeOfBusiness = vm.firmarenPlacesOfBusiness[0];
    } else {
      vm.cart.placeOfBusinessPrice = null;
    }
  }

  function onGetPrice(p) {
    vm.cart.price = p;

    window.hideSpinnerWhenAllResolved();
  }

  function onCitiesLoad(ps) {
    vm.cities = ps;

    window.hideSpinnerWhenAllResolved();
  }

  function checkStreetNumber() {
    let addresses = [vm.order.placeOfBusiness && vm.order.placeOfBusiness.address];

    var result = false;

    addresses.forEach(address => {
      console.log(address);
      if (address && address.streetNo && (!address.streetNoPrefix || address.streetNoPrefix === ""))
        result = true;
    });

    return result;
  }

  function onSaveAndContinueClick(steps) {

    if($scope.step1.$invalid) {
      vm.requiredMessage = true;
      //scrollTo("content");
      vm.submitted=true;
    } else {
      if (vm.showStreetNumberModals && checkStreetNumber()) {
        ModalStreetNumber()
            .add()
            .result
            .then();

        vm.showStreetNumberModals = false;
      } else {
        if (vm.order.selectedGoals.length > 0) {
          let placeOfBusiness = vm.order.placeOfBusiness.type === "firmaren" ? vm.firmarenAddress : vm.order.placeOfBusiness;
          placeOfBusiness.email = vm.order.placeOfBusiness.email;
          vm.order.placeOfBusiness = placeOfBusiness;

          service.setOzInfo(vm.order).then(() => {
            if (steps) {
              vm.valid = true;
            } else {
              $window.location = "krok2";
            }
          })
        } else {
          vm.errorGoals = true;
          vm.scrollTo("goals_search");
        }
      }
    }
  }

  function onFirmarenPlacesOfBusinessLoad(ps) {
    vm.firmarenPlacesOfBusiness = ps;
    vm.firmarenAddress = vm.firmarenPlacesOfBusiness[0];

    window.hideSpinnerWhenAllResolved();
  }

  function onFirmarenAddressClick() {
    vm.isCollapsed.firmarenAddress = !vm.isCollapsed.firmarenAddress;
  }

  function setFirmarenPlaceOfBusiness(place) {
    vm.order.placeOfBusiness.id = place.id;
    vm.firmarenAddress = place;
    vm.isCollapsed.firmarenAddress = true;
  }

  function findPlaceOfBusiness(elem) {
    return elem.id === vm.order.placeOfBusiness.id;
  }

  function onGetOzInfo(ps) {
    if(ps) {
      vm.order = ps;
      if(vm.order.placeOfBusiness && vm.order.placeOfBusiness.id ) {
        vm.firmarenAddress = vm.firmarenPlacesOfBusiness.find(findPlaceOfBusiness);
      }
      if (vm.order.otherInfo && vm.order.otherInfo.status) {
        vm.order.otherInfo.statusSelect = vm.order.otherInfo.status;
      }

      if (vm.order.otherInfo && vm.order.otherInfo.head) {
        vm.order.otherInfo.headSelect = vm.order.otherInfo.head;
      }

      if (vm.order.otherInfo && vm.order.otherInfo.control) {
        vm.order.otherInfo.controlSelect = vm.order.otherInfo.control;
      }

      if(!vm.order.domain) {
        vm.order.domain = {
          name: "",
          isFree: false,
          active: false
        }
      }
    } else {
      service.getStringContent().then((data) => {
        if(data) {
          vm.order = JSON.parse(data);

          if(!vm.order.domain) {
            vm.order.domain = {
              name: "",
              isFree: false,
              active: false
            }
          }
        }
      });
    }

    vm.loadingSteps = true;
    window.hideSpinnerWhenAllResolved();
  }

}

angular.module("firmaren")
  .controller("ZalozenieOZKrok1Controller", ZalozenieOZKrok1Controller);
}());
