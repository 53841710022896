;(function() {
/* @ngInject */
function BusinessSubjectsFactory($rootScope, _, $timeout, $window, $filter, ModalConfirmDelete, $translate) {
  const SelectionChanged = "BusinessSubjects.SelectionChanged";

  let subjects = [];
  let subjectGroups = {};
  let selectedSubjects = [];
  let selectedSubjectsSet = {};
  let lang = $translate.use();

  return {
    init,
    onChange,
    get all() { return subjects },
    get groups() { return subjectGroups },
    get selected() { return selectedSubjects },
    isSelectionEmpty,
    selectionNonEmpty,
    isSelected,
    select,
    deselect,
    deselectAll,
    clear,
    selectByTag,
    getAllSubjects
  };

  function init(_subjects) {
    subjects = _subjects;
    subjectGroups = {
      mostUsed: {
        title: "businessSubjects.groups.mostUsed.title",
        subtitle: "businessSubjects.groups.mostUsed.subtitle",
        subjects: groupByTag("business")
      },
      eshop: {
        title: "businessSubjects.groups.eshop.title",
        subtitle: "businessSubjects.groups.eshop.subtitle",
        subjects: _.filter(subjects, s => s.category2 === "eshop")
      },
      graphics: {
        title: "businessSubjects.categories.graphic",
        subjects: groupByTag("graphic")
      },
      handmade: {
        title: "businessSubjects.categories.handmade",
        subjects: groupByTag("handmade")
      },
      traffic: {
        title: "businessSubjects.categories.traffic",
        subjects: groupByTag("traffic")
      },
      administration: {
        title: "businessSubjects.categories.administration",
        subjects: groupByTag("administration")
      },
      architecture: {
        title: "businessSubjects.categories.architecture",
        subjects: groupByTag("architecture")
      },
      science: {
        title: "businessSubjects.categories.science",
        subjects: groupByTag("science")
      },
      agriculture: {
        title: "businessSubjects.categories.agriculture",
        subjects: groupByTag("agriculture")
      },
      accounting: {
        title: "businessSubjects.categories.accounting",
        subjects: groupByTag("accounting")
      },
      health: {
        title: "businessSubjects.categories.health",
        subjects: groupByTag("health")
      },
      advertisement: {
        title: "businessSubjects.categories.advertisement",
        subjects: groupByTag("advertisement")
      },
      course: {
        title: "businessSubjects.categories.course",
        subjects: groupByTag("course")
      },
      free: {
        class: "free",
        title: "businessSubjects.groups.free",
        subjects: _(subjects).filter(s => s.type === "0").groupBy(lang === 'en' ? "categoryEN" : "category").value()
      },
      craft: {
        title: "businessSubjects.groups.craft",
        subjects: _(subjects).filter(s => s.type === "1").groupBy(lang === 'en' ? "categoryEN" : "category").value()
      },
      bound: {
        title: "businessSubjects.groups.bound",
        subjects: _(subjects).filter(s => s.type === "2").groupBy(lang === 'en' ? "categoryEN" : "category").value()
      }
    };
  }

  function onChange($scope, callback) {
    let unsubscribe = $rootScope.$on(SelectionChanged, callback);
    $scope.$on("$destroy", unsubscribe);
    return unsubscribe;
  }

  function notify() {
    $rootScope.$emit(SelectionChanged, selectedSubjects);
  }

  function isSelectionEmpty() {
    return _.isEmpty(selectedSubjects);
  }

  function selectionNonEmpty() {
    return !_.isEmpty(selectedSubjects);
  }

  function isSelected(s) {
    return angular.isDefined(selectedSubjectsSet[s.id]);
  }

  let promise;

  function select(s, event) {
    if (angular.isArray(s)) {
      selectAll(s);
    }
    else {
      selectOne(s);
    }

    if(event) {
      $('#green_popup').removeClass("red_popup");
      $('#green_popup_arrow').removeClass("red_popup");
      if(promise)
        $timeout.cancel(promise);

      if (window.matchMedia('(min-width: 45em)').matches) {
        console.log(event);
        if(selectedSubjects.length === 1) {
          $('#green_popup').css({'top': (event.clientY - ($("#business_subject_search").offset().top - (window.scrollY || window.pageYOffset || document.body.scrollTop)) - 42) + 'px', 'left': event.target.parentNode.parentNode.parentNode.clientWidth + 'px'});
        } else {
          $('#green_popup').css({'top': (event.clientY - ($("#business_subject_search").offset().top - (window.scrollY || window.pageYOffset || document.body.scrollTop)) - 22) + 'px', 'left': event.target.parentNode.parentNode.parentNode.clientWidth + 'px'});
        }
      }

      if(selectedSubjects.length === 1) {
        $('#green_popup_text').text($filter("translate")('zalozenieZivnosti.krok1.popup.firstSubject'));
      } else {
        $('#green_popup_text').text($filter("translate")('zalozenieZivnosti.krok1.popup.anotherSubjects'));
      }

      $('#green_popup').show();

      promise = $timeout(function () {
        $('#green_popup').hide();
      }, 3000);
    }
  }

  function selectAll(ss) {
    let selectedChanged = false;

    for (let s of ss) {
      if (!isSelected(s)) {
        selectedChanged = true;
        let subject = {
          id: s.id,
          name: s.name,
          nameEN: s.nameEN,
          type: s.type,
          price: s.price,
          responsiblePerson: s.responsiblePerson
        };
        selectedSubjects.push(subject);
        selectedSubjectsSet[s.id] = true;
      }
    }

    if (selectedChanged) {
      notify();
    }
  }

  function selectOne(s) {
    if (!isSelected(s)) {
      let subject = {
        id: s.id,
        name: s.name,
        nameEN: s.nameEN,
        type: s.type,
        price: s.price,
        responsiblePerson: s.responsiblePerson
      };
      selectedSubjects.push(subject);
      selectedSubjectsSet[s.id] = true;
      notify();
    }
  }

  function groupByTag(tag){
    let ss = _(subjects).filter(s => s.packageTags && s.packageTags.indexOf(tag) != -1);

    let listSubject = ss.valueOf();

    listSubject = $filter('orderBy')(listSubject, function(x) {
      let res = x.packageTags.split(";");
      var index;
      res.forEach(function(val,i){
        if(val.match(tag, 'g')) {
          index = i;
        }
      });
      return res[index];
    });
    return listSubject;
  }

  function selectByTag(tag) {
    let selectedChanged = false;

    let ss = _(subjects).filter(s => s.packageTags && s.packageTags.indexOf(tag) != -1);

    if(tag === "eshop") {
      ss = _(subjects).filter(s => s.category2 === "eshop");
    }

    let listSubject = ss.valueOf();

    listSubject = $filter('orderBy')(listSubject, function(x) {
      let res = x.packageTags.split(";");
      var index;
      res.forEach(function(val,i){
        if(val.match(tag, 'g')) {
          index = i;
        }
      });
      return res[index];
    });

    for (let s of listSubject) {
      if (!isSelected(s)) {
        selectedChanged = true;
        let subject = {
          id: s.id,
          name: s.name,
          nameEN: s.nameEN,
          type: s.type,
          price: s.price,
          responsiblePerson: s.responsiblePerson
        };
        selectedSubjects.push(subject);
        selectedSubjectsSet[s.id] = true;
      }
    }

    if (selectedChanged) {
      notify();
    }
  }

  function deselect(s, event, withoutModal) {
    if(withoutModal) {
      if (isSelected(s)) {
        if(event) {
          if(promise)
            $timeout.cancel(promise);

          if (window.matchMedia('(min-width: 45em)').matches) {
            $('#green_popup').css({'top': (event.clientY - ($("#business_subject_search").offset().top - (window.scrollY || window.pageYOffset || document.body.scrollTop)) - 52) + 'px', 'left': event.target.parentNode.parentNode.parentNode.clientWidth + 'px'});
          }

          $('#green_popup_text').text($filter("translate")('zalozenieZivnosti.krok1.popup.removeSubject'));
          $('#green_popup').addClass("red_popup");
          $('#green_popup_arrow').addClass("red_popup");

          $('#green_popup').show();

          promise = $timeout(function () {
            $('#green_popup').hide();
          }, 3000);
        }

        selectedSubjects = _.filter(selectedSubjects, t => t.id !== s.id);
        delete selectedSubjectsSet[s.id];
        notify();
      }
    } else {
      ModalConfirmDelete("confirmDelete.info").add().result.then(x => {
          if (isSelected(s)) {
            selectedSubjects = _.filter(selectedSubjects, t => t.id !== s.id);
            delete selectedSubjectsSet[s.id];
            notify();
          }
        }
      );
    }
  }

  function deselectAll() {
    // console.log("deselect all");
    ModalConfirmDelete("confirmDelete.infoall").add().result.then(x => {
          clear();
        }
    );

  }

  function clear() {
    if (selectionNonEmpty()) {
      selectedSubjects = [];
      selectedSubjectsSet = {};
      notify();
    }
  }

  function getAllSubjects() {
    return subjects;
  }
}

angular.module("firmaren")
  .service("BusinessSubjects", BusinessSubjectsFactory);
}());
