;(function() {
/* @ngInject */
function LeavingPage($document, $interval) {
    return {
      link: function($scope, elem, attrs) {
        window.onbeforeunload = function(){
           if ($scope[attrs.name].$dirty && !$scope[attrs.name].$submitted) {
              return "Unsaved changes";
           }
        }
      }
    };
  }
  
  angular.module("firmaren")
    .directive("firmarenLeavingPage", LeavingPage);
}());
