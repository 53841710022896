;(function() {
/* @ngInject */
function ModalBusinessSubjects($uibModal, $rootScope) {
  return { open };

  function open(scope) {
    return $uibModal.open({
      size: "lg",
      templateUrl: "/app/shared/modal-business-subjects/modal-business-subjects.html",
      controller: "ModalBusinessSubjectsController",
      controllerAs: "vm",
      bindToController: true,
      windowTopClass: "modal-business-subjects-root",
      scope: angular.extend($rootScope.$new(), scope)
    });
  }
}

/* @ngInject */
function ModalBusinessSubjectsController($uibModalInstance) {
  let vm = this;

  vm.close = () => $uibModalInstance.dismiss("cancel");
}

angular.module("firmaren")
  .factory("ModalBusinessSubjects", ModalBusinessSubjects)
  .controller("ModalBusinessSubjectsController", ModalBusinessSubjectsController);
}());
