;(function() {
/* @ngInject */
function CitiesSliderController($document, $scope, $location, $filter, normalize, $rootScope, IndexService) {
  let vm = this;

  let service = IndexService;

  vm.selected = -1;

  vm.onLeftClick = onLeftClick;
  vm.onRightClick = onRightClick;
  vm.onImageClick = onImageClick;

  $scope.$on("reset-selected-city", function (event, data) {
    vm.selected = -1;
  });

  function setStats(city) {
    service.setStats({
      url: $location.absUrl(),
      source: "Firmaren-VirtualneSidlo",
      medium: "Filter-" + city.name,
      campaign: "GridItem"
    }).then();
  }

  function onLeftClick() {
    let row = $document.find(".img_items_in_row");
    let keeper = $document.find(".img_row_keeper");

    let marginLeft = parseFloat(row.css("margin-left").replace("px", ""));

    let offset = 1;
    if (window.matchMedia('(min-width: 45em)').matches)
      offset = 0.8;

    let keeperWidth = keeper.width() * offset;

    let newMargin = marginLeft + keeperWidth;

    if (newMargin > 0) {
      newMargin = 0;
    }

    row.animate({
      marginLeft: newMargin + "px"
    }, 500);
  }

  function onRightClick() {
    let row = $document.find(".img_items_in_row");
    let keeper = $document.find(".img_row_keeper");

    let marginLeft = parseFloat(row.css("margin-left").replace("px", ""));
    let rowWidth = row.width();

    let offset = 1;
    if (window.matchMedia('(min-width: 45em)').matches)
      offset = 0.8;

    let keeperWidth = keeper.width() * offset;

    let newMargin = marginLeft - keeperWidth;

    if (rowWidth < (Math.abs(newMargin) + keeperWidth)) {
      newMargin = (rowWidth - keeperWidth) * -1;
    }

    row.animate({
      marginLeft: newMargin + "px"
    }, 500);
  }

  function onImageClick(city, index) {
    $rootScope.$broadcast('virtualne-sidlo-location-reset');

    if (vm.selected != index) {
      vm.selected = index;
      city.selected = true;

      if(city.children){
        city.children.forEach((e) => e.selected = true);
      }

      $rootScope.$broadcast('virtualne-sidlo-update-selected');
    }
    else {
      vm.selected = -1;
    }

    setStats(city);
  }
}

angular.module("firmaren")
  .component("citiesSlider", {
    templateUrl: "/app/shared/cities-slider/cities-slider.html",
    controller: CitiesSliderController,
    controllerAs: "vm",
    bindings: {
      cities: "="
    }
  });
}());
