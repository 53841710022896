;(function() {
/* @ngInject */
function ModalExecutorOtherInfo($uibModal, $rootScope, $window) {
  let executors, cities;

  return function init(executors_, cities_) {
    executors = executors_;
    cities = cities_;

    return {add};
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-executor-other-info/modal-executor-other-info.html",
      controller: "ModalExecutorOtherInfoController",
      controllerAs: "vm",
      bindToController: true,
      size: "lg",
      backdrop: "static",
      resolve: {
        executors: () => executors,
        cities: () => cities
      }
    }, options));
  }

  function add(scope = {}) {
    return open({scope: angular.extend($rootScope.$new(true), scope)})
  }
}

/* @ngInject */
function ModalExecutorOtherInfoController($uibModalInstance, Helpers, $scope, executors, cities, $window) {
  let vm = this;

  vm.executors = [];
  vm.executors = angular.copy(executors);

  vm.index = -1;

  vm.executors.forEach(function (m) {
    if(m.newData && m.newData.dateOfBirth)
        m.dateOfBirth = m.newData.dateOfBirth;
      if(m.newData && m.newData.idNumber)
          m.idNumber = m.newData.idNumber;
    if(!m.dateOfBirth)
      m.dateOfBirth = {};
    if(!m.idNumber)
      m.idNumber = {};
  });

  vm.cities = cities;

  let minDate = new Date();

  vm.days = createArray(1, 31, false);
  vm.months = createArray(1, 12, false);
  vm.years = createArray(1900, minDate.getFullYear() - 18, true);

  vm.onElementFocus = Helpers.onElementFocus;
  vm.onElementBlur = Helpers.onElementBlur;
  vm.onClick = onClick;

  vm.formerSurnameChange = [];

  function createArray(start, end, reverse) {
    let array = [];
    for (var i = start; i <= end; i++) {
      array.push(i);
    }
    if (reverse)
      return array.reverse();
    else return array;
  }

  function onClick(item, elem, index) {

    //   console.log(index);
    // console.log(vm.executors);
    // console.log(vm.executors[index]);
    if (elem === "day") {
      vm.executors[index].dateOfBirth.day = item;
      vm.mouseoverDay[index] = false;
    } else if (elem === "month") {
      vm.executors[index].dateOfBirth.month = item;
      vm.mouseoverMonth[index] = false;
    } else if (elem === "year") {
      vm.executors[index].dateOfBirth.year = item;
      vm.mouseoverYear[index] = false;
    }
  }


  vm.close = () => $uibModalInstance.dismiss("cancel");

  vm.submit = () => {
    if ($scope.executiveManager.$invalid) {
      vm.submitted = true;
    } else $uibModalInstance.close(vm.executors);
  }

}

angular.module("firmaren")
  .factory("ModalExecutorOtherInfo", ModalExecutorOtherInfo)
  .controller("ModalExecutorOtherInfoController", ModalExecutorOtherInfoController);
}());
