;(function() {
/* @ngInject */
function CitackaEIdController(IndexService, $scope, $interval) {

    let vm = this;

    let server = IndexService;

    vm.agreement = false;

    vm.onSendClick = onSendClick;

    vm.form = {};

    function initForm() {
        vm.form = {
            phoneNumber: {
                prefix: "+421"
            }
        };
    }

    initForm();

    function onSendClick() {
        if (vm.form) {
            server.sendReaderForm(vm.form).then((data) => {
                vm.success = true;
                initForm();
                $scope.order.$setUntouched();
                $interval(function () {
                    vm.success = false;
                }, 2000, 1);
            });
        }
    }
}

angular.module("firmaren")
    .controller("CitackaEIdController", CitackaEIdController);
}());
