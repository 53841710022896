;(function() {
/* @ngInject */
function ModalConfirmFactory($uibModal, $rootScope) {
  let title;
  let message;
  let confirm;
  let cancel;
  return function init(title_, message_, confirm_, cancel_) {
    title = title_;
    message = message_;
    confirm = confirm_;
    cancel = cancel_;
    return { add };
  };

  function open(options = {}) {
    return $uibModal.open({
      templateUrl: "/app/shared/modal-confirm/modal-confirm-view.html",
      controller: "ModalConfirmController",
      controllerAs: "vm",
      bindToController: true,
      resolve: {
        title: () => title,
        message: () => message,
        confirm: () => confirm,
        cancel: () => cancel
      }
    });
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope)})
  }
}

/* @ngInject */
function ModalConfirmController(title, message, confirm, cancel, $uibModalInstance) {
  let vm = this;

  vm.title = title;
  vm.message = message;
  vm.confirm = confirm;
  vm.cancel = cancel;

  vm.close = () => {
    $uibModalInstance.close("cancel");
  };

  vm.submit = () => {
    $uibModalInstance.close("confirm");
  };
}

angular.module("firmaren")
  .factory("ModalConfirmFactory", ModalConfirmFactory)
  .controller("ModalConfirmController", ModalConfirmController);
}());
