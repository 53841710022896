;(function() {
/* @ngInject */
function ModalExecutor($uibModal, $rootScope) {
  let cities, countries, date, isExecutor, changeType, executor, isSelf;

  return function init(cities_, countries_, date_, isExecutor_, changeType_, executor_, isSelf_) {
    cities = cities_;
    countries = countries_;
    date = date_;
    isExecutor = isExecutor_;
    changeType = changeType_;
    executor = executor_;
    isSelf = isSelf_;

    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-executor/modal-executor.html",
      controller: "ModalExecutorController",
      controllerAs: "vm",
      bindToController: true,
      size: "lg",
      backdrop: "static",
      resolve: {
        cities: () => cities,
        countries: () => countries,
        date: () => date,
        isExecutor: () => isExecutor,
        changeType: () => changeType,
        executor: () => executor,
        isSelf: () => isSelf
      }
    }), options);
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(), scope) })
  }

}

/* @ngInject */
function ModalExecutorController($uibModalInstance, cities, countries, date, isExecutor, changeType, executor, isSelf, $scope, $filter, $window, $interval, Helpers) {
  let vm = this;

  vm.cities = cities;
  vm.countries = countries;
  vm.date = date;
  vm.isExecutor = isExecutor;
  vm.changeType = changeType;
  vm.isDate = !angular.equals(vm.date, {});
  vm.isSelf = isSelf;
  vm.focusSurname = false;
  vm.focusName = false;

  vm.isReadyMade = vm.isExecutor && vm.changeType === "edit" && !executor;

  if(executor) {
    vm.oldName = executor.name;
    vm.oldSurname = executor.surname;

    vm.isChange = true;
    vm.executor = angular.copy(executor);

    if(!executor.dateOfBirth)
        vm.executor.dateOfBirth = {};

    if(!vm.executor.address.country)
      vm.executor.address.country = vm.countries[0]._2;
  } else {
    vm.isChange = false;
    vm.executor = {
      dateOfBirth: {}
    };
  }

  if(vm.changeType === "edit")
    vm.isChange = true;

  if(vm.isExecutor && !vm.isChange && vm.changeType !== 'remove')
    vm.submitBtn = $filter("translate")('executiveManager.btnAdd');
  if(!vm.isExecutor && !vm.isChange && vm.changeType !== 'remove')
    vm.submitBtn = $filter("translate")('sro.zmena.krok1.clerks.modal.btn.add');
  if(vm.isChange && vm.changeType !== 'remove')
    vm.submitBtn = $filter("translate")('sro.zmena.krok1.executors.modal.btn.change');
  if(!vm.isChange && vm.changeType === 'remove')
    vm.submitBtn = $filter("translate")('sro.zmena.krok1.clerks.btn.remove');


  vm.genders = ["male", "female"];
  //vm.executor.gender = $filter("translate")('gender.' + vm.genders[0]);

  if(!vm.executor.idNumber) {
    vm.executor.idNumber = {};
  }

  let now = new Date();
  if(vm.isDate)
    vm.executor.date = vm.date;

  vm.popupOpened = false;
  vm.format = "EEEE - dd. MM. yyyy";
  vm.dateOptions = {
    showWeeks: false,
    //minDate: now,
    initDate: now
  };

  if(!vm.executor.address) {
    vm.executor.address = {
      country: ""
    };
    vm.executor.address.country = vm.countries[0]._2;
  }

  let minDate = new Date();

  vm.days = createArray(1,31, false);
  vm.months = createArray(1,12, false);
  vm.years = createArray(1900, minDate.getFullYear() - 18, true);

  vm.onIdNumberChange = onIdNumberChange;
  vm.onResetCountry = onResetCountry;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.onElementBlur = Helpers.onElementBlur;
  vm.scrollTo = Helpers.scrollInModalTo;
  vm.getFullName = Helpers.getFullName;
  vm.onZipCodeChange = onZipCodeChange;
  vm.onClick = onClick;
  vm.setDisabled = setDisabled;

  function setDisabled(isNew) {
    return !vm.isReadyMade && vm.isExecutor && vm.isChange && isNew;
  }

  function createArray(start, end, reverse) {
    let array = [];
    for(var i = start; i <= end; i++) {
      array.push(i);
    }
    if(reverse)
      return array.reverse();
    else return array;
  }

  function onClick(item, elem) {
    if(elem === "day") {
      vm.executor.dateOfBirth.day = item;
      vm.mouseoverDay = false;
    } else if(elem === "month") {
      vm.executor.dateOfBirth.month = item;
      vm.mouseoverMonth = false;
    } else if(elem === "year") {
      vm.executor.dateOfBirth.year = item;
      vm.mouseoverYear = false;
    }

    onIdNumberChange();
  }

  function onIdNumberChange() {
    if(vm.executor) {
      if (vm.executor.gender) {
        vm.executor.idNumber.prefix = Helpers.calculateIdNumber(vm.executor.dateOfBirth, vm.executor.gender);
      } else {
        vm.onElementFocus("gender");
        vm.onElementBlur("gender");
        vm.scrollTo("gender");
      }
    }
  }

  function onZipCodeChange($item) {
    vm.executor.address.zipCode = $item && $item._3;
  }

  function onResetCountry() {
    vm.executor.address.country = "";
    $interval(function() {
      let element = $window.document.getElementById("address.country");
      if(element)
        element.focus();
    }, 100, 1);
  }

  vm.close = () => $uibModalInstance.dismiss("cancel");
  //vm.submit = () => $uibModalInstance.close(vm.executor);
  vm.submit = () => {
    if($scope.executor.$invalid) {
      vm.submitted=true;
    } else {
      vm.executor.fullName = vm.getFullName(vm.executor);
      $uibModalInstance.close(vm.executor);
    }
  };
}

angular.module("firmaren")
  .factory("ModalExecutor", ModalExecutor)
  .controller("ModalExecutorController", ModalExecutorController);
}());
