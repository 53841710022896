;(function() {
/* @ngInject */
function CreditController() {
    let vm = this;
}

angular.module("firmaren")
    .component("credit", {
        templateUrl: "/app/shared/credit/credit.html",
        controller: CreditController,
        controllerAs: "vm",
        bindings: {
            loggedUser: "=",
            isCredit: "="
        }
    });
}());
