;(function() {
/* @ngInject */
function GoogleReviewsController() {
    let vm = this;
}

angular.module("firmaren")
    .component("googleReviews", {
        templateUrl: "/app/shared/google-reviews/google-reviews.html",
        controller: GoogleReviewsController,
        controllerAs: "vm",
        bindings: {
            googleReviews: "="
        }
    });
}());
