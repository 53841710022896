;(function() {
/* @ngInject */
function whitespaceFilter() {
  return function(input, optional1, optional2) {

    return input.replace(/\s/g, "");

  }
}
angular.module("firmaren")
  .filter("whitespaceFilter", whitespaceFilter);
}());
