;(function() {
/* @ngInject */
function SelfInfoController($rootScope, ZmenaSroKrok1Service) {
    let vm = this;

    let server = ZmenaSroKrok1Service;

    vm.routeToChangeCompany = routeToChangeCompany;

    server.getSelfPrice().then(onSelfPriceLoad);

    function routeToChangeCompany() {
        $rootScope.$broadcast('set-string-content');
    }

    function onSelfPriceLoad(p) {
        vm.price = p;
    }
}

angular.module("firmaren")
    .component("selfInfo", {
        templateUrl: "/app/shared/self-info/self-info.html",
        controller: SelfInfoController,
        controllerAs: "vm",
        bindings: {
            visible: "="
        }
    });
}());
