;(function() {
/* @ngInject */
function UctovnikRenewController(UctovnikRenewService, $window){
    let vm = this;
    let server = UctovnikRenewService;

    vm.hash = "";
    vm.typPlatby = "";

    vm.createOrder = createOrder;

    server.getHashAndType().then(onHashAndTypeLoad);

    function onHashAndTypeLoad(data) {
        if(data) {
            vm.hash = data.hash;
            vm.typPlatby = data.typPlatby;
        } else {
            $window.location = "/404";
        }
    }

    function createOrder() {
        $window.location = "/duplikuj-uctovnik/" + vm.hash + "?typ=" + vm.typPlatby;
    }
}

angular.module("firmaren")
    .controller("UctovnikRenewController", UctovnikRenewController);
}());
