;(function() {
/* @ngInject */
function yearsFilter() {
  return function(input, optional1, optional2) {

    if(input ==1) {
      return input + " rok";
    }
    if(input >= 2 && input <=4) {
      return input + " roky";
    } else {
      return input + " rokov";
    }

  }
}
angular.module("firmaren")
  .filter("yearsFilter", yearsFilter);
}());
