;(function() {
/* @ngInject */
function ModalNotLoggedUser($uibModal, $rootScope) {
    let title;
    let desc;
    let regFrom;

    return function init(title_, desc_, regFrom_) {
        title = title_;
        desc = desc_;
        regFrom = regFrom_;

        return { add };
    };

    function open(options = {}) {
        return $uibModal.open(angular.extend({
            templateUrl: "/app/shared/modal-not-logged-user/modal-not-logged-user.html",
            controller: "ModalNotLoggedUserController",
            controllerAs: "vm",
            bindToController: true,
            backdrop: "static",
            resolve: {
                title: () => title,
                desc: () => desc,
                regFrom: () => regFrom
            },
            size: "small",
        }, options));
    }

    function add(scope = {}) {
        return open({ scope: angular.extend($rootScope.$new(true), scope) })
    }
}

/* @ngInject */
function ModalNotLoggedUserController($uibModalInstance, title, desc, regFrom, $window, UserService, $rootScope) {
    let vm = this;

    let server = UserService;

    vm.title = title;
    vm.desc = desc;
    vm.regFrom = regFrom;
    vm.showLogin = false;
    vm.showSpinner = true;
    vm.showSpinnerPopover = false;

    vm.loginUser = loginUser;

    vm.close = () => $uibModalInstance.close("cancel");
    vm.createAccount = () => {
        server.setRegFrom(vm.regFrom)
            .then(() => $window.location = "/register")
            .catch(() => $window.location = "/register");
    };
    vm.login = () => vm.showLogin = true;

    function loginUser() {
        if(vm.loginForm.$invalid) {
            vm.submitted = true;
        } else {
            vm.showSpinnerPopover = true;
            vm.showSpinner = true;
            server.login({email: vm.user.email, password: vm.user.password}).then(({success, data}) => {
                if (success) {
                    vm.errorMessage = null;
                    vm.submitted = false;
                    vm.close();
                } else {
                    vm.errorMessage = "bad";
                }
            }).catch(function (e) {
                vm.errorMessage = "bad";
            }).finally(_ => {
                vm.showSpinnerPopover = false;
                vm.showSpinner = false;
            });
            broadCast();
        }
    }

    function broadCast() {
        $rootScope.$broadcast('user-changed-contact');
    }

}

angular.module("firmaren")
    .factory("ModalNotLoggedUser", ModalNotLoggedUser)
    .controller("ModalNotLoggedUserController", ModalNotLoggedUserController);
}());
