;(function() {
/* @ngInject */
function ZalozenieZivnostiKrok3Controller(ZalozenieZivnostiKrok3Service, UserService, routes, _, $window, $filter, Helpers,
                                          ModalFinishLater, $translate, $scope, ModalTatraBanka, $location) {
  let vm = this;
  let server = ZalozenieZivnostiKrok3Service;
  vm.server = server;

  let userService = UserService;

  vm.maxStep = 3;
  vm.cart = { };
  vm.selected = [];
  vm.countries = [];
  vm.foreigner = false;
  vm.agreements = {
      agrees: false,
      representation: true,
  };
  vm.upsells = {};
  vm.isTbCampaign = false;
  vm.requiredCheckbox = false;
  vm.inProgress = false;
  vm.orderId = 0;
  vm.lang = $translate.use();
  vm.user = {};
  vm.zvyhodnena = false;

  vm.onBackClick = onBackClick;
  vm.onFinishClick = onFinishClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.scrollTo = Helpers.scrollTo;
  vm.mergeStreetNo = Helpers.mergeStreetNo;
  vm.getFullName = Helpers.getFullName;
  vm.dateToString = Helpers.dateToString;
  vm.idNumberToString = Helpers.idNumberToString;
  vm.phoneNumberToString = Helpers.phoneNumberToString;
  vm.formatCityZipCode = Helpers.formatCityZipCode;
  vm.formatStreet = Helpers.formatStreet;

  vm.showModalTB = false;

  window.showSpinner(10);
  server.getCountries().then(onCountriesLoad);
  server.getMaxStep().then( (ms) =>{ vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
  server.getSelectedSubjects().then(onSelectedSubjectsLoad);
  server.getTotalPrice().then(onTotalPriceLoad);
  server.getOrder().then(onOrderLoad);
  server.getAgreementAndRepresentation().then(onAgreementAndRepresentationLoad);
  server.getOrderId().then((id) => { vm.orderId = id; window.hideSpinnerWhenAllResolved(); });
  server.getUpsell().then((upsell) => { vm.upsells = upsell; window.hideSpinnerWhenAllResolved(); });
  server.getTBCampaign().then((isTbCampaign) => { vm.isTbCampaign = isTbCampaign; window.hideSpinnerWhenAllResolved() });
  server.showLastStepModalTB().then((res) => vm.showModalTB = res);
  userService.getLoggedUser().then((user) => {vm.user = user; window.hideSpinnerWhenAllResolved()});
  userService.isOldUser().then((ms) => vm.zvyhodnena = ms);

  $scope.$on('on-price-change', function(event, args) {
    server.getTotalPrice().then(onTotalPriceLoad);
  });

  function onPriceChange() {
    server.getCart().then(onCartLoad);
  }

  function onCartLoad(cart) {
    vm.cart = cart;
  }

  function onCountriesLoad(countries) {
    vm.countries = countries;
    window.hideSpinnerWhenAllResolved();
  }

  function onSelectedSubjectsLoad(ss) {
    if (angular.isDefined(ss)) {
      vm.selected = ss.subjects;
      vm.cart.size = ss.subjects.length;
    }

    window.hideSpinnerWhenAllResolved();
  }

  function showTatraBankaModal() {
    ModalTatraBanka('modalTatraBanka.lastStep.zivnost.title', 'modalTatraBanka.lastStep.zivnost.text', 18)
        .add()
        .result
        .then(res => {
          server.setTBDiscount((res && res === "confirm").toString()).then(() => {
            if(res && res === "confirm") {
              server.saveLastStepTBUpsell($location.absUrl()).then();
            }
            vm.showModalTB = false;
            onFinishClick();
          });
        });
  }

  function onOrderLoad(order) {
    if (angular.isDefined(order)) {
      let name = vm.getFullName(order.personalInfo);

      vm.order = angular.extend(order, {
        personalInfo: angular.extend(order.personalInfo, {
          fullname: name,
          address: vm.formatStreet(order.personalInfo.address),
          city: vm.formatCityZipCode(order.personalInfo.address),
          country: order.personalInfo.address.country,
          dateOfBirth: vm.dateToString(order.personalInfo.dateOfBirth),
          idNumber: vm.idNumberToString(order.personalInfo.idNumber),
          phoneNumber: vm.phoneNumberToString(order.personalInfo.phoneNumber)
        }),
        contractorInfo: angular.extend(order.contractorInfo, {
          name: (order.contractorInfo.name) ? `${name} - ${order.contractorInfo.name}` : name,
          businessAddress: formatTypedAddress(order.contractorInfo.businessAddress),
          licenceDeliveryAddress: formatTypedAddress(order.contractorInfo.licenceDeliveryAddress)
        })
      });

      if(vm.countries.length > 0 && vm.order.personalInfo.country !== vm.countries[0]._2) {
        vm.foreigner = true;
      }

      onPriceChange();
    }

      window.hideSpinnerWhenAllResolved();
  }

  function onTotalPriceLoad(tp) {
    vm.totalPrice = tp;
    vm.cart.totalPrice = tp;

    window.hideSpinnerWhenAllResolved();
  }

  function onAgreementAndRepresentationLoad(aar) {
    if (angular.isDefined(aar)) {
      vm.agrees = aar.agrees;
      vm.requestsRepresentation = aar.requestsRepresentation;
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onBackClick() {
    if (vm.lang === 'en')
      $window.location = routes.en.zivnosti.zalozenie.krok2;
    else $window.location = routes.zivnosti.zalozenie.krok2;
  }

  function onFinishClick() {
    if (!vm.agreements.agrees) {
      vm.requiredCheckbox = true;
      vm.scrollTo("scroll_checkbox");
    } else {
      if(vm.showModalTB && vm.lang != "en") {
        showTatraBankaModal();
      } else {
        var dataLayer = Helpers.getDataLayer(vm.orderId,
            vm.order.personalInfo && vm.order.personalInfo.foreigner ? 'zalozenie_zivnost_cudzinec' : (vm.zvyhodnena ? 'zalozenie_zivnost_zvyhodnena' : 'zalozenie_zivnost'),
            'zivnost', '', vm.user);
        $window.localStorage.setItem('dataLayer', JSON.stringify(dataLayer));

        vm.inProgress = true;
        window.showSpinner(1);
        // todo: data + validation
        server.finish({agrees: vm.agreements.agrees, requestsRepresentation: vm.agreements.representation})
            .then(({success, data, error}) => {
              if (success) {
                if (vm.lang === 'en')
                  $window.location = routes.en.zivnosti.zalozenie.objednane;
                else $window.location = routes.zivnosti.zalozenie.objednane;
              }
              vm.inProgress = false;
              window.hideSpinnerWhenAllResolved();
            })
            .catch(error => {
              console.log(error);
              vm.inProgress = false;
              window.hideSpinnerWhenAllResolved();
            });
      }
    }
  }

  function formatTypedAddress(address) {
    if(address) {
      if(address.type === "firmaren") {
        return address.street + ", " + address.city + " " + address.zipCode;
      } else if(address.type === "own" && address.address) {
        return address.address.street + " " + ((address.address.streetNoPrefix) ? (address.address.streetNoPrefix + "/") : "") + address.address.streetNo + ", " + address.address.city + " " + address.address.zipCode;
      } else if (address.type === "otherLicence") {
        return address.place;
      } else if (address.type === "work") {
        return $filter("translate")('zalozenieZivnosti.krok2.contractorInfo.originalWorkPlace');
      } else if (address.type === "same") {
        return $filter("translate")('zalozenieZivnosti.krok2.contractorInfo.originalHome');
      }
    }
  }

  function onSaveForLaterClick() {
    ModalFinishLater(server)
      .add(server)
      .result
      .then();
  }
}

angular.module("firmaren")
  .controller("ZalozenieZivnostiKrok3Controller", ZalozenieZivnostiKrok3Controller);
}());
