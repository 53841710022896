;(function() {
/* @ngInject */
function capitalExtentValidator() {

  return {
    require: "ngModel",
    scope: {
      otherModelValue: "=capitalExtentValidator"
    },
    link: function(scope, element, attributes, ngModel) {

      ngModel.$validators.capitalExtent = function(modelValue) {
        return scope.otherModelValue.founders != 1 || scope.otherModelValue.orderAmount === scope.otherModelValue.extent;
      };

      ngModel.$validators.capitalExtentFounders = function(modelValue) {
        return modelValue != scope.otherModelValue.extent || scope.otherModelValue.founders === 1 || scope.otherModelValue.extent >= 2500;
      };

      ngModel.$validators.capitalExtentDiff = function(modelValue) {
        if(scope.otherModelValue.extent !== 0)
          return modelValue === scope.otherModelValue.extent;
        else return true;
      };

      scope.$watch("otherModelValue.extent", function() {
        ngModel.$validate();
      });
    }
  };
};

angular.module("firmaren")
  .directive("capitalExtentValidator", capitalExtentValidator);
}());
