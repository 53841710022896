;(function() {
/* @ngInject */
function ModalMap($uibModal, $rootScope) {
    let map;
    let marker;

    return function init(map_, marker_) {
        map = map_;
        marker = marker_;

        return { add };
    };

    function open(options = {}) {
        return $uibModal.open(angular.extend({
            templateUrl: "/app/shared/modal-map/modal-map.html",
            controller: "ModalMapController",
            controllerAs: "vm",
            bindToController: true,
            resolve: {
                map: () => map,
                marker: () => marker
            },
            size: "lg",
        }, options));
    }

    function add(scope = {}) {
        return open({ scope: angular.extend($rootScope.$new(true), scope) })
    }
}

/* @ngInject */
function ModalMapController($uibModalInstance, map, marker) {
    let vm = this;

    vm.map = map;
    vm.marker = marker;

    vm.close = () => $uibModalInstance.close("cancel");

}

angular.module("firmaren")
    .factory("ModalMap", ModalMap)
    .controller("ModalMapController", ModalMapController);
}());
