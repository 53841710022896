;(function() {
/* @ngInject */
function ContactController() {
  let vm = this;
  let server = UserService;

  vm.loggedUser = {};
  vm.user = {};
  vm.errorMessage = null;
  vm.onEmailChange = onEmailChange;
  vm.onPasswordChange = onPasswordChange;
  vm.showData = "";
  vm.userExists = false;

  onUserChange();

  $scope.$on('user-changed', function(event, args) {
    onUserChange();
  });

  
}

angular.module("firmaren")
  .component("firmarenContact", {
    templateUrl: "/app/shared/contact/contact.html",
    controller: ContactController,
    controllerAs: "vm",
    bindings: {
      contactInfo: "=",
      form: "="
    }
  });
}());
