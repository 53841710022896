;(function() {
/* @ngInject */
function PlatbaNotaroviController(IndexService, $window, routes) {

    let vm = this;
    let server = IndexService;

    vm.payment = {};

    window.showSpinner(1);
    server.getPaymentAndQrCodeForNotary().then(onPaymentAndQrCodeLoad);

    function onPaymentAndQrCodeLoad(item) {
        console.log(item);
        if (item && item.vs) {
            vm.payment = item;
        } else {
            $window.location = routes.index;
        }
        window.hideSpinnerWhenAllResolved();
    }


}

angular.module("firmaren")
    .controller("PlatbaNotaroviController", PlatbaNotaroviController);
}());
