;(function() {
/* @ngInject */
function onStart(_, $rootScope) {
  $rootScope._ = _.noConflict();
}

function datePickerData($provide) {
  $provide.decorator('$locale', function ($delegate, $translate) {
    var value = $delegate.DATETIME_FORMATS;

    if($translate.use() === 'en') {
      value.SHORTDAY = [
        "Su",
        "Mo",
        "Tu",
        "We",
        "Th",
        "Fr",
        "Sa"
      ];
      value.MONTH = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      value.DAY = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ];
    } else {
      value.SHORTDAY = [
        "Ne",
        "Po",
        "Ut",
        "St",
        "Št",
        "Pi",
        "So"
      ];
      value.MONTH = [
        "január",
        "február",
        "marec",
        "apríl",
        "máj",
        "jún",
        "júl",
        "August",
        "September",
        "október",
        "November",
        "December"
      ];
      value.DAY = [
        "Nedeľa",
        "Pondelok",
        "Utorok",
        "Streda",
        "Štvrtok",
        "Piatok",
        "Sobota"
      ];
    }

    return $delegate;
  });
}

angular.module("firmaren")
  .constant("_", window._)
  .run(onStart)
  .config(datePickerData);
}());
