;(function() {
/* @ngInject */
function LandingPage() {
  return {
    restrict: 'E',
    templateUrl: "/app/components/landing-pages/landing-page-component.html"
  };
}

angular.module("firmaren")
  .directive("firmarenLandingPage", LandingPage);
}());
