;(function() {
/* @ngInject */
function ModalRedirect($uibModal, $rootScope) {
  let title;
  let text;

  return function init(title_, text_) {
    title = title_;
    text = text_;

    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-redirect/modal-redirect.html",
      controller: "ModalRedirectController",
      controllerAs: "vm",
      backdrop: 'static',
      bindToController: true,
      size: "md",
      resolve: {
        title: () => title,
        text: () => text
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) })
  }
}

/* @ngInject */
function ModalRedirectController($uibModalInstance, title, text) {
  let vm = this;

  vm.title = title;
  vm.text = text;

  vm.close = () => $uibModalInstance.dismiss("cancel");

  vm.submit = () => {
    $uibModalInstance.close(true);
  }
}

angular.module("firmaren")
  .factory("ModalRedirect", ModalRedirect)
  .controller("ModalRedirectController", ModalRedirectController);
}());
