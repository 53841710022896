;(function() {
/* @ngInject */
function KonverziaDokumentovKrok1Controller(KonverziaDokumentovKrok1Service, UserService, $scope, $window, ModalFinishLater,
                                            $rootScope, $filter, Helpers, ModalStreetNumber, ModalForgotPassword, routes) {
    let vm = this;

    let service = KonverziaDokumentovKrok1Service;
    let server = UserService;

    vm.onIcoChange = onIcoChange;
    vm.onInvoiceNameChange = onInvoiceNameChange;
    vm.onBackClick = onBackClick;
    vm.onSaveForLaterClick = onSaveForLaterClick;
    vm.onEmailChange = onEmailChange;
    vm.onPasswordChange = onPasswordChange;
    vm.onPersonalInfoClick = onPersonalInfoClick;
    vm.onSaveAndContinueClick = onSaveAndContinueClick;
    vm.showModalForgotPassword = showModalForgotPassword;
    vm.fullName = Helpers.fullName;

    vm.maxStep = 1;
    vm.valid = false;
    vm.countries = [];
    vm.loggedUser = {};
    vm.user = {};
    vm.errorMessage = null;
    vm.userExists = false;
    vm.showContactInfoForm = false;
    vm.showContactInfoSelect = true;
    vm.hidePrice = true;
    vm.spravnyPoplatok = 0;

    vm.cart = {
        finalPrice: 0
    };
    vm.nextStep = 2;

    vm.order = {
        service: "trade",
        type: "paper",
        personalNumber: "public",
        invoiceTo: {
            type: "company",
            address: {},
            sendInvoice: true
        },
        tmpInvoiceTo: {
            address: {}
        },
        sendTo: {
            type: "post",
            address: {}
        },
        contactInfo: {
            address: {}
        }
    };

    window.showSpinner(5);
    service.getMaxStep().then( (ms) =>{ vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
    service.getCities().then(onCitiesLoad);
    service.getCountries().then(onCountriesLoad);
    service.getPrice().then(onPriceLoad);
    service.getOrder().then(onOrderLoad);

    userLoad();

    $scope.$on('user-changed', function(event, args) {
        userLoad();
    });

    $scope.$on('on-password-change', function(event, data) {
        if(vm.order.contactInfo)
            vm.order.contactInfo.password = data;

        onPasswordChange();
    });

    $scope.$on('show-cart-price', function (event, args) {
        vm.hidePrice = false;
    });

    $scope.$on('on-email-change', function(event, args) {
        onEmailChange();
    });

    $scope.$watch('vm.order.type', function() {
        if(vm.order.type === "paper")
            vm.order.sendTo.type = "post";
        else vm.order.sendTo.type = "email";

        checkPrice();
    }, true);

    $scope.$watch('vm.order.service', function() {
        checkPrice();
    }, true);

    function detectUrl() {
        let res = Helpers.getUrlParameter("sluzba");
        if(res.length > 0) {
            if (res === "zivnost")
                vm.order.service = "trade";
            else if(res === "spolocnost")
                vm.order.service = "company";
        }
    }

    detectUrl();

    function checkPrice() {
        if(vm.order.service === "other") {
            vm.spravnyPoplatok = 5;
        } else vm.spravnyPoplatok = 0;
        service.checkPrice({service: vm.order.service, type: vm.order.type}).then(onPriceLoad);
    }

    function setInvoiceData(data) {
        vm.order.invoiceTo.companyName = data.companyName;
        vm.order.invoiceTo.address = data.address;
        vm.order.invoiceTo.city = data.address.city;
        vm.order.invoiceTo.dic = data.dic;
        vm.order.invoiceTo.icdph = data.icdph;
    }

    function setTmpInvoiceData(data) {
        vm.order.tmpInvoiceTo.address = data.address;
        vm.order.tmpInvoiceTo.dic = data.dic;
        vm.order.tmpInvoiceTo.icdph = data.icdph;
    }

    function onIcoChange(id, elem) {
        vm.icoError = false;
        service.getFinstatInfo({name: elem}).then( data => {
            if(data) {
                if(id === "ico") {
                    if(vm.order.service === 'trade')
                        vm.order.personalInfo = {
                            titleBefore: data.titleBefore,
                            name: data.name,
                            surname: data.surname,
                            titleAfter: data.titleAfter
                        };
                    else vm.order.companyName = data.companyName;

                    setTmpInvoiceData(data);
                } else {
                    setInvoiceData(data);
                }
            }
            vm.order.invoiceTo.address.country = vm.countries[0]._2;

            onInvoiceNameChange();
        });
    }

    function onInvoiceNameChange() {
        if(vm.order.personalInfo){
        vm.invoiceName = (vm.order.personalInfo.titleBefore && vm.order.personalInfo.titleBefore.length > 0 ? vm.order.personalInfo.titleBefore + " " : "")
            .concat(vm.order.personalInfo.name ? vm.order.personalInfo.name + " " : "")
            .concat(vm.order.personalInfo.surname ? vm.order.personalInfo.surname : "")
            .concat(vm.order.personalInfo.titleAfter && vm.order.personalInfo.titleAfter.length > 0 ? ", " + vm.order.personalInfo.titleAfter : "");
        } else if(vm.order.service === "company" && vm.order.companyName)
            vm.invoiceName = vm.order.companyName;
    }

    function onCountriesLoad(countries) {
        vm.countries = countries;
        vm.order.invoiceTo.address.country = vm.countries[0]._2;

        if(vm.order.contactInfo.address)
            vm.order.contactInfo.address.country = vm.countries[0]._2;

        window.hideSpinnerWhenAllResolved();
    }

    function onCitiesLoad(cities) {
        vm.cities = cities;
        window.hideSpinnerWhenAllResolved();
    }

    function onPriceLoad(c) {
        vm.cart.price = c.totalPriceWithTax;
        vm.cart.priceWithoutTax = c.totalPrice;
        window.hideSpinnerWhenAllResolved();
    }

    function userLoad(lastStep) {
        onEmailChange();
        onPasswordChange(lastStep);
        if(!lastStep)
            onUserChange();
    }

    function onPasswordChange(lastStep){
        if(vm.order.contactInfo && vm.order.contactInfo.email) {
            server.login({
                email: vm.order.contactInfo.email,
                password: vm.order.contactInfo.password
            }).then(({success, data}) => {
                if (success) {
                    vm.errorMessage = null;
                    if(!lastStep)
                        onUserChange();
                    $rootScope.$broadcast('user-changed-contact');
                } else {
                    vm.errorMessage = "bad";
                }
            }).catch(vm.errorMessage = "bad");
        }
    }

    function showModalForgotPassword() {
        ModalForgotPassword(vm.order.contactInfo.email)
            .add()
            .result
            .then();
      }

    function onUserChange() {
        vm.errorMessage = null;
        server.getLoggedUser().then(onLoggedUserLoad);
    }

    function onLoggedUserLoad(user) {
        vm.loggedUser = user;
        if(!vm.order.contactInfo) {
            vm.order.contactInfo = {};
        }
        if(user) {
            vm.order.contactInfo.email = user.email;
            vm.order.contactInfo.phoneNumber = $filter("whitespaceFilter")(user.phoneNumber);
            vm.order.contactInfo.name = user.firstName;
            vm.order.contactInfo.surname = user.lastName;
            vm.order.contactInfo.address = user.address;
            vm.order.contactInfo.dateOfBirth = user.dateOfBirth;
            vm.order.contactInfo.hasCompany = user.hasCompany;
            vm.order.contactInfo.companyName = user.companyName;
            vm.order.contactInfo.ico = user.ico;
            vm.order.contactInfo.dic = user.dic;
            vm.order.contactInfo.icDph = user.icDph;

            if(user.firstName !== "" && user.lastName !== "") {
                vm.showContactInfoForm = true;
                vm.showContactInfoSelect = false;
            }
        }

        if(!vm.order.contactInfo.phoneNumber) {
            vm.order.contactInfo.phoneNumber = "+421";
        }
    }

    function onEmailChange() {
        vm.loginDisabled = true;
        vm.errorMessage = "";
        if(vm.order.contactInfo && vm.order.contactInfo.email) {
            vm.loginSpinner = true;
            server.isFree({email: vm.order.contactInfo.email}).then(({success, data}) => {
                vm.loginSpinner = false;
                vm.loginDisabled = false;
                if (success) {
                    vm.userExists = false;
                } else {
                    if (vm.order.contactInfo && vm.order.contactInfo.password && vm.order.contactInfo.password !== "")
                        onPasswordChange();
                    vm.userExists = true;
                }
            }).catch(vm.userExists = false);
        }
    }

    // function contactInfoToOrder() {
    //     if(!vm.showContactInfoForm) {
    //         vm.order.contactInfo.name = vm.contactInfo.name;
    //         vm.order.contactInfo.surname = vm.contactInfo.surname;
    //         vm.order.contactInfo.address = vm.contactInfo.address;
    //         vm.order.contactInfo.dateOfBirth = vm.contactInfo.dateOfBirth;
    //     }
    // }

    function onBackClick() {
        // contactInfoToOrder();
        service.setBackContent(JSON.stringify(vm.order)).then(({success, data}) => {
            if (success) {
                $window.location = routes.sluzby.konverzia.krok1;
            } else {
                // todo validation
            }
        });
    }

    function onSaveForLaterClick() {
        // contactInfoToOrder();

        ModalFinishLater(service, JSON.stringify(vm.order))
            .add(service, JSON.stringify(vm.order))
            .result
            .then();
    }

    function onPersonalInfoClick(elem, item) {
        if(elem === "other") {
            vm.showContactInfoForm = true;
            vm.order.contactInfo.type = $filter('translate')('paymentInfo.other');
        } else {
            vm.showContactInfoForm = false;
            vm.contactInfo = item;
            vm.order.contactInfo.type = vm.fullName(item);
        }
        vm.mouseoverContactInfoType = false
    }

    function onOrderLoad(order) {
        if(order) {
            vm.order = order;
            onInvoiceNameChange();
            userLoad();
        } else {
            service.getStringContent().then((data) => {
                if (data) {
                    vm.order = JSON.parse(data);

                    onInvoiceNameChange();

                    if(vm.order.contactInfo) {
                        vm.contactInfo.name = vm.order.contactInfo.name;
                        vm.contactInfo.surname = vm.order.contactInfo.surname;
                        vm.contactInfo.address = vm.order.contactInfo.address;
                        vm.contactInfo.dateOfBirth = vm.order.contactInfo.dateOfBirth;
                    }

                    userLoad();
                }
            });
        }

        window.hideSpinnerWhenAllResolved();
    }

    function checkStreetNumber() {
        let addresses = [vm.order.sendTo && vm.order.sendTo.address,
            vm.order.invoiceTo && vm.order.invoiceTo.address,
            vm.order.contactInfo && vm.order.contactInfo.address];

        var result = false;

        addresses.forEach(address => {
            if (address && address.streetNo && (!address.streetNoPrefix || address.streetNoPrefix === ""))
                result = true;
        });

        return result;
    }

    function onSaveAndContinueClick(steps) {
        if($scope.step1.$invalid) {
            vm.requiredMessage = true;
            vm.submitted = true;
        } else {
            if(vm.showStreetNumberModals && checkStreetNumber()) {
                ModalStreetNumber()
                    .add()
                    .result
                    .then();

                vm.showStreetNumberModals = false;
            } else {
                // contactInfoToOrder();
                if (vm.order.contactInfo && vm.order.contactInfo.address && !vm.order.contactInfo.address.country)
                    vm.order.contactInfo.address.country = vm.countries[0]._2;

                if(!vm.order.contactInfo.email && vm.loggedUser.email)
                    vm.order.contactInfo.email = vm.loggedUser.email;

                console.log(vm.order);

                userLoad(true);
                service.setOrder(vm.order).then(({success, data}) => {
                    if (success) {
                        if (steps) {
                            vm.valid = true;
                        } else {
                            $window.location = routes.sluzby.konverzia.krok2;
                        }
                    } else {
                        // todo validation
                    }
                });
            }
        }
    }
}

angular.module("firmaren")
    .controller("KonverziaDokumentovKrok1Controller", KonverziaDokumentovKrok1Controller);
}());
