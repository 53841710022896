;(function() {
/* @ngInject */
function ReadyMadeOzController(IndexService, $window, Helpers, ModalOrder) {

    let vm = this;
    let server = IndexService;

    vm.showAll = showAll;
    vm.order = order;
    vm.scrollTo = Helpers.scrollTo;

    vm.showButton = true;
    vm.showTooltip = false;
    vm.statLoading = false;

    vm.priceWithTwoPercent = "";
    vm.priceWithoutTwoPercent = "";

    server.getReadyMadeOz().then(onReadyMadeOzLoad);

    function onReadyMadeOzLoad(items) {
        vm.allItems = items;
        vm.allItems.forEach(i => {
            if(i.registraciaPrijimatela && vm.priceWithTwoPercent.length === 0)
                vm.priceWithTwoPercent = i.cena.replace(".00", "");
            else if(!i.registraciaPrijimatela && vm.priceWithoutTwoPercent.length === 0)
                vm.priceWithoutTwoPercent = i.cena.replace(".00", "");

            if(vm.priceWithTwoPercent.length > 0 && vm.priceWithoutTwoPercent.length > 0)
                return;
        });
        vm.items = items.slice(0, 4);
    }

    function showAll() {
        vm.items = vm.allItems;
        vm.showButton = false;
        vm.showTooltip = true;
    }

    function order(item, form, trackForm) {
        //console.log(trackForm);
        ModalOrder(server.setContact, item, form, trackForm)
            .add(server.setContact, item, form, trackForm)
            .result
            .then();
    }
}

angular.module("firmaren")
    .controller("ReadyMadeOzController", ReadyMadeOzController);
}());
