;(function() {
/* @ngInject */
function IndexController(ModalVideo, $window, $rootScope, $filter, $translate, ModalIdle, UserService, IndexService) {

  let vm = this;
  let server = UserService;
  let indexService = IndexService;

  vm.isActive = isActive;
  vm.playVideo = playVideo;
  vm.goToPath = goToPath;
  vm.openMobileMenu = false;
  vm.lang = $translate.use();

  vm.stats = [];
  vm.mojaFirmarenUrl = '';
  vm.googleReviews = [];

  let ladeskIdByPath = [
    {path: 'zmeny-vo-firme', id: '98a07b37'},
    {path: 'zalozenie-firmy', id: 'f89ade7a'},
    {path: 'predaj-firmy', id: '02c83bb4'},
    {path: 'zalozenie-zivnosti', id: 'a7c93208'},
    {path: 'rozsirenie-zivnosti', id: 'a7c93208'},
    {path: 'zrusenie-pozastavenie-zivnosti', id: 'a7c93208'},
    {path: 'zmena-zivnosti', id: 'a7c93208'},
    {path: 'zalozenie-obcianskeho-zdruzenia', id: '3389ea76'},
    {path: 'burza-firiem', id: 'dd9b23fd'},
    {path: 'zrusenie-a-likvidacia-sro', id: '480cd5de'}
  ];

  let ladeskMobileIdByPath = [
    {path: 'zmeny-vo-firme', id: 'qjx6o10r'},
    {path: 'zalozenie-firmy', id: '13efn49y'},
    {path: 'predaj-firmy', id: '2t88itsq'},
    {path: 'zalozenie-zivnosti', id: '98f0cx3v'},
    {path: 'rozsirenie-zivnosti', id: '98f0cx3v'},
    {path: 'zrusenie-pozastavenie-zivnosti', id: '98f0cx3v'},
    {path: 'zmena-zivnosti', id: '98f0cx3v'},
    {path: 'zalozenie-obcianskeho-zdruzenia', id: 'xfs4ph45'},
    {path: 'burza-firiem', id: 'ql2z62uk'},
    {path: 'zrusenie-a-likvidacia-sro', id: 'd6pft7fr'}
  ];

  let ladeskArray = $window.innerWidth > 768 ? ladeskIdByPath : ladeskMobileIdByPath;

  let ladesk = ladeskArray.find(obj => $window.location.pathname.startsWith("/" + obj.path));
  let ladeskId = ladesk ? ladesk.id : ($window.innerWidth > 768 ? "3d32e4e0" : "5knw4lat");

  //LiveAgent START-code
  let script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  let inlineScript = document.createTextNode("(function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.async=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document,\n" +
    "'https://firmaren.ladesk.com/scripts/track.js',\n" +
    "function(e){ LiveAgent.createButton('" + ladeskId + "', e); });");
  script.appendChild(inlineScript);
  angular.element(document.body).append(script);
  //LiveAgent END-code

  server.getMojaFirmaUrl().then(url => vm.mojaFirmarenUrl = url);
  indexService.getGoogleReviews().then(data => vm.googleReviews = data);

  function playVideo(url) {
    ModalVideo(url)
      .add()
      .result
      .then();
  }

  function goToPath(path) {
    $window.location = path;
  }

  function isActive(routes) {
    let active = false;
    routes.forEach(function(route){
      if(($window.location.pathname.startsWith(route)) && $window.location.pathname !== "/zalozenie-sro-a-zivnosti"){
        active = true;
      }
    }, this);
    return active;
  }

  var showSpinner = function(promisesToResolve, callFunction) {

      window.promisesToResolve = promisesToResolve;
      window.callFunction = callFunction;

      const wait = $filter('translate')('wait');

      const spinnerTemplate = '<div style="display: block" class="loading_page"> <div> <i class="fa fa-spinner fa-spin"></i> <p>' + wait + '</p> </div> </div>';
      document.querySelector(".spinnerPlaceholder").innerHTML = spinnerTemplate;
  };

  var hideSpinnerWhenAllResolved = function() {

      //ked dorazil posledny promise
    if(window.promisesToResolve === 1){
        document.querySelector(".spinnerPlaceholder").innerHTML = "";
        window.promisesToResolve = 0;
        $rootScope.$broadcast("show-cart-price");
        if(window.callFunction)
          $rootScope.$broadcast(window.callFunction);
    }else{
        window.promisesToResolve--;
    }

  };

  window.showSpinner = showSpinner;
  window.hideSpinnerWhenAllResolved = hideSpinnerWhenAllResolved;

  $rootScope.$on('IdleTimeout', function() {
    console.log('Session expired');
    ModalIdle()
      .add()
      .result
      .then();
  });

}

angular.module("firmaren")
  .controller("IndexController", IndexController);
}());
