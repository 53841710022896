;(function() {
/* @ngInject */
function KonverziaController(IndexService, $window, routes) {

    let vm = this;

    let server = IndexService;

    vm.onSendClick = onSendClick;

    vm.conversionDocs = {
        receive: "personal"
    };
    vm.requiredCheckbox = false;

    vm.agreement = false;

    server.getConversionDocsOrderId().then(onOrderIdLoad);

    function onOrderIdLoad(orderId) {
        if (orderId) {
            vm.conversionDocs.orderId = orderId;
        } else {
            $window.location = routes.index;
        }
    }

    function onSendClick() {
        if (!vm.conversionDocs.agreements) {
            vm.requiredCheckbox = true;
        } else {
            vm.requiredCheckbox = false;
            if (vm.conversionDocs) {
                server.sendConversionDocs(vm.conversionDocs).then((data) => {
                    vm.success = true;
                    const orderId = vm.conversionDocs.orderId;
                    vm.conversionDocs = {
                        orderId: orderId,
                        receive: "personal"
                    };
                    $scope.order.$setUntouched();
                    vm.requiredCheckbox = false;
                    $interval(function () {
                        vm.success = false;
                    }, 2000, 1);
                });
            }
        }
    }
}

angular.module("firmaren")
    .controller("KonverziaController", KonverziaController);
}());
