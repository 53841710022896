;(function() {
/* @ngInject */
function ModalBasicCapital($uibModal, $rootScope) {
  let oldBasicCapital;
  let basicCapital;
  let allFounders;
  return function init(oldBasicCapital_, basicCapital_, allFounders_) {
    oldBasicCapital = oldBasicCapital_;
    basicCapital = basicCapital_;
    allFounders = allFounders_;

    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-basic-capital/modal-basic-capital.html",
      controller: "ModalBasicCapitalController",
      controllerAs: "vm",
      bindToController: true,
      size: "md",
      backdrop: "static",
      resolve: {
        oldBasicCapital: () => oldBasicCapital,
        basicCapital: () => basicCapital,
        allFounders: () => allFounders
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) })
  }
}

/* @ngInject */
function ModalBasicCapitalController($uibModalInstance, $filter, oldBasicCapital, basicCapital, allFounders, $scope) {
  let vm = this;

  vm.changeNewAmount = changeNewAmount;
  vm.setFounder = setFounder;
  vm.changeFounderAmount = changeFounderAmount;

  vm.basicCapital = {
    oldAmount: parseFloat(oldBasicCapital),
    newAmount: parseFloat(basicCapital)
  };
  vm.allFounders = allFounders;
  vm.selectFounders = {};

  vm.founders = [];
  vm.capitalError = false;

  vm.isCollapsed = [true, true, true, true];

  if(vm.basicCapital.newAmount - vm.basicCapital.oldAmount < 0) {
    vm.basicCapital.type = "minus";
    vm.basicCapital.diffAmount = (vm.basicCapital.newAmount - vm.basicCapital.oldAmount)*(-1);
  } else if(vm.basicCapital.newAmount - vm.basicCapital.oldAmount > 0) {
    vm.basicCapital.type = "plus";
    vm.basicCapital.diffAmount = vm.basicCapital.newAmount - vm.basicCapital.oldAmount;
  } else vm.basicCapital.type = "plus";

  function getSelectedFounders() {
    return $filter('filter')(vm.selectFounders[vm.founders.length - 1], function (founder) {
      return founder.index !== vm.founders[vm.founders.length - 1].index;
    });
  }

  vm.allFounders.forEach(function (founder) {
    if(founder.diffAmount) {
      if(vm.founders.length > 0) {
        let allFounderWithoutPrev = getSelectedFounders();
        vm.selectFounders[vm.founders.length] = allFounderWithoutPrev;
      } else vm.selectFounders[0] = vm.allFounders;

      vm.founders.push(founder);
    }
  });
  
  function changeFounderAmount(index) {

    var sumCapital = 0;
    angular.forEach(vm.founders, function (founder) {
        sumCapital += founder.diffAmount;
    });

    if(vm.founders[index+1]) {
      let nextFounderAMount = (vm.founders[index + 1].diffAmount) ? vm.founders[index + 1].diffAmount : 0;
      vm.founders[index + 1].diffAmount = vm.basicCapital.diffAmount - (sumCapital - nextFounderAMount);
    }

    if(!vm.basicCapital.diffAmount || 
      (sumCapital > vm.basicCapital.diffAmount || 
        (sumCapital < vm.basicCapital.diffAmount && vm.founders.length === vm.allFounders.length)))
      vm.capitalError = true;
    else if(sumCapital === vm.basicCapital.diffAmount)
      vm.capitalError = false;
    else {
      if(vm.founders.length < 5) {
        if(!vm.founders[index+1]) {
          let allFounderWithoutPrev = getSelectedFounders();
          if(allFounderWithoutPrev.length > 0) {
            vm.founders.push(allFounderWithoutPrev[0]);
            vm.selectFounders[vm.founders.length - 1] = allFounderWithoutPrev;

            let nextFounderAMount = (vm.founders[index + 1].diffAmount) ? vm.founders[index + 1].diffAmount : 0;
            vm.founders[index + 1].diffAmount = vm.basicCapital.diffAmount - (sumCapital - nextFounderAMount);
          }
        }
      } else vm.capitalError = true;
    }
  }

  function setFounder(founder, index) {
    vm.founders[index] = founder;
    vm.isCollapsed[index] = true;
  }

  function changeNewAmount() {
    if(vm.basicCapital.type === "plus")
      vm.basicCapital.newAmount = vm.basicCapital.oldAmount + vm.basicCapital.diffAmount;
    else vm.basicCapital.newAmount = vm.basicCapital.oldAmount - vm.basicCapital.diffAmount;

    if(vm.basicCapital.diffAmount) {
      if (vm.founders.length === 0) {
        vm.founders.push(vm.allFounders[0]);
        vm.selectFounders[0] = vm.allFounders;
        vm.founders[0].diffAmount = vm.basicCapital.diffAmount;
      }
      if (vm.founders.length === 1)
        vm.founders[0].diffAmount = vm.basicCapital.diffAmount;
    }

    var sumCapital = 0;
    angular.forEach(vm.founders, function (founder) {
      sumCapital += founder.diffAmount;
    });

    if(sumCapital === vm.basicCapital.diffAmount)
      vm.capitalError = false;
  }

  vm.close = () => $uibModalInstance.dismiss("cancel");

  vm.submit = () => {
    if($scope.basicCapital.$invalid) {
      vm.submitted=true;
    } else {
      let result = {
        newAmount: vm.basicCapital.newAmount,
        founder: vm.founders
      };
      $uibModalInstance.close(result);
    }
  }

}

angular.module("firmaren")
  .factory("ModalBasicCapital", ModalBasicCapital)
  .controller("ModalBasicCapitalController", ModalBasicCapitalController);
}());
