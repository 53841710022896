;(function() {
/* @ngInject */
function UctovnikZadarmoController(UctovnikObjednaneService, $window, Helpers){
    let vm = this;
    let server = UctovnikObjednaneService;

    server.getPaymentInfo().then(onPaymentInfoLoad);

    function onPaymentInfoLoad(payment) {
        if(payment) {
            vm.orderId = payment.paymentInfo.orderId;

            var d = Helpers.getDataLayer(vm.orderId, 'uctovnici',
                'ine', '', payment.user);

            var dataLayer = $window.dataLayer = $window.dataLayer || [];
            if (d)
                dataLayer.push(d);
        } else {
            $window.location = "/";
        }
    }
}

angular.module("firmaren")
    .controller("UctovnikZadarmoController", UctovnikZadarmoController);
}());
