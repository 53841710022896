;(function() {
/* @ngInject */
function PlatbaPrevodomController(IndexService, $rootScope, $window, routes) {

    let vm = this;
    let server = IndexService;

    vm.setStatsTB = setStatsTB;

    vm.payment = {};

    window.showSpinner(1);
    server.getPaymentAdnQrCode().then(onPaymentAndQrCodeLoad);

    function onPaymentAndQrCodeLoad(item) {
        if (item && item.vs) {
            vm.payment = item;
            $rootScope.$broadcast('service-load', {orderType: item.orderType, isSingle: item.isSingle});
        } else {
            $window.location = routes.index;
        }
        window.hideSpinnerWhenAllResolved();
    }

    function setStatsTB() {
        server.setStats({
            url: "https://www.moja.firmaren.sk/zlavy-a-akcie/tatrabanka",
            source: "Firmaren-Prevodom",
            medium: "Tatrabanka",
            campaign: "Button"
        }).then();
    }

}

angular.module("firmaren")
    .controller("PlatbaPrevodomController", PlatbaPrevodomController);
}());
