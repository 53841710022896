;(function() {
/* @ngInject */
function BurzaFilterController($rootScope) {
    let vm = this;

    vm.showMoreFilter = false;
    vm.locations = [
        "Bratislavský kraj",
        "Trnavský kraj",
        "Trenčiansky kraj",
        "Nitriansky kraj",
        "Žilinský kraj",
        "Banskobystrický kraj",
        "Prešovský kraj",
        "Košický kraj"
    ];

    vm.resetFilter = resetFilter;

    function resetFilter() {
        $rootScope.$broadcast('burza-filter-reset');
    }
}

angular.module("firmaren")
    .component("burzaFilter", {
        templateUrl: "/app/shared/burza-filter/burza-filter.html",
        controller: BurzaFilterController,
        controllerAs: "vm",
        bindings: {
            filter: "="
        }
    });
}());
