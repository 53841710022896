;(function() {
/* @ngInject */
function ResetPasswordController(UserService, $window, $scope) {
    let vm = this;
    let server = UserService;

    vm.onForgotPasswordClick = onForgotPasswordClick;
    vm.forgotPassword = forgotPassword;
    vm.changePasswordInputType = changePasswordInputType;

    vm.error = false;
    vm.success = false;
    vm.isSentPassword = false;
    vm.passwordInputType = 'password';

    function changePasswordInputType() {
        return vm.passwordInputType === 'password' ? vm.passwordInputType = 'text' : vm.passwordInputType = 'password';
    }

    function onForgotPasswordClick() {
        if ($scope.forgotPasswordForm.$invalid) {
            vm.submitted = true;
        } else {
            server.restorePassword(vm.forgotPasswordUser).then(({success, data}) => {
                if (success) {
                    vm.error = false;
                    vm.success = true;
                    vm.submitted = false;
                    vm.isChanged = true;
                    vm.forgotPasswordUser = {};
                    $scope.forgotPasswordForm.$setPristine();
                    $scope.forgotPasswordForm.$setUntouched();
                } else {
                    vm.error = true;
                }
            }).catch(function (e) {
                vm.error = true;
            });
        }
    }

    function forgotPassword() {
        if($scope.resetPw.$invalid) {
            vm.submitted = true;
        } else {
            server.forgotPassword({email: vm.user.email}).then(({success, data}) => {
                vm.isSentPassword = true;
            }).catch();
        }
    }
}

angular.module("firmaren")
    .controller("ResetPasswordController", ResetPasswordController);
}());
