;(function() {
/* @ngInject */
function ZalozenieZivnostiKrok2Controller(ZalozenieZivnostiKrok2Service, routes, $window, $scope, _, UserService,
                                          $rootScope, ModalFinishLater, $interval, $filter, ModalForgotPassword, $location,
                                          ModalStreetNumber, $translate, Helpers, ModalRedirect, ModalLanguage, removeDiacritics) {
  let vm = this;
  let service = ZalozenieZivnostiKrok2Service;
  vm.service = service;

  vm.genders = ["male", "female"];

  vm.minPricePlace = 0;

  vm.order = {
    additionalInfo: {},
    invoiceTo: {
      type: "company",
      address: {},
      sendInvoice: true
    },
    personalInfo: {
      idNumber: {
      },
      phoneNumber: {
        prefix : "+421"
      },
      contactMe: true,
      address: {},
      dateOfBirth: {},
      foreigner: {
        type: "person"
      }
    },
    contractorInfo: {
      businessAddress: {
        type: "same"
      }
    },
    contactInfo: {
      address: {}
    },
    registerDate: {
      type: "asap",
      date: {}
    },
    domain: {
      name: "",
      isFree: false,
      active: false
    }
  };
  vm.cities = [];
  vm.countries = [];
  vm.insuranceCompanies = [];
  vm.insuranceCompaniesEN = [];
  vm.firmarenPlaces = [];
  vm.cart = {
    size: 0,
    price: 0
  };
  vm.companyName = {};

  vm.persons = [];
  vm.persons.push(vm.order.personalInfo);

  vm.address = "home";
  vm.nextStep = 3;
  vm.showStreetNumberModals = true;
  vm.lang = $translate.use();

  vm.onSaveAndContinueClick = onSaveAndContinueClick;
  vm.onIdNumberChange = onIdNumberChange;
  vm.onNameChange = onNameChange;
  vm.onPlaceRadioButtonClick = onPlaceRadioButtonClick;
  vm.onAddressRadioButtonClick = onAddressRadioButtonClick;
  vm.onBackClick = onBackClick;
  vm.onEmailChange = onEmailChange;
  vm.onPasswordChange = onPasswordChange;
  vm.onCodeChange = onCodeChange;
  vm.onFirmarenPlaceChange = onFirmarenPlaceChange;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.showModalForgotPassword = showModalForgotPassword;
  vm.onZipCodeChange = onZipCodeChange;
  vm.onResetCountry = onResetCountry;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.onElementBlur = Helpers.onElementBlur;
  vm.scrollTo = Helpers.scrollTo;
  vm.onIcoChange = onIcoChange;
  vm.onClick = onClick;
  vm.onCountryChange = onCountryChange;
  vm.onForeignerMandateChange = onForeignerMandateChange;
  vm.onInvoiceNameChange = onInvoiceNameChange;
  vm.onPersonalInfoClick = onPersonalInfoClick;
  vm.fullName = Helpers.fullName;
  vm.formatTypehead = formatTypehead;
  vm.onNationalityChange = onNationalityChange;
  vm.getDomainInfo = getDomainInfo;

  vm.formerSurnameChange = false;
  vm.maxStep = 2;
  vm.valid = false;

  vm.isCollapsed = {
    contactInfo: true,
    additionalInfo: true,
    firmarenAddress: true
  };
  vm.requiredMessage = false;
  vm.foreigner = vm.lang === 'en';

  let server = UserService;

  vm.loggedUser = {};
  vm.user = {};
  vm.errorMessage = null;
  vm.showData = "";
  vm.userExists = false;
  vm.contactInfo = vm.order.personalInfo;
  vm.showContactInfoForm = false;
  vm.showContactInfoSelect = true;
  vm.intermediateStep = Helpers.getUrlParameter('upsell') ? true : false;

  vm.upsells = {};
  vm.isTbCampaign = false;

  vm.isCredit = false;
  // vm.names = "";

  userLoad();

  $scope.$on('user-changed', function(event, args) {
    userLoad();
  });

  $scope.$on('on-password-change', function(event, data) {
    if(vm.order.contactInfo)
      vm.order.contactInfo.password = data;

    onPasswordChange();
  });

  $scope.$on('on-email-change', function(event, args) {
    onEmailChange();
  });

  $scope.$on('on-price-change', function(event, args) {
    onPriceChange();
  });

  $scope.$watch('vm.isCredit', function (newValue, oldValue) {
    if (newValue !== oldValue) {
      onCreditChange();
    }
  }, true);

  $scope.$watch('vm.order.contractorInfo.name', function (newValue, oldValue) {
    if (newValue !== oldValue) {
      getDomainInfo();
    }
  }, true);

  window.showSpinner(10);
  service.getNameDay("trade").then((name) => {
    if(name && name.length > 0)
      vm.names = name.split(", ");

    window.hideSpinnerWhenAllResolved();
  });
  service.getMaxStep().then( (ms) => {vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
  service.getCities().then(onCitiesLoad);
  service.getCountries().then(onCountriesLoad);
  service.getInsuranceCompanies().then(onInsuranceCompaniesLoad);
  service.getFirmarenPlacesOfBusiness().then(onFirmarenPlacesOfBusinessLoad);
  service.getOrder().then(onOrderLoad);
  service.getVoucher().then(onVoucherLoad);
  service.getTBCampaign().then((isTbCampaign) => { vm.isTbCampaign = isTbCampaign; window.hideSpinnerWhenAllResolved() });
  service.getUpsell().then((upsell) => { if(angular.isDefined(upsell)) vm.upsells = upsell; window.hideSpinnerWhenAllResolved(); });
  service.getCompanyName().then(data => {if(data && data.length > 0) vm.order.contractorInfo.name = data});
  onPriceChange();

  // TODO check loaded values

  function contactInfoToOrder() {
    if(!vm.showContactInfoForm) {
      vm.order.contactInfo.name = vm.contactInfo.name;
      vm.order.contactInfo.surname = vm.contactInfo.surname;
      vm.order.contactInfo.address = vm.contactInfo.address;
      vm.order.contactInfo.dateOfBirth = vm.contactInfo.dateOfBirth;
    }
  }

  function onPersonalInfoClick(elem, item) {
    if(elem === "other") {
      vm.showContactInfoForm = true;
      vm.order.contactInfo.type = $filter('translate')('paymentInfo.other');
    } else {
      vm.showContactInfoForm = false;
      vm.contactInfo = item;
      vm.order.contactInfo.type = vm.fullName(item);
    }
    vm.mouseoverContactInfoType = false
  }

  function onClick(item, elem) {
    if(elem === "day") {
        vm.order.personalInfo.dateOfBirth.day = item;
        vm.mouseoverDay = false;
    } else if(elem === "month") {
        vm.order.personalInfo.dateOfBirth.month = item;
        vm.mouseoverMonth = false;
    } else if(elem === "year") {
        vm.order.personalInfo.dateOfBirth.year = item;
        vm.mouseoverYear = false;
    }

    onIdNumberChange();
  }

  function onVoucherLoad(voucher) {
    if(voucher && voucher.voucher) {
      vm.order.additionalInfo.voucher = voucher.voucher;
      vm.isCollapsed.additionalInfo = false;
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onIcoChange(id, elem) {
    service.getFinstatInfo({name: elem}).then( data => {
      if(data) {
        if(id === "foreigner") {
          vm.order.personalInfo.foreigner.company.companyName = data.name;
          vm.order.personalInfo.foreigner.company.address = data.address;
        } else {
          vm.order.invoiceTo.companyName = data.name;
          vm.order.invoiceTo.address = data.address;
          vm.order.invoiceTo.city = data.address.city;
          vm.order.invoiceTo.dic = data.dic;
          vm.order.invoiceTo.icdph = data.icdph;
        }
      }
      vm.order.invoiceTo.address.country = vm.countries[0]._2;
    });
  }

  function onCodeChange() {
    if(vm.order.additionalInfo.voucher) {
      service.setDiscount(vm.order.additionalInfo).then(({ success, error }) => {
        if(success) {
          // TODO docasne FIRM-2342
          vm.voucherError = "temp";
        } else {
          vm.voucherError = error;
        }
      });
    }
    onPriceChange();
  }

  function onForeignerMandateChange() {
    if(vm.order.personalInfo.foreigner.type === "firmaren")
        service.setForeignerPrice({value: "firmaren", country: vm.order.personalInfo.address.country}).then(onPriceChange);
    else if(vm.order.personalInfo.address.country !== vm.countries[0]._2)
      service.setForeignerPrice({value: "foreign", country: vm.order.personalInfo.address.country}).then(onPriceChange);
    else service.setForeignerPrice({value: "base", country: vm.order.personalInfo.address.country}).then(onPriceChange);
  }

  function onFirmarenPlaceChange() {
    if(vm.order.contractorInfo.businessAddress.type == "firmaren" && !vm.order.contractorInfo.businessAddress.id) {
      vm.order.contractorInfo.businessAddress = vm.firmarenPlaces[0];
    }
    if(vm.order.contractorInfo.businessAddress.type == "firmaren") {
      vm.cart.placeOfBusinessPrice = vm.order.contractorInfo.businessAddress.price;
    } else {
      vm.cart.placeOfBusinessPrice = null;
    }
  }

  function onCreditChange() {
    service.setCredit(vm.isCredit.toString()).then(onPriceChange);
  }

  function onPriceChange() {
    service.getCart().then(onCartLoad);
  }

  function onCartLoad(cart) {
    vm.cart = cart;
    onFirmarenPlaceChange();
  }

  function onZipCodeChange(elem, $item) {
    if(elem === "addressCity")
      vm.order.personalInfo.address.zipCode = $item && $item._3;
    if(elem === "businessAddressCity")
      vm.order.contractorInfo.businessAddress.address.zipCode = $item && $item._3;
    if(elem === "licenceDeliveryAddressCity")
      vm.order.contractorInfo.licenceDeliveryAddress.address.zipCode = $item && $item._3;
    if(elem === "foreignerAddress")
      vm.order.personalInfo.foreigner.address.zipCode = $item && $item._3;
    if(elem === "foreignerPerson")
      vm.order.personalInfo.foreigner.person.address.zipCode = $item && $item._3;
    if(elem === "foreignerCompany")
      vm.order.personalInfo.foreigner.company.address.zipCode = $item && $item._3;
  }

  function onCitiesLoad(cities) {
    vm.cities = cities;

    window.hideSpinnerWhenAllResolved();
  }

  function setForeigner() {
    vm.foreigner = true;
    if (vm.order.contractorInfo && vm.order.contractorInfo.businessAddress && vm.order.contractorInfo.businessAddress.type === "same")
      vm.order.contractorInfo.businessAddress.type = "own";
  }

  function changeLanguage(price) {
    service.setForeignerPrice({value: price, country: vm.order.personalInfo.address.country}).then(() => {
      service.setBackContent(JSON.stringify(vm.order)).then(({success, data}) => {
        if (success) {
          if (vm.lang === 'en')
            $window.location = routes.zivnosti.zalozenie.krok2;
          else $window.location = routes.en.zivnosti.zalozenie.krok2;
        }
      });
    });
  }

  function formatTypehead(model){
    if(vm.lang === "en") {
      return $filter("translate")(model);
    }
    else return model;
  }

  vm.translatedMatch = (arrayValue, userInput) => {
    return removeDiacritics($translate.instant(arrayValue).toLowerCase()).includes(removeDiacritics(userInput.toLowerCase()))
  };

  function onCountryChange() {
    if(vm.lang === "en") {
      if(vm.order.personalInfo.address.country === vm.countries[0]._2) {
        ModalRedirect('zalozenieZivnosti.krok2.changeLanguage.title', 'zalozenieZivnosti.krok2.changeLanguage.text')
            .add()
            .result
            .then(result => {
              if(result) {
                vm.foreigner = false;
                if(vm.order.personalInfo) {
                  vm.order.personalInfo.gender = "";
                }
                changeLanguage((vm.order.personalInfo && vm.order.personalInfo .name && vm.names.indexOf(vm.order.personalInfo.name) > -1) ? "nameDay" : "base");
              }
            })
            .catch(() => {
              vm.order.personalInfo.address.country = "";
            });
      } else {
        setForeigner();
        service.setForeignerPrice({value: "foreign", country: vm.order.personalInfo.address.country}).then(onPriceChange);
      }
    } else {
      if(vm.order.personalInfo.address.country !== vm.countries[0]._2) {
        if(!vm.foreigner) {
          ModalRedirect('zalozenieZivnosti.krok2.changeLanguage.title', 'zalozenieZivnosti.krok2.changeLanguage.text')
              .add()
              .result
              .then(result => {
                if (result) {
                  if (vm.order.personalInfo) {
                    vm.order.personalInfo.gender = "";
                  }
                  ModalLanguage()
                      .add()
                      .result
                      .then(lang => {
                        if (lang === "en")
                          changeLanguage("foreign");
                        else {
                          setForeigner();
                          window.showSpinner(2);
                          service.getCountries().then(onCountriesLoad);
                          service.setForeignerPrice({value: "foreign", country: vm.order.personalInfo.address.country ? vm.order.personalInfo.address.country : ""})
                              .then(() => {
                                onPriceChange();
                                window.hideSpinnerWhenAllResolved();
                              });
                          vm.scrollTo("formSubmit");
                        }
                      });
                }
              })
              .catch(() => {
                vm.order.personalInfo.address.country = vm.order.personalInfo.nationality = "";
              });
        }
      } else {
        service.setForeignerPrice({value: (vm.order.personalInfo && vm.order.personalInfo.name && vm.names.indexOf(vm.order.personalInfo.name) > -1) ? "nameDay" : "base", country: vm.order.personalInfo.address.country}).then(onPriceChange);
      }
    }
  }

  function onCountriesLoad(countries) {
    if (vm.foreigner) {
      vm.countries = countries;
    } else {
      vm.countries = countries;

      if(vm.order.personalInfo.address)
        vm.order.personalInfo.address.country = vm.order.personalInfo.nationality ? vm.order.personalInfo.nationality : "";

      if(vm.order.invoiceTo.address)
        vm.order.invoiceTo.address.country = vm.countries[0]._2;

      if(vm.order.contactInfo.address)
        vm.order.contactInfo.address.country = vm.countries[0]._2;
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onNationalityChange() {
    vm.order.personalInfo.address.country = vm.order.personalInfo.nationality;
    vm.onCountryChange();
  }

  function onInsuranceCompaniesLoad(insuranceCompanies) {
    vm.insuranceCompanies = _.map(insuranceCompanies, s=> s._2);
    vm.insuranceCompaniesEN = vm.insuranceCompanies.filter(i => {
      if(i.indexOf("Poisťovňa mimo SR") === -1)
        return i;
    });
    vm.insuranceCompaniesEN.push("Health insurance outside of SR");

    window.hideSpinnerWhenAllResolved();
  }

  function onFirmarenPlacesOfBusinessLoad(places) {
    vm.firmarenPlaces = places;

    vm.minPricePlace = Math.min.apply(null, vm.firmarenPlaces.map(function(item) {
      return item.price;
    }));

    window.hideSpinnerWhenAllResolved();
  }

  function onPlaceRadioButtonClick(value) {
    vm.place = value;
  }

  function onAddressRadioButtonClick(value) {
    vm.address = value;
  }

  function onBackClick() {
    if(vm.intermediateStep) {
      vm.intermediateStep = false;
    } else {
      contactInfoToOrder();
      service.setBackContent(JSON.stringify(vm.order)).then(({success, data}) => {
        if (success) {
          if (vm.lang === 'en')
            $window.location = routes.en.zivnosti.zalozenie.krok1;
          else $window.location = routes.zivnosti.zalozenie.krok1;
        } else {
          // todo validation
        }
      });
    }
  }

  function checkStreetNumber() {
    let addresses = [vm.order.personalInfo && vm.order.personalInfo.address,
      vm.order.personalInfo && vm.order.personalInfo.foreigner && vm.order.personalInfo.foreigner.address,
      vm.order.personalInfo && vm.order.personalInfo.foreigner && vm.order.personalInfo.foreigner.person && vm.order.personalInfo.foreigner.person.address,
      vm.order.personalInfo && vm.order.personalInfo.foreigner && vm.order.personalInfo.foreigner.company && vm.order.personalInfo.foreigner.company.address,
      vm.order.contractorInfo && vm.order.contractorInfo.businessAddress && vm.order.contractorInfo.businessAddress.address,
      vm.order.invoiceTo && vm.order.invoiceTo.address,
      vm.order.contactInfo && vm.order.contactInfo.address];

    var result = false;

    addresses.forEach(address => {
      if (address && address.streetNo && (!address.streetNoPrefix || address.streetNoPrefix === ""))
        result = true;
    });

    return result;
  }

  function onSaveAndContinueClick(steps) {
    if (vm.intermediateStep){
      if (steps) {
        vm.valid = true;
      } else {
        if (vm.upsells.tb && vm.upsells.tb.active && !vm.upsells.tb.agreeFirmaren){
          $scope.step2.tbCheckboxFirmaren.required = true;
          vm.scrollTo("tbCheckbox");
        } else if (vm.upsells.superfaktura && vm.upsells.superfaktura.active && !vm.upsells.superfaktura.agree) {
          $scope.step2.superfakturaCheckbox.required = true;
          vm.scrollTo("superfakturaCheckbox");
        } else if (vm.upsells.znamka && vm.upsells.znamka.active && !vm.upsells.znamka.agree) {
          $scope.step2.znamkaCheckbox.required = true;
          vm.scrollTo("znamkaCheckbox");
        } else if (vm.upsells.universal && vm.upsells.universal.active && (vm.upsells.universal.type.length === 0 || !vm.upsells.universal.agree || !vm.upsells.universal.agreeFirmaren)) {
          if(vm.upsells.universal.type.length === 0) {
            $scope.step2.upsellServicesError = true;
            vm.scrollTo("upsell-services");
          } else if(!vm.upsells.universal.agree) {
            $scope.step2.universalCheckbox.required = true;
            vm.scrollTo("universalCheckbox");
          } else if(!vm.upsells.universal.agreeFirmaren) {
            $scope.step2.universalCheckboxFirmaren.required = true;
            vm.scrollTo("universalCheckbox");
          }
        } else if (vm.upsells.saltpay && vm.upsells.saltpay.active && !vm.upsells.saltpay.agree) {
          $scope.step2.saltpayCheckbox.required = true;
          vm.scrollTo("saltpayCheckbox");
        } else if (vm.upsells.saltpay && vm.upsells.saltpay.active && !vm.upsells.saltpay.agreeFirmaren) {
          $scope.step2.saltpayCheckboxFirmaren.required = true;
          vm.scrollTo("saltpayCheckbox");
        } else {
          $scope.step2.$submitted = true;
          if (vm.lang === 'en') {
            $window.location = routes.en.zivnosti.zalozenie.krok3;
          } else {
            $window.location = routes.zivnosti.zalozenie.krok3;
          }
        }
      }
    } else {
      vm.order.additionalInfo.comment = $filter("removeEmojiFilter")(vm.order.additionalInfo.comment);

      if($scope.step2.$invalid) {
        vm.isCollapsed = {
          contactInfo: false,
          additionalInfo: true,
          firmarenAddress: true
        };
        vm.requiredMessage = true;
        //scrollTo("content");
        vm.submitted=true;
      } else {
        if (vm.showStreetNumberModals && checkStreetNumber()) {
          ModalStreetNumber()
            .add()
            .result
            .then();

          vm.showStreetNumberModals = false;
        } else {
          userLoad(true);
          vm.order.contactInfo.phoneNumber = vm.order.personalInfo.phoneNumber.prefix + vm.order.personalInfo.phoneNumber.number;

          contactInfoToOrder();
          if (vm.order.contactInfo && vm.order.contactInfo.address && !vm.order.contactInfo.address.country)
            vm.order.contactInfo.address.country = vm.countries[0]._2;

          service.setOrder(vm.order).then(({success, data}) => {
            if (success) {
              if (steps) {
                vm.valid = true;
              } else {
                vm.intermediateStep = true;
                vm.scrollTo("upsells");
              }
            } else {
              // todo validation
            }
          });
        }
      }
    }
  }

  function userLoad(lastStep) {
    onEmailChange();
    onPasswordChange(lastStep);
    if(!lastStep)
      onUserChange();
  }

  function onOrderLoad(order) {
    if(order) {
      vm.order = order;
      onNameChange();
      onFirmarenPlaceChange();
      userLoad();

      if(order.registerDate && order.registerDate.date)
        order.registerDate.date = new Date(order.registerDate.date);

      if(vm.order.personalInfo && vm.order.personalInfo.contactMe === undefined)
        vm.order.personalInfo.contactMe = true;
    } else {
      service.getStringContent().then((data) => {
        if(data) {
          vm.order = JSON.parse(data);
          onNameChange();
          onFirmarenPlaceChange();
          userLoad();

          if(vm.order.contactInfo) {
            vm.contactInfo.name = vm.order.contactInfo.name;
            vm.contactInfo.surname = vm.order.contactInfo.surname;
            vm.contactInfo.address = vm.order.contactInfo.address;
            vm.contactInfo.dateOfBirth = vm.order.contactInfo.dateOfBirth;
          }

          if(vm.order.personalInfo && !vm.order.personalInfo.dateOfBirth)
            vm.order.personalInfo.dateOfBirth = {};

          if(vm.order.personalInfo && vm.order.personalInfo.contactMe === undefined)
            vm.order.personalInfo.contactMe = true;

          if(vm.order.registerDate && vm.order.registerDate.date)
            vm.order.registerDate.date = new Date(vm.order.registerDate.date);
        } else {
          service.getPlaceOfBusiness().then((pb) => {
            if(pb) {
              vm.order.contractorInfo.businessAddress = pb;
              vm.order.contractorInfo.businessAddress.type = "firmaren";
              onFirmarenPlaceChange();
            }
          });
        }
      });
    }

    if(vm.order &&
        vm.order.personalInfo &&
        vm.order.personalInfo.address &&
        vm.order.personalInfo.address.country !== vm.countries[0]._2 &&
        vm.order.personalInfo.address.country !== "") {
      vm.foreigner = true;
    }

    window.hideSpinnerWhenAllResolved();
  }

  function getDomainInfo() {
    if(!vm.order.domain) {
      vm.order.domain = {
        name: "",
        isFree: false,
        active: false
      }
    }
    vm.order.domain.name = !vm.order.contractorInfo.name ? vm.order.personalInfo.surname : vm.order.contractorInfo.name;
  }

  function onInvoiceNameChange() {
    vm.invoiceName = vm.companyName.prefix
      .concat(vm.order.contractorInfo.name ? " - " + vm.order.contractorInfo.name : "");
  }

  function onNameChange() {
    vm.companyName.prefix = vm.fullName(vm.order.personalInfo);
    vm.contactInfo = vm.order.personalInfo;
    onInvoiceNameChange();
    if(!vm.order.contactInfo)
      vm.order.contactInfo = {};
    vm.order.contactInfo.type = vm.companyName.prefix;

    // TODO name day docasne nepouzivat
    // if(vm.names && vm.order.personalInfo && vm.order.personalInfo.name && vm.lang != "en") {
    //   service.setNameDay((vm.names.indexOf(vm.order.personalInfo.name) > -1).toString()).then(onPriceChange());
    // }
  }

  function onIdNumberChange() {
    if(vm.order.personalInfo.gender) {
      $scope.step2.dateOfBirthYear.$validate();
      if (vm.order) {
          vm.order.personalInfo.idNumber.prefix =
              calculateYearPart() +
              calculateMonthPart() +
              calculateDayPart();
      }
    } else {
      vm.onElementFocus("gender");
      vm.onElementBlur("gender");
      vm.scrollTo("gender");
    }
  }

  function calculateYearPart() {
    return vm.order.personalInfo.dateOfBirth && vm.order.personalInfo.dateOfBirth.year && vm.order.personalInfo.dateOfBirth.year > 1000 ? vm.order.personalInfo.dateOfBirth.year.toString().substring(2,4) : "";
  }

  function calculateMonthPart() {
    if (vm.order.personalInfo.dateOfBirth && vm.order.personalInfo.dateOfBirth.month && vm.order && vm.order.personalInfo && vm.order.personalInfo.gender) {
      let month = parseInt(vm.order.personalInfo.dateOfBirth.month);
      let result = vm.order.personalInfo.gender === "Žena" ? month + 50 : month;
      result = roundDate(result);
      return result;
    } else {
      return "";
    }
  }

  function calculateDayPart() {
    return vm.order.personalInfo.dateOfBirth && vm.order.personalInfo.dateOfBirth.day ? roundDate(parseInt(vm.order.personalInfo.dateOfBirth.day)) : "";
  }

  function roundDate(number) {
    return number > 9 ? number : "0" + number;
  }

  function onPasswordChange(lastStep){
    if(vm.order.contactInfo && vm.order.contactInfo.email) {
      server.login({
        email: vm.order.contactInfo.email,
        password: vm.order.contactInfo.password
      }).then(({success, data}) => {
        if (success) {
          vm.errorMessage = null;
          if(!lastStep)
            onUserChange();
          $rootScope.$broadcast('user-changed-contact');
        } else {
          vm.errorMessage = "bad";
        }
      }).catch(vm.errorMessage = "bad");
    }
  }

  function onUserChange() {
    vm.errorMessage = null;
    server.getLoggedUser().then(onLoggedUserLoad);
  }

  function onLoggedUserLoad(user) {
    vm.loggedUser = user;
    if(!vm.order.contactInfo) {
      vm.order.contactInfo = {};
    }
    if(user) {
      vm.order.contactInfo.email = user.email;
      vm.order.contactInfo.phoneNumber = $filter("whitespaceFilter")(user.phoneNumber);
      vm.order.contactInfo.name = user.firstName;
      vm.order.contactInfo.surname = user.lastName;
      vm.order.contactInfo.address = user.address;
      vm.order.contactInfo.dateOfBirth = user.dateOfBirth;
      vm.order.contactInfo.hasCompany = user.hasCompany;
      vm.order.contactInfo.companyName = user.companyName;
      vm.order.contactInfo.ico = user.ico;
      vm.order.contactInfo.dic = user.dic;
      vm.order.contactInfo.icDph = user.icDph;

      if(vm.loggedUser.credit > 0) {
        service.getUsedCredits().then((credits) => {
          if(credits > 0) {
            vm.isCredit = true;
          }
        });

        vm.isCollapsed.additionalInfo = false;
      }

      if(user.firstName !== "" && user.lastName !== "") {
        vm.showContactInfoForm = true;
        vm.showContactInfoSelect = false;
      }

      if(!vm.foreigner) {
        server.isOldUser().then((ms) => {
          if (ms)
            service.getOldUserPrice().then(onPriceChange);
        });
      }
    }

    if(!vm.order.contactInfo.phoneNumber) {
      vm.order.contactInfo.phoneNumber = "+421";
    }
  }

  function onEmailChange() {
    vm.loginDisabled = true;
    vm.errorMessage = "";
    if(vm.order.contactInfo && vm.order.contactInfo.email) {
      vm.loginSpinner = true;
      server.isFree({email: vm.order.contactInfo.email}).then(({success, data}) => {
        vm.loginSpinner = false;
        vm.loginDisabled = false;
        if (success) {
          vm.userExists = false;
        } else {
          if (vm.order.contactInfo && vm.order.contactInfo.password && vm.order.contactInfo.password !== "")
            onPasswordChange();
          vm.userExists = true;
        }
      }).catch(vm.userExists = false);
    }
  }

  function showModalForgotPassword() {
    ModalForgotPassword(vm.order.contactInfo.email)
        .add()
        .result
        .then();
  }

  function onSaveForLaterClick() {
    contactInfoToOrder();

    ModalFinishLater(service, JSON.stringify(vm.order))
      .add(service, JSON.stringify(vm.order))
      .result
      .then();
  }

  function onResetCountry(id) {
    if(id === "address")
      vm.order.personalInfo.address.country = "";
    else if(id === "foreigner.nationality")
      vm.order.personalInfo.nationality = "";
    else vm.order.invoiceTo.address.country = "";
    $interval(function() {
      let element = $window.document.getElementById(id);
      if(element)
        element.focus();
    }, 100, 1);
  }

}

angular.module("firmaren")
  .controller("ZalozenieZivnostiKrok2Controller", ZalozenieZivnostiKrok2Controller);
}());
