;(function() {
/* @ngInject */
function PersonalInfoController(UserService, $window, $rootScope, Helpers, $timeout, $interval) {
  let vm = this;

  let server = UserService;

  $timeout(function() {
    if(vm.model && vm.model.address && vm.model.address.streetNo && vm.model.address.streetNo.indexOf("/") > -1) {
      let splitStreetNo = vm.model.address.streetNo.split("/");
      vm.model.address.streetNo = splitStreetNo[1];
      vm.model.address.streetNoPrefix = splitStreetNo[0];
    }
  });

  vm.onZipCodeChange = onZipCodeChange;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.onResetCountry = onResetCountry;
  vm.onIcoChange = onIcoChange;

  function onIcoChange() {
    server.getFinstatInfo(vm.model.ico).then( data => {
      if(data) {
        vm.model.companyName = data.name;
        vm.model.dic = data.dic;
        vm.model.icDph = data.icdph;
      }
    });
  }

  function onZipCodeChange($item) {
    vm.model.address.zipCode = $item && $item._3;
  }


  function onResetCountry(id) {
    vm.model.address.country = "";
    $interval(function() {
      vm.onElementFocus(id);
    }, 100, 1);
  }
}

angular.module("firmaren")
  .component("personalInfo", {
    templateUrl: "/app/shared/personal-info/personal-info.html",
    controller: PersonalInfoController,
    controllerAs: "vm",
    bindings: {
      model: "=",
      form: "=",
      submitted: "=",
      cities: "=",
      countries: "="
    }
  });
}());
