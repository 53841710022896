;(function() {
/* @ngInject */
function ModalNameDay($uibModal, $rootScope) {
    let title, desc,text;

    return function init(title_, desc_, text_) {
        title = title_;
        desc = desc_;
        text = text_;

        return { add };
    };

    function open(options = {}) {
        return $uibModal.open(angular.extend({
            templateUrl: "/app/shared/modal-name-day/modal-name-day.html",
            controller: "ModalNameDayController",
            controllerAs: "vm",
            bindToController: true,
            backdrop: "static",
            size: "md",
            resolve: {
                title: () => title,
                desc: () => desc,
                text: () => text
            }
        }, options));
    }

    function add(scope = {}) {
        return open({ scope: angular.extend($rootScope.$new(true), scope) })
    }
}

/* @ngInject */
function ModalNameDayController($uibModalInstance, title, desc, text) {
    let vm = this;

    vm.title = title;
    vm.desc = desc;
    vm.text = text;

    vm.close = () => $uibModalInstance.dismiss("cancel");

}

angular.module("firmaren")
    .factory("ModalNameDay", ModalNameDay)
    .controller("ModalNameDayController", ModalNameDayController);
}());
