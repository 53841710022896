;(function() {
/* @ngInject */
function VirtualneSidloKrok1Controller(VirtualneSidloKrok1Service, IndexService, $window, $scope, ModalFinishLater, ModalInfo) {
    let vm = this;
    let service = VirtualneSidloKrok1Service;

    vm.onSaveAndContinueClick = onSaveAndContinueClick;
    vm.onSaveForLaterClick = onSaveForLaterClick;
    vm.onElementFocus = onElementFocus;

    vm.maxStep = 1;
    vm.nextStep = 2;
    vm.valid = false;
    vm.cart = {
        text: "/rok"
    };
    vm.order = {
        phoneNumber: {
            prefix : "+421"
        },
        seatAddress: {
            address: {}
        },
        services: {
            shipment: "0",
            photo: "0",
            notification: "0",
            // personalCollection: "0",
            // online: "0",
            scan: "0",
            send: "0",
            englishSupport: "0",
            withoutCommitment: "0"
        }
    };
    vm.contract = ["0", "3", "6", "1", "2"];

    window.showSpinner(5);
    service.getMaxStep().then( (ms) => { vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
    service.getOrder().then(onOrderLoad);
    service.getPrice().then(onPriceLoad);
    service.getCities().then(onCitiesLoad);
    service.getRegionalCities().then(onRegionalCitiesLoad);

    function onPriceLoad(tp) {
        vm.cart.totalPrice = tp;
        window.hideSpinnerWhenAllResolved();
    }

    function onRegionalCitiesLoad(items) {
        vm.regionalCities = items;
        window.hideSpinnerWhenAllResolved();
    }

    function onCitiesLoad(ps) {
        vm.cities = ps;
        window.hideSpinnerWhenAllResolved();
    }

    function onSaveAndContinueClick(steps) {
        if($scope.step1.$invalid) {
            vm.requiredMessage = true;
            //scrollTo("content");
            vm.submitted=true;
        } else {
            service.setSeatInfo(vm.order).then(() => {
                if (steps) {
                    vm.valid = true;
                } else {
                    $window.location = "krok2";
                }
            });
        }
    }

    function onSaveForLaterClick() {
        ModalFinishLater(service, JSON.stringify(vm.order))
            .add(service, JSON.stringify(vm.order))
            .result
            .then();
    }

    function onOrderLoad(order) {
        if(order.seatInfo) {
            vm.order = order.seatInfo;
        } else {
            service.getStringContent().then((data) => {
                if (data) {
                    vm.order = JSON.parse(data);
                }
            });
        }

        window.hideSpinnerWhenAllResolved();
    }

    function onElementFocus(id) {
        let element = $window.document.getElementById(id);
        if(element)
            element.focus();
    }

    // TODO zablokovanie bratislavy
    // $scope.$watch('vm.order.seatAddress.address.city', function() {
    //     if (vm.order.seatAddress.address && vm.order.seatAddress.address.city && vm.order.seatAddress.address.city.includes("Bratislava")){
    //         ModalInfo("virtualneSidlo.krok1.infoTitle", "virtualneSidlo.krok1.limitExceededInfo", "buttons.understand")
    //         .add()
    //         .result
    //         .then(vm.order.seatAddress.address = {});
    //     }
    //   }, true);
}

angular.module("firmaren")
    .controller("VirtualneSidloKrok1Controller", VirtualneSidloKrok1Controller);
}());
