;(function() {
/* @ngInject */
function StickyScrolling($document, $interval) {
  return {
    restrict: "A",
    link: function ($scope, $element) {

      function sticky() {
        if (window.matchMedia('(min-width: 60em)').matches)
          $interval(function() {
            let $container = $document.find(".sticky-container");
            var top = $container.offset().top;
            var bottom = $(document).height() - $container.height() - top;
            var cart = $(".cart_position").height();
            $element.sticky({topSpacing: 200, bottomSpacing: bottom + cart});
          }, 1000, 3);
        //vykonanie 3x koli zistovaniu vysky kosika, ktory sa dlho nacitava
      }

      sticky();

      $scope.$on('change-sticky-container', function (event, args) {
        sticky();
      });

    }
  };
}

angular.module("firmaren")
  .directive("firmarenStickyScrolling", StickyScrolling);
}());
