;(function() {
/* @ngInject */
function ModalPlaceOfBusiness($uibModal, $rootScope) {
  let placeOfBusiness;
  let cities;
  let firmarenPlacesOfBusiness;
  let firmarenAddress;
  let oldAddress;

  return function init(placeOfBusiness_, cities_, firmarenPlacesOfBusiness_, firmarenAddress_, oldAddress_) {
    placeOfBusiness = placeOfBusiness_;
    cities = cities_;
    firmarenPlacesOfBusiness = firmarenPlacesOfBusiness_;
    firmarenAddress = firmarenAddress_;
    oldAddress = oldAddress_;
    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-place-of-business/modal-place-of-business.html",
      controller: "ModalPlaceOfBusinessController",
      controllerAs: "vm",
      bindToController: true,
      size: "lg",
      backdrop: "static",
      resolve: {
        placeOfBusiness: () => placeOfBusiness,
        cities: () => cities,
        firmarenPlacesOfBusiness: () => firmarenPlacesOfBusiness,
        firmarenAddress: () => firmarenAddress,
        oldAddress: () => oldAddress
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) }, placeOfBusiness, cities, firmarenPlacesOfBusiness, firmarenAddress, oldAddress)
  }
}

/* @ngInject */
function ModalPlaceOfBusinessController($scope, $filter, $window, Helpers, $uibModalInstance, placeOfBusiness, cities, firmarenPlacesOfBusiness, firmarenAddress, oldAddress) {
  let vm = this;

  vm.placeOfBusiness = angular.copy(placeOfBusiness);
  vm.cities = cities;
  vm.firmarenPlacesOfBusiness = angular.copy(firmarenPlacesOfBusiness);
  vm.firmarenAddress = angular.copy(firmarenAddress);
  vm.oldAddress = angular.copy(oldAddress);
  vm.placeOfBusinessKinds = ["area", "flat", "otherBuilding", "house", "garage"];
  if(!vm.placeOfBusiness) {
    vm.placeOfBusiness = {
      type: "own",
      kind: $filter("translate")('placeOfBusinessKind.' + vm.placeOfBusinessKinds[0])
    };
  }
  vm.isCollapsed = {
    firmarenAddress: true
  };

  //console.log(vm.firmarenAddress);

  vm.setFirmarenPlaceOfBusiness = setFirmarenPlaceOfBusiness;
  vm.onCityChange = onCityChange;
  vm.onFirmarenAddressClick = onFirmarenAddressClick;
  vm.onElementFocus = Helpers.onElementFocus;

  function onFirmarenAddressClick() {
    vm.isCollapsed.firmarenAddress = !vm.isCollapsed.firmarenAddress;
  }

  function onCityChange($item) {
    vm.placeOfBusiness.address.zipCode = $item && $item._3;
  }

  function setFirmarenPlaceOfBusiness(place) {
    vm.firmarenAddress = place;
    vm.isCollapsed.firmarenAddress = true;
  }

  vm.close = () => $uibModalInstance.dismiss("cancel");

  vm.submit = () => {
    if($scope.placeOfBusiness.$invalid) {
      vm.submitted=true;
    } else {
      $uibModalInstance.close([vm.placeOfBusiness, vm.firmarenAddress]);
    }
  }

}

angular.module("firmaren")
  .factory("ModalPlaceOfBusiness", ModalPlaceOfBusiness)
  .controller("ModalPlaceOfBusinessController", ModalPlaceOfBusinessController);
}());
