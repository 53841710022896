;(function() {
/* @ngInject */
function ModalSearchFaq($uibModal, $rootScope) {
  let results;
  let searchStr;
  return function init(results_, searchStr_) {
    results = results_;
    searchStr = searchStr_;
    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-search-faq/modal-search-faq.html",
      controller: "ModalSearchFaqController",
      controllerAs: "vm",
      bindToController: true,
      size: "lg",
      resolve: {
        results: () => results,
        searchStr: () => searchStr
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) , results, searchStr})
  }
}

/* @ngInject */
function ModalSearchFaqController($uibModalInstance, results, normalize, searchStr) {
  let vm = this;

  vm.foundSubstr = foundSubstr;
  vm.openQuestion = openQuestion;

  vm.results = results;
  vm.searchStr = searchStr;

  function openQuestion(item) {
    $uibModalInstance.close([item.index, item.category, item.subcategory, item.question]);
  }

  function foundSubstr(str) {
    let position = normalize(str).indexOf(vm.searchStr);

    let prefix = str.substring(position - 80, position - 71).substring(str.substring(position - 80, position - 71).indexOf(">") + 1, 10);
    let suffix = str.substring(position + 71, position + 80).substring(0, str.substring(position + 71, position + 80).indexOf("<"));

    let sub = "..." + prefix + str.substring(position - 70, position + vm.searchStr.length + 70) + suffix + "...";
    var div = document.createElement("div");
    div.innerHTML = sub;
    let newStr = div.innerHTML;

    if(position !== -1) {
      var substr = str.substring(position, position + vm.searchStr.length);
      //let newReplace = new RegExp(substr,'gi');
      return newStr.replace(substr, "<span class='found'>" + substr + "</span>");
    } else return newStr;
  }

  vm.close = () => $uibModalInstance.dismiss("cancel");

}

angular.module("firmaren")
  .factory("ModalSearchFaq", ModalSearchFaq)
  .controller("ModalSearchFaqController", ModalSearchFaqController);
}());
