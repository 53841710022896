;(function() {
angular.module('firmaren').config(['IdleProvider', 'TitleProvider', function(IdleProvider, TitleProvider) {
    TitleProvider.enabled(false); // to not show countdown in the tab
    IdleProvider.idle(60*60);
    IdleProvider.timeout(1); 

    IdleProvider.interrupt('keydown wheel mousemove mousedown touchstart touchmove scroll');
}]);

angular.module('firmaren').run(['Idle', function(Idle) {
    Idle.watch();
}]);
}());
