;(function() {
/* @ngInject */
function LikvidaciaOzController($interval, $scope, IndexService, Helpers, $window) {

    let vm = this;

    let server = IndexService;

    vm.agreement = false;

    vm.onSendClick = onSendClick;

    function initForm() {
        vm.order = {
            phoneNumber: {
                prefix: "+421"
            }
        };
    }

    initForm();

    function onSendClick() {
        if (vm.order) {
            server.liquidationCivic(vm.order).then((data) => {
                vm.success = true;

                var d = Helpers.getDataLayer(-1, 'likvidacia_oz',
                    'ine', '');

                var dataLayer = $window.dataLayer = $window.dataLayer || [];
                if (d)
                    dataLayer.push(d);

                initForm();
                vm.agreement = false;
                $scope.order.$setUntouched();
                $interval(function () {
                    vm.success = false;
                }, 2000, 1);
            });
        }
    }

}

angular.module("firmaren")
    .controller("LikvidaciaOzController", LikvidaciaOzController);
}());
