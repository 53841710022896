;(function() {
/* @ngInject */
function ModalConfirmDelete($uibModal, $rootScope) {
  let text;
  return function init(text_) {
    text = text_;
    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-confirm-delete/modal-confirm-delete.html",
      controller: "ModalConfirmDeleteController",
      controllerAs: "vm",
      bindToController: true,
      size: "md",
      backdrop: "static",
      resolve: {
          text: () => text
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope), text})
  }
}

/* @ngInject */
function ModalConfirmDeleteController($uibModalInstance, text, $window) {
  let vm = this;

  vm.text = text;

  vm.close = () => $uibModalInstance.dismiss("cancel");
  vm.submit = () => {
    $uibModalInstance.close("success");
  }
}

angular.module("firmaren")
  .factory("ModalConfirmDelete", ModalConfirmDelete)
  .controller("ModalConfirmDeleteController", ModalConfirmDeleteController);
}());
