;(function() {
/* @ngInject */
function removeDiacriticsFilter(removeDiacritics) {
  return function(items, props) {

    let out = [];
    items.forEach(function (item) {

      let itemLower = removeDiacritics(item._2.toLowerCase());
      let propsLower = removeDiacritics(props._2.toLowerCase());

        if(itemLower.match(propsLower)) {
          out.push(item);
        }
      }
    );


    return out;
  }
}
angular.module("firmaren")
  .filter("removeDiacriticsFilter", removeDiacriticsFilter);
}());
