;(function() {
/* @ngInject */
function VideoInstructionController($timeout) {
    let vm = this;

    vm.showInstruction = false;

    $timeout( function(){
        vm.showInstruction = true;
        closeInstruction();
    }, 5000 );

    function closeInstruction() {
        $timeout( function(){
            vm.showInstruction = false;
        }, 60000 );
    }
}

angular.module("firmaren")
    .component("videoIntruction", {
        templateUrl: "/app/shared/video-instruction/video-instruction.html",
        controller: VideoInstructionController,
        controllerAs: "vm",
        bindings: {
            link: "=",
            image: "="
        }
    });
}());
