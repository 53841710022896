;(function() {
/* @ngInject */
function ModalFinishLater($uibModal, $rootScope) {
  let service;
  let order;

  return function init(service_, order_) {
    service = service_;
    order = order_;

    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-finish-later/modal-finish-later.html",
      controller: "ModalFinishLaterController",
      controllerAs: "vm",
      bindToController: true,
      size: "md",
      backdrop: "static",
      resolve: {
        service: () => service,
        order: () => order
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) }, service, order)
  }
}

/* @ngInject */
function ModalFinishLaterController($uibModalInstance, service, UserService, $window, order) {
  let vm = this;

  let server = UserService;
  vm.service = service;
  vm.isForm = true;

  server.getLoggedUser().then(onLoggedUserLoad);

  function onLoggedUserLoad(user) {
    if(user) {
      vm.email = user.email;
    }
  }
  
  vm.close = () => $uibModalInstance.dismiss("cancel");
  vm.submit = () => {
    vm.service.saveForLater({email: vm.email, path: $window.location.pathname + location.search, content: order}).then(data => {

    });
    vm.isForm = false;
  }
}

angular.module("firmaren")
  .factory("ModalFinishLater", ModalFinishLater)
  .controller("ModalFinishLaterController", ModalFinishLaterController);
}());
