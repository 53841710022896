;(function() {
/* @ngInject */
function ModalUpsell($uibModal, $rootScope) {
    let showOtherDesc;
    let submitText;
    let cancelText;
    return function init(showOtherDesc_, submitText_, cancelText_) {
        showOtherDesc = showOtherDesc_;
        submitText = submitText_;
        cancelText = cancelText_;
        return { add };
    };

    function open(options = {}) {
        return $uibModal.open({
            templateUrl: "/app/shared/modal-upsell/modal-upsell.html",
            controller: "ModalUpsellController",
            controllerAs: "vm",
            bindToController: true,
            backdrop: "static",
            resolve: {
                showOtherDesc: () => showOtherDesc,
                submitText: () => submitText,
                cancelText: () => cancelText
            }
        });
    }

    function add(scope = {}) {
        return open({ scope: angular.extend($rootScope.$new(true), scope), showOtherDesc, submitText, cancelText})
    }
}

/* @ngInject */
function ModalUpsellController($uibModalInstance, showOtherDesc, submitText, cancelText) {
    let vm = this;

    vm.showOtherDesc = showOtherDesc;
    vm.submitText = submitText;
    vm.cancelText = cancelText;

    vm.close = () => {
        $uibModalInstance.close("close");
    };

    vm.cancel = () => {
        $uibModalInstance.close("cancel");
    };

    vm.submit = () => {
        $uibModalInstance.close("confirm");
    };
}

angular.module("firmaren")
    .factory("ModalUpsell", ModalUpsell)
    .controller("ModalUpsellController", ModalUpsellController);
}());
