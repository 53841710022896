;(function() {
/* @ngInject */
function FileUpload() {
  return {
    restrict: 'E',
    templateUrl: "/app/shared/file-upload/file-upload-view.html",
    scope : {
      file: "=",
      tooltip: "=",
      required: "=",
      label: "=",
      onlyImages: "=",
      multiple: "=",
      onlyZep: "=",
      maxFiles: "="
    },
    controller: FileUploadController,
    controllerAs: "vm"
  };
}

/* @ngInject */
function FileUploadController($scope, Upload, $interval, IndexService, $timeout) {
  let vm = this;

  vm.uploading = false;
  vm.errorSize = false;
  vm.errorType = false;
  vm.errorImagesType = false;
  vm.errorZepType = false;
  vm.filesLoaded = false;
  vm.progressPercentage = 0;

  let service = IndexService;

  vm.files = [];
  vm.fileNames = [];

  $scope.$watch(function(scope) {return scope.file},function () {
    if ($scope.file && !vm.filesLoaded) {
      vm.filesLoaded = true;
      if (Array.isArray($scope.file)) {
        if ($scope.onlyImages){
          $scope.file.forEach(function (img) {
            service.getUploadedImage(img).then((i) => {
              vm.files.push(i);
            });
          });
        }
        
        $scope.file.forEach(file => vm.fileNames.push(file.replace(/^.*[\\\/]/, '')));
        vm.inputLabel = vm.fileNames.join(', ');
      } else {
        if ($scope.onlyImages){
          service.getUploadedImage($scope.file).then((i) => {
            vm.imageFile = i;
          });
        }
        vm.inputLabel = $scope.file.replace(/^.*[\\\/]/, '');
      }
    }

    vm.uploadedFile = $scope.file && (!$scope.multiple || ($scope.file.length == $scope.maxFiles));

  });

  vm.upload = function (file) {
    if (file) {
      vm.progressPercentage = 0;
      vm.uploading = true;
      vm.errorSize = false;
      vm.errorType = false;
      vm.errorImagesType = false;
      vm.errorZepType = false;

      const maxSize = 5 * 1024 * 1024;

      let isPossibleType = false;
      if ($scope.onlyImages)
        isPossibleType = file.type.indexOf("image") !== -1;
      else if($scope.onlyZep) {
        var ext = file.name.split('.').pop();
        isPossibleType = ext === "zep" || ext === "zepx" || ext === "asice";
      } else isPossibleType = file.type.indexOf("image") !== -1 ||
          file.type.indexOf("pdf") !== -1 ||
          file.type.indexOf("msword") !== -1 ||
          file.type.indexOf("openxmlformats-officedocument") !== -1;

      if (file.size < maxSize) {
        if (isPossibleType) {
          Upload.upload({
            url: '/fileUpload?fileName=' + file.name + '&uploadType=zmenazivnosti',
            data: {file: file}
          }).then(function (resp) {
            console.log('Success ' + resp.config.data.file.name + ' uploaded. Response: ' + resp.data);
            vm.files.push(file);
            if($scope.multiple) {
              $scope.file.push(resp.data.path);
              vm.fileNames.push(resp.config.data.file.name);
              vm.inputLabel = vm.fileNames.join(', ');
              if ($scope.file.length == $scope.maxFiles){
                vm.uploadedFile = true;
              }
            }
            else {
              $scope.file = resp.data.path;
              vm.inputLabel = resp.config.data.file.name;
              vm.uploadedFile = true;
              vm.imageFile = file;
            }
            vm.uploading = false;
          }, function (resp) {
            console.log('Error status: ' + resp.status);
          }, function (evt) {
            vm.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            console.log('progress: ' + vm.progressPercentage + '% ' + evt.config.data.file.name);
          });
        } else {
          if ($scope.onlyImages)
            vm.errorImagesType = true;
          else if($scope.onlyZep)
            vm.errorZepType = true;
          else vm.errorType = true;
        }
      } else {
        vm.errorSize = true;
      }
    
      //vm.uploading = false;
      //console.log(vm.uploading);
    }
  };

  vm.uploadFiles = function (files) {
    if (!$scope.file) {
      $scope.file = [];
      vm.files = [];
    }

    files.forEach(file => {
      vm.upload(file);
    });
  };

  vm.clean = function () {
    $scope.file = null;
    vm.inputLabel = null;
    vm.uploadedFile = false;
    vm.imageFile = null;
  };

  vm.specificClean = function (file, isImage) {
    let fileName = isImage? file.name : file;
    let index = $scope.file.map(x => x.replace(/^.*[\\\/]/, '')).indexOf(fileName);
    $scope.file.splice(index, 1);
    index = vm.fileNames.indexOf(fileName);
    vm.fileNames.splice(index, 1);
    vm.inputLabel = vm.fileNames.join(', ');

    if(isImage) {
      index = vm.files.indexOf(file);
      vm.files.splice(index, 1);
    }

    if($scope.file.length < $scope.maxFiles) {
      vm.uploadedFile = false;
    }
  }
}


angular.module("firmaren")
  .directive("fileUpload", FileUpload);
}());
