;(function() {
/* @ngInject */
function ZalozenieSroKrok3Controller(ZalozenieSroKrok3Service, BusinessSubjects, $scope, $window, ModalFinishLater, $filter) {
  let vm = this;
  let server = ZalozenieSroKrok3Service;

  vm.subjects = BusinessSubjects;
  vm.nextStep = 4;
  vm.valid = false;

  vm.onSaveAndContinueClick = onSaveAndContinueClick;
  vm.onBackClick = onBackClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;

  window.showSpinner(4);
  server.getMaxStep().then( (ms) => {vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
  server.getSelectedSubjects().then(onSelectedSubjectsLoad);
  server.getSubjects().then( (subjects) => { BusinessSubjects.init(subjects); window.hideSpinnerWhenAllResolved(); });
  server.getFoundersManagers().then(onFoundersManagersLoad);

  BusinessSubjects.onChange($scope, ($event, subjects) => onSelectionChange(subjects));

  function onFoundersManagersLoad(order) {
    if(order) {
      //console.log(order);
      vm.founders = [];
      order.founders.forEach(addToFounder);
      order.executiveManagers.forEach(addToFounder);
    }
    //console.log(vm.founders);

      window.hideSpinnerWhenAllResolved();
  }

  function addToFounder(f) {
    let e = {};
    e.address = f.address;
    e.dateOfBirth = f.dateOfBirth;
    e.idNumber = f.idNumber;
    e.name = f.name;
    e.surname = f.surname;
    e.otherInfo = f.otherInfo;
    e.titleBefore = f.titleBefore;
    e.titleAfter = f.titleAfter;
    vm.founders.push(e);
  }

  function onSelectedSubjectsLoad(ss) {
    if (angular.isDefined(ss)) {
      //console.log(ss)
      BusinessSubjects.select(ss.subjects);
    }

      window.hideSpinnerWhenAllResolved();
  }

  function onSelectionChange(subjects) {
    server.setSelectedSubjects({ subjects })
      .then(tp => vm.totalPrice = tp);
  }

  function onSaveAndContinueClick(steps) {
    onSelectionChange(vm.subjects.selected);
    server.saveAndContinue().then(({ success, data }) => {
      if (success) {
        if(steps){
          vm.valid = true;
        } else {
          $window.location = "krok4";
        }
      }
    });
  }

  function onBackClick() {
    $window.location = "krok2";
  }

  function onSaveForLaterClick() {
    ModalFinishLater(server)
      .add(server)
      .result
      .then();
  }

}

angular.module("firmaren")
  .controller("ZalozenieSroKrok3Controller", ZalozenieSroKrok3Controller);
}());
