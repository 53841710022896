;(function() {
/* @ngInject */
function VirtualneSidloKrok3Controller(VirtualneSidloKrok3Service, $window, ModalFinishLater) {
    let vm = this;
    let server = VirtualneSidloKrok3Service;

    vm.maxStep = 3;
    vm.onFinishClick = onFinishClick;
    vm.onSaveForLaterClick = onSaveForLaterClick;
    vm.onBackClick = onBackClick;
    vm.scrollTo = scrollTo;

    vm.totalPrice = 0;
    vm.requiredCheckbox = false;

    vm.order = {};
    vm.cart = {
        text: "/rok"
    };

    vm.agreements = {
        agrees: false,
        representation: true
    };

    vm.inProgress = false;
    vm.orderId = 0;

    window.showSpinner(4);
    server.getMaxStep().then( (ms) => { vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
    server.getPrice().then( (tp) => { vm.cart.totalPrice = tp; window.hideSpinnerWhenAllResolved(); });
    server.getOrder().then(onOrderLoad);
    server.getOrderId().then((id) => { vm.orderId = id; window.hideSpinnerWhenAllResolved(); });

    function onFinishClick() {
        if(!vm.agreements.agrees) {
            vm.requiredCheckbox = true;
            scrollTo("scroll_checkbox");
        } else {
            vm.inProgress = true;
            server.finish({
                agrees: vm.agreements.agrees,
                requestsRepresentation: vm.agreements.representation
            }).then(function (_ref) {
                var success = _ref.success;
                var data = _ref.data;
                var error = _ref.error;

                if (success) {
                    $window.location = "objednane";
                }
                vm.inProgress = false;
            }).catch(function (error) {
                console.log(error);
                vm.inProgress = false;
            });
        }
    }

    function scrollTo(scrollLocation) {
        $('html, body').animate({
            scrollTop: $("#" + scrollLocation).offset().top - 170
        }, 500);
    }

    function onSaveForLaterClick() {
        ModalFinishLater(server)
            .add(server)
            .result
            .then();
    }

    function onBackClick() {
        $window.location = "krok2";
    }

    function onOrderLoad(order) {
        if(order) {
            vm.order = order;
        }
        console.log(vm.order);
        window.hideSpinnerWhenAllResolved();
    }
}

angular.module("firmaren")
    .controller("VirtualneSidloKrok3Controller", VirtualneSidloKrok3Controller);
}());
