;(function() {
/* @ngInject */
function PaginationController($rootScope) {
    let vm = this;

    vm.paginationFrom = paginationFrom;
    vm.paginationTo = paginationTo;
    vm.setPage = setPage;
    vm.paginationItems = paginationItems;
    vm.showMore = showMore;
    vm.showMoreCount = showMoreCount;

    vm.defaultOffset = 20;

    function paginationFrom() {
        return (vm.numberOfPage * vm.page) + 1;
    }

    function paginationTo() {
        const to = vm.numberOfPage * (vm.page + 1);
        return to > vm.totalElements ? vm.totalElements : to;
    }

    function setPage(index) {
        vm.page = index;
        vm.server.setPage(index.toString());
        $rootScope.$broadcast('pagination-change-page-' + vm.name);
    }

    function paginationItems() {
        return vm.totalElements % vm.numberOfPage !== 0 ? Math.trunc(vm.totalElements / vm.numberOfPage) + 1 : Math.trunc(vm.totalElements / vm.numberOfPage);
    }

    function showMore() {
        vm.numberOfPage = vm.numberOfPage + vm.defaultOffset;
        vm.server.setOffset(vm.numberOfPage.toString());
        $rootScope.$broadcast('pagination-change-offset-' + vm.name);
    }

    function showMoreCount() {
        return vm.totalElements - paginationTo() < 20 ? vm.totalElements - paginationTo() : vm.defaultOffset;
    }
}

angular.module("firmaren")
    .component("pagination", {
        templateUrl: "/app/shared/pagination/pagination.html",
        controller: PaginationController,
        controllerAs: "vm",
        bindings: {
            numberOfPage: "=",
            page: "=",
            totalElements: "=",
            server: "=",
            name: "="
        }
    });
}());
