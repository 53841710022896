;(function() {
/* @ngInject */
function LocationMultiselectController($rootScope) {
    let vm = this;

    vm.onRegionChange = onRegionChange;
    vm.onDistrictChange = onDistrictChange;
    vm.updateSelected = updateSelected;
    vm.onClick = onClick;

    function updateSelected() {
        $rootScope.$broadcast('virtualne-sidlo-update-selected');
        $rootScope.$broadcast('reset-selected-city');
    }

    function onClick(e) {
        e.preventDefault();
    }

    function onRegionChange(regionIndex) {
        if (vm.location[regionIndex].children.length) {
            vm.location[regionIndex].children.forEach((district) => {
                district.selected = vm.location[regionIndex].selected;
                if (district.children.length) {
                    district.children.forEach((urbanDistrict) =>
                        urbanDistrict.selected = district.selected);
                }
            });
        }

        updateSelected();
    }

    function onDistrictChange(regionIndex, districtIndex) {
        let value = vm.location[regionIndex].children[districtIndex].selected;

        if (vm.location[regionIndex].children.length && vm.location[regionIndex].children[districtIndex].children.length) {
            vm.location[regionIndex].children[districtIndex].children.forEach((child) => {
                child.selected = value;
            });
        }

        updateSelected();
    }
}

angular.module("firmaren")
    .component("locationMultiselect", {
        templateUrl: "/app/shared/location-multiselect/location-multiselect.html",
        controller: LocationMultiselectController,
        controllerAs: "vm",
        bindings: {
            filter: "=",
            location: "=",
            showDropdown: "=",
            id: "="
        }
    });
}());
