;(function() {
angular.module("firmaren", [
  "firmaren.server",
  "ng",
  "ngLocale",
  "ngMessages",
  "ngResource",
  "ngAnimate",
  "pascalprecht.translate",
  "ngStorage",
  "ui.bootstrap",
  "ngMask",
  "ngMap",
  "ngFileUpload",
  "ngGPlaces",
  "ngMaterial",
  "ngIdle",
  "ng.deviceDetector"
]);
}());
