;(function() {
/* @ngInject */
function removeEmojiFilter() {
  return function(input, optional1, optional2) {

    var res;

    if(input)
      res = input.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g, '');
    else res = input;
    return res;

  }
}
angular.module("firmaren")
  .filter("removeEmojiFilter", removeEmojiFilter);
}());
