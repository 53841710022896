;(function() {
/* @ngInject */
function ContactMeController() {
    let vm = this;
}

angular.module("firmaren")
    .component("contactMe", {
        templateUrl: "/app/shared/contact-me/contact-me.html",
        controller: ContactMeController,
        controllerAs: "vm",
        bindings: {
            model: "="
        }
    });
}());
