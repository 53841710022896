;(function() {
/* @ngInject */
function AgreementCheckboxController() {
    let vm = this;
}

angular.module("firmaren")
    .component("agreementCheckbox", {
        templateUrl: "/app/shared/agreement-checkbox/agreement-checkbox.html",
        controller: AgreementCheckboxController,
        controllerAs: "vm",
        bindings: {
            model: "="
        }
    });
}());
