;(function() {
/* @ngInject */
function VideoNavodyController(VideoService, $filter, Helpers, $location, ModalVideo, removeDiacritics) {
    let vm = this;
    let service = VideoService;

    vm.tags = [];
    vm.allCategoryVideos = [];
    vm.categoryVideos = [];
    vm.tagVideos = [];
    vm.filterDatas = [];
    vm.isActiveTag = false;
    vm.filter = "";
    vm.activeTagsText = "";
    vm.numberOfCategories = 10;

    vm.getCategoryName = getCategoryName;
    vm.setTag = setTag;
    vm.activeTags = activeTags;
    vm.showAllByTags = showAllByTags;
    vm.onFilterChange = onFilterChange;
    vm.clearFilter = clearFilter;
    vm.search = search;
    vm.scrollTo = Helpers.scrollTo;

    window.showSpinner(2);
    service.getVideosTags().then(onVideosTagsLoad);
    service.getVideosByCategory().then(onVideosByCategoryLoad);
    service.getDataToFilter().then(onDataToFilterLoad);

    function onVideosTagsLoad(items) {
        let forAllTag = items.filter((e) => e.id === 5);
        let otherTags = items.filter((e) => e.id !== 5);
        vm.tags.push(...forAllTag, ...otherTags);
        // vm.tags = items;
        initFilter();
        window.hideSpinnerWhenAllResolved();
    }

    function onVideosByCategoryLoad(items) {
        vm.allCategoryVideos = $filter("orderBy")(items, "primaryTag");
        let forAllTag = vm.allCategoryVideos.filter((e) => e.primaryTag === 5);
        let otherTags = vm.allCategoryVideos.filter((e) => e.primaryTag !== 5);
        vm.categoryVideos.push(...forAllTag, ...otherTags);
        // vm.categoryVideos = vm.allCategoryVideos.slice(0, 3);
        window.hideSpinnerWhenAllResolved();
    }

    function getCategoryName(tag) {
        return vm.tags.find(x => x.id === tag).name;
    }

    function initFilter(){
        let res = $location.search();
        if(res.tags) {
            let tagNames = res.tags.split(",");
            vm.tags.forEach(t => {
                if(tagNames.indexOf(removeDiacritics(t.tag).replace(" ", "-")) !== -1) {
                    t.active = true;
                }
            });

            setTag();
        }

        if(res.video) {
            ModalVideo("https://www.youtube.com/embed/" + res.video + "?autoplay=1&rel=0&vq=hd720p60")
            .add()
            .result
            .then(function(){}, function(){
                $location.search({tags: res.tags});
            });
        }
    }

    function setTag(tag) {
        if (tag) {
            tag.active = !tag.active;
        }
        let activeTags = [];
        vm.tags.forEach(t => {
            if(t.active)
                activeTags.push(t.id);
        });
        service.getVideosByTags({tags: activeTags}).then(items => {
            vm.tagVideos = items;
        });
        if(vm.tags.filter(t => t.active).length > 0) {
            vm.isActiveTag = true;
        } else vm.isActiveTag = false;

        vm.activeTagsText = vm.activeTags(", ");
        let originQueryParams = $location.search();

        if (!vm.activeTagsText) {
            $location.search({});
        }
        else {
            let tags = removeDiacritics(vm.activeTags(",")).replace(" ", "-");
            $location.search(Object.assign({}, originQueryParams, {tags: tags}));
        } 
    }

    function activeTags(delimiter) {
        let tags = "";
        vm.tags.forEach(tag => {
            if(tag.active)
                tags = tags + tag.tag + delimiter;
        });
        if(tags.endsWith(delimiter))
            tags = tags.slice(0, -delimiter.length);
        return tags;
    }

    function showAllByTags(primary) {
        let tag = vm.tags.find(x => x.id === primary);
        setTag(tag);
    }

    function onDataToFilterLoad(items) {
        vm.filterDatas = items;
    }

    function onFilterChange(item) {
        if(item._1 === "tag") {
            let tag = vm.tags.find(x => x.tag === item._2);
            setTag(tag);
        } else if(item._1 === "category") {
            let tag = vm.tags.find(x => x.name === item._2);
            setTag(tag);
        } else {
            service.getVideoByName(item._2).then(video => {
                vm.tags.forEach(v => {
                    v.active = false;
                });
                vm.tagVideos = [];
                vm.tagVideos.push(video);
                vm.activeTagsText = video.title;
                vm.isActiveTag = true;
            });
        }
    }

    function clearFilter() {
        vm.filter = "";
        vm.tags.forEach(v => {
            v.active = false;
        });
        vm.tagVideos = [];
        vm.isActiveTag = false;
    }

    function search(item) {
        vm.tags.forEach(v => {
            v.active = false;
        });
        service.search(item).then(videos => {
            vm.tagVideos = [];
            vm.tagVideos = videos;
            vm.activeTagsText = item;
            vm.isActiveTag = true;
        })
    }
}

angular.module("firmaren")
    .controller("VideoNavodyController", VideoNavodyController);
}());
