;(function() {
/* @ngInject */
function ModalInfo($uibModal, $rootScope) {
    let title, text, button;

    return function init(title_, text_, button_) {
        title = title_;
        text = text_;
        button = button_;

        return { add };
    };

    function open(options = {}) {
        return $uibModal.open(angular.extend({
            templateUrl: "/app/shared/modal-info/modal-info.html",
            controller: "ModalInfoController",
            controllerAs: "vm",
            bindToController: true,
            backdrop: "static",
            size: "md",
            resolve: {
                title: () => title,
                text: () => text,
                button: () => button
            }
        }, options));
    }

    function add(scope = {}) {
        return open({ scope: angular.extend($rootScope.$new(true), scope) })
    }
}

/* @ngInject */
function ModalInfoController($uibModalInstance, title, text, button) {
    let vm = this;

    vm.title = title;
    vm.text = text;
    vm.button = button;

    vm.close = () => $uibModalInstance.dismiss("cancel");

}

angular.module("firmaren")
    .factory("ModalInfo", ModalInfo)
    .controller("ModalInfoController", ModalInfoController);
}());
