;(function() {
/* @ngInject */
function ModalLanguage($uibModal, $rootScope) {

    return function init() {
        return { add };
    };

    function open(options = {}) {
        return $uibModal.open(angular.extend({
            templateUrl: "/app/shared/modal-language/modal-language.html",
            controller: "ModalLanguageController",
            controllerAs: "vm",
            backdrop: 'static',
            bindToController: true,
            size: "md"
        }, options));
    }

    function add(scope = {}) {
        return open({ scope: angular.extend($rootScope.$new(true), scope) })
    }
}

/* @ngInject */
function ModalLanguageController($uibModalInstance) {
    let vm = this;

    vm.language = "en";

    vm.close = () => $uibModalInstance.dismiss("cancel");

    vm.submit = () => {
        $uibModalInstance.close(vm.language);
    }
}

angular.module("firmaren")
    .factory("ModalLanguage", ModalLanguage)
    .controller("ModalLanguageController", ModalLanguageController);
}());
