;(function() {
/* @ngInject */
function PhoneNumberController($rootScope) {
    let vm = this;
    vm.onChange = onChange;
    vm.onFocus = onFocus;
    vm.selectMatch = selectMatch;
    vm.position = position;
    vm.pattern = $rootScope.patterns.phoneNumber;
    vm.open = false;

    function onChange(){
        $rootScope.$broadcast(vm.change);
    }

    function onFocus(){
        vm.open = true;
    }

    window.addEventListener('click', (e) => {   
        const countriesEl = document.getElementById('countries');
        const prefixEl = document.getElementById('prefix');
        if (countriesEl && !countriesEl.contains(e.target)
            && prefixEl && !prefixEl.contains(e.target)){
                countriesEl.style.display ='none';
        } else {
            countriesEl.style.display ='block';
        }
    });

    function selectMatch(country){
        vm.model.prefix = country._3;
        vm.open = false;
    }

    function position() {
        const element = document.getElementById('prefix');
        if (element) {
            return {top: element.offsetTop + element.offsetHeight, left: element.offsetLeft};
        }
        return {top: 0, left: 0};
    }

}

angular.module("firmaren")
    .component("phoneNumber", {
        templateUrl: "/app/shared/phone-number/phone-number.html",
        controller: PhoneNumberController,
        controllerAs: "vm",
        bindings: {
            model: "=",
            form: "=",
            submitted: "=",
            change: "=",
            label: "=",
            tooltip: "=",
            countries: "=",
            size: "="
        }
    });
}());
