;(function() {
/* @ngInject */
function trustFactory($sce) {
  return function(value, type) {
    return $sce.trustAs(type || "html", value);
  }
}

angular.module("firmaren")
  .filter("trust", trustFactory);
}());
