;(function() {
/* @ngInject */
function VirtualneSidloFilterController($rootScope) {
    let vm = this;
    
    vm.showDropdown = false;

    vm.resetFilter = resetFilter;
    vm.showOnMap = showOnMap;

    function resetFilter() {
        vm.showDropdown = false;
        $rootScope.$broadcast('virtualne-sidlo-filter-reset');
    }

    function showOnMap() {
        console.log("TODO: zobrazit vysledky na mape");
    }
}

angular.module("firmaren")
    .component("virtualneSidloFilter", {
        templateUrl: "/app/shared/virtualne-sidlo-filter/virtualne-sidlo-filter.html",
        controller: VirtualneSidloFilterController,
        controllerAs: "vm",
        bindings: {
            filter: "=",
            location: "="
        }
    });
}());
