;(function() {
/* @ngInject */
function PaymentInfoController($interval, $window) {
    let vm = this;

    vm.onResetCountry = onResetCountry;

    function onResetCountry() {
        vm.model.address.country = "";
        $interval(function() {
          let element = $window.document.getElementById('address.countryInvoice');
          if(element)
            element.focus();
        }, 100, 1);
    }
}

angular.module("firmaren")
    .component("paymentInfo", {
        templateUrl: "/app/shared/payment-info/payment-info.html",
        controller: PaymentInfoController,
        controllerAs: "vm",
        bindings: {
            submitted: "=",
            form: "=",
            model: "=",
            companyName: "=",
            options:"=",
            onIcoChange: "=",
            cities: "=",
            countries: "=",
            select: "=",
            sendInvoice: "=",
            icoRequired: "=",
            noDefaultValue: "="
        }
    });
}());
