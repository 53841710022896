;(function() {
/* @ngInject */
function UpsellBoxController($rootScope, Helpers, $scope, $timeout) {
    let vm = this;

    $timeout(function() {
        vm.setUpsell = setUpsell;
        vm.updateSelectedServices = updateSelectedServices;

        vm.showMoreInfo = false;
        vm.selectedServices = {};

        $scope.$watch('vm.upsell', function () {
            vm.showMoreInfo = vm.upsell ? vm.upsell : vm.showMoreInfo;
            if(vm.servicesModel) {
                vm.servicesModel.forEach(str => {
                    vm.selectedServices[str] = true;
                });
            }
        });

        function setUpsell() {
            vm.upsell = !vm.upsell;
            $rootScope.$broadcast('change-sticky-container');
        }

        function updateSelectedServices() {
            vm.servicesModel = Object.keys(vm.selectedServices).filter(function(service) {
                return vm.selectedServices[service];
            });
        };
    });
}

angular.module("firmaren")
    .component("upsellBox", {
        templateUrl: "/app/shared/upsell-box/upsell-box.html",
        controller: UpsellBoxController,
        controllerAs: "vm",
        bindings: {
            upsell: "=",
            name: "=",
            img: "=",
            header: "=",
            description: "=",
            moreInfo: "=",
            checkboxModel: "=",
            checkboxRequired: "=",
            checkboxName: "=",
            checkboxAgree: "=",
            checkboxAgreeMemorandum: "=",
            price: "=",
            priceName: "=",
            withoutTax: "=",
            backgroundColor: "=",
            checkboxFirmarenModel: "=",
            checkboxFirmaren: "=",
            checkboxFirmarenRequired: "=",
            services: "=",
            servicesModel: "=",
            servicesError: "=",
        }
    });
}());
