;(function() {
/* @ngInject */
function EmailController($rootScope, $window) {
    let vm = this;

    vm.domains = ["gmail.com", "google.com", "yahoo.com", "hotmail.com", "outlook.com", "protonmail.com", "zoznam.sk",
        "centrum.sk", "gmx.com", "me.com", "aol.com", "gmz.com", "mail.com"];

    vm.keyUp = keyUp;
    vm.setEmail = setEmail;

    function validateEmail() {
        let element = $window.document.getElementById("email." + vm.ref);
        return element.checkValidity();
    }

    function keyUp() {
        if(validateEmail() && vm.change) {
            setTimeout(() => {
                $rootScope.$broadcast(vm.change);
            });
        }
        vm.actualDomains = [];
        if (vm.model && vm.model.indexOf("@") != -1) {
            let dom = vm.model.split("@")[1];
            if(dom.length > 0) {
                vm.domains.forEach(d => {
                    if(d.startsWith(dom)) {
                        vm.actualDomains.push(d);
                    }
                });
            } else vm.actualDomains = vm.domains;
        }
    }

    function setEmail(domain) {
        vm.actualDomains = [];
        vm.mouseover = false;
        vm.model = vm.model.split('@')[0] + "@" + domain;
        if(vm.change)
            setTimeout(() => {
                $rootScope.$broadcast(vm.change);
            });
    }
}

angular.module("firmaren")
    .component("email", {
        templateUrl: "/app/shared/email/email.html",
        controller: EmailController,
        controllerAs: "vm",
        bindings: {
            label: "=",
            model: "=",
            submitted: "=",
            form: "=",
            ref: "=",
            size: "=",
            change: "=",
            disabled: "=",
            freeValid: "=",
            placeholder: "=",
            tooltip: "="
        }
    });
}());
