;(function() {
/* @ngInject */
function ModalOrder($uibModal, $rootScope) {
  let item;
  let formName;
  let serverCall;
  let trackForm;

  return function init(serverCall_, item_, formName_, trackForm_) {
    item = item_;
    formName = formName_;
    serverCall = serverCall_;
    trackForm = trackForm_;

    return { add, edit };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-order/modal-order.html",
      controller: "ModalOrderController",
      controllerAs: "vm",
      bindToController: true,
      size: "lg",
      backdrop: "static",
      resolve: {
        item: () => item,
        formName: () => formName,
        serverCall: () => serverCall,
        trackForm: () => trackForm
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) }, serverCall, item, formName, trackForm)
  }

  function edit(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) }, serverCall, item, formName, trackForm);
  }
}

/* @ngInject */
function ModalOrderController($uibModalInstance, item, formName, serverCall,  $timeout, $interval, $scope, trackForm, $window, Helpers) {
  let vm = this;
  vm.item = item;
  vm.trackForm = trackForm;
  vm.success = false;

  vm.contact = {
    phoneNumber: {
      prefix: "+421"
    }
  };

  $timeout(function () {
    let element = $window.document.getElementById("order-name");
    if(element)
      element.focus();
  });

  vm.close = () => $uibModalInstance.dismiss("cancel");
  vm.submit = () => {
    if($scope.order.$invalid) {
      vm.requiredMessage = true;
      vm.submitted=true;
    } else {
      var data = Helpers.getDataLayer(-1, vm.trackForm,
          vm.trackForm.indexOf("ready_made_oz") !== -1 ? 'oz' : 'ine', '');

      var dataLayer = $window.dataLayer = $window.dataLayer || [];
      if (data)
        dataLayer.push(data);

      if (item && vm.contact) {
        vm.contact.idOrder = item.id;
      }

      if(formName) {
        vm.contact.formType = formName;
      }

      serverCall(vm.contact).then((data) => {
        vm.success = true;
        $interval(function () {
          $uibModalInstance.close();
        }, 2000, 1);
      });
    }
  }
}

angular.module("firmaren")
  .factory("ModalOrder", ModalOrder)
  .controller("ModalOrderController", ModalOrderController);
}());
