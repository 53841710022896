;(function() {
/* @ngInject */
function ReferencieController(IndexService, Helpers, ModalVideo) {

  let vm = this;
  let server = IndexService;

  vm.showButtonSro = true;
  vm.showButtonZivnost = true;
  vm.showButtonZmena = true;
  vm.showButtonOz = true;
  vm.googleReviews = [];

  vm.scrollTo = Helpers.scrollTo;
  vm.showAllCompanies = showAllCompanies;
  vm.playVideo = playVideo;

  server.getOurCompanies().then(onOurCompaniesLoad);
  server.getGoogleReviews().then(data => vm.googleReviews = data.slice(0, 2));

  function playVideo(url) {
    ModalVideo(url)
      .add()
      .result
      .then();
  }

  function onOurCompaniesLoad(items) {
    vm.allSro = items[0];
    vm.sro = items[0].slice(0, 4);

    vm.allZivnost = items[1];
    vm.zivnost = items[1].slice(0, 4);

    vm.allZmena = items[2];
    vm.zmena = items[2].slice(0, 4);

    vm.allOz = items[3];
    vm.oz = items[3].slice(0, 4);
  }

  function showAllCompanies(key) {
    if(key === 'sro') {
      vm.sro = vm.allSro;
      vm.showButtonSro = false;
    }
    if(key === 'zivnost') {
      vm.zivnost = vm.allZivnost;
      vm.showButtonZivnost = false;
    }
    if(key === 'zmena') {
      vm.zmena = vm.allZmena;
      vm.showButtonZmena = false;
    }
    if(key === 'oz') {
      vm.oz = vm.allOz;
      vm.showButtonOz = false;
    }
  }
}

angular.module("firmaren")
  .controller("ReferencieController", ReferencieController);
}());
