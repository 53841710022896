;(function() {
/* @ngInject */
function EmailValidator(UserService, $q) {

  return {
    require: "ngModel",
    link: function(scope, element, attributes, ngModel) {

      if(attributes.allowdirective) {
        ngModel.$asyncValidators.emailUnique = function(modelValue) {
          var deferred = $q.defer();
          UserService.isFree({email: modelValue}).then(({ success, data }) => {
            if(success){
              deferred.resolve();
            } else {
              deferred.reject();
            }
          });

          return deferred.promise;
        };
      }

    }
  };
};

angular.module("firmaren")
  .directive("emailValidator", EmailValidator);
}());
