;(function() {
/* @ngInject */
function VideoBoxController(ModalVideo, $window, $location, ModalPaidVideo) {
    let vm = this;

    vm.playVideo = playVideo;
    vm.isShown = isShown;

    function playVideo(id, embedId) {
        setShow(id);
        
        let originQueryParams = $location.search()
        $location.search(Object.assign({}, originQueryParams, { video: embedId}));

        if(!vm.video.paid) {
            ModalVideo("https://www.youtube.com/embed/" + embedId + "?autoplay=1&rel=0&vq=hd720p60")
                .add()
                .result
                .then(function(){}, function(){
                    $location.search(originQueryParams);
                });
        } else {
            ModalPaidVideo(vm.video)
                .add()
                .result
                .then();
        }
    }

    function setShow(id) {
        let ids = $window.localStorage.getItem('showingIds') ?
            JSON.parse($window.localStorage.getItem('showingIds')) : [];
        if(ids.indexOf(id) < 0) {
            ids.push(id);
        }
        $window.localStorage.setItem('showingIds', JSON.stringify(ids));
    }

    function isShown(id) {
        let ids = $window.localStorage.getItem('showingIds') ?
            JSON.parse($window.localStorage.getItem('showingIds')) : [];
        return ids.indexOf(id) > -1;
    }
}

angular.module("firmaren")
    .component("videoBox", {
        templateUrl: "/app/shared/video-box/video-box.html",
        controller: VideoBoxController,
        controllerAs: "vm",
        bindings: {
            video: "="
        }
    });
}());
