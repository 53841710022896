;(function() {
/* @ngInject */
function ScrollToInvalid($document, $interval) {
  return {
    restrict: "A",
    link: function (scope, elem) {

      // set up event handler on the form element
      elem.on('submit', function () {
        // find the first invalid element
        var firstInvalid = elem[0].querySelector('.ng-invalid');
        // if we find one, set focus
        if (firstInvalid) {
          $('html, body').animate({
            scrollTop: $("#" + firstInvalid.id.replace(/\./g, "\\\.")).offset().top - 200
          }, 500);
        }
      });
    }
  };
}

angular.module("firmaren")
  .directive("firmarenScrollToInvalid", ScrollToInvalid);
}());
