;(function() {
/* @ngInject */
function EmailValidator() {

  return {
    require: "ngModel",
    scope: {
      otherModelValue: "=emailValidator"
    },
    link: function(scope, element, attributes, ngModel) {

      ngModel.$validators.emailInvalid = function(modelValue) {
        
        return scope.otherModelValue !== "bad";
      };

      scope.$watch("otherModelValue", function() {
        ngModel.$validate();
      });
    }
  };
};

angular.module("firmaren")
  .directive("emailValidator", EmailValidator);
}());
