;(function() {
/* @ngInject */
function ReferencesController() {
}

angular.module("firmaren")
  .component("references", {
    templateUrl: "/app/shared/references/references-view.html",
    controller: ReferencesController,
    controllerAs: "vm"
  });
}());
