;(function() {
/* @ngInject */
function ModalGoals($uibModal, $rootScope) {
  return { open };

  function open(scope) {
    return $uibModal.open({
      size: "lg",
      templateUrl: "/app/shared/modal-goals/modal-goals.html",
      controller: "ModalGoalsController",
      controllerAs: "vm",
      bindToController: true,
      windowTopClass: "modal-business-subjects-root",
      scope: angular.extend($rootScope.$new(), scope)
    });
  }
}

/* @ngInject */
function ModalGoalsController($uibModalInstance, _, $filter) {
  let vm = this;
  
  let uniqueCat = $filter('unique')(vm.goals,'category');
  vm.goalGroups = {};
  
  angular.forEach(uniqueCat, function (cat) {
    vm.goalGroups[cat.category] = _(vm.goals).filter(g => g.category === cat.category).groupBy("subcategory").value()
  });
  
  vm.close = () => $uibModalInstance.dismiss("cancel");
}

angular.module("firmaren")
  .factory("ModalGoals", ModalGoals)
  .controller("ModalGoalsController", ModalGoalsController);
}());
