;(function() {
/* @ngInject */
function PaymentInfoSummaryController(Helpers) {
    let vm = this;

    vm.mergeStreetNo = Helpers.mergeStreetNo;

}

angular.module("firmaren")
    .component("paymentInfoSummary", {
        templateUrl: "/app/shared/payment-info-summary/payment-info-summary.html",
        controller: PaymentInfoSummaryController,
        controllerAs: "vm",
        bindings: {
            model: "=",
            mergeStreetAndNumber: "=",
            emptyLine: "=",
            nameLabel: "="
        }
    });
}());
