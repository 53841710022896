;(function() {
/* @ngInject */
function Steps($window, $scope) {
  let vm = this;
  vm.url = "";

  vm.getClass = getClass;
  vm.saveContent = saveContent;

  function saveContent(url) {
    vm.url = url;
    if (vm.call){
      vm.call(true);
    } else {
      $window.location = vm.url;
    }
  }

  $scope.$watch('vm.valid', function() {
    if (vm.valid) {
      if (vm.form) {
        vm.form.$dirty = false;
        vm.form.$submitted = true;
      }

      $window.location = vm.url;
    }
  });

  function getClass($index) {
    let classes = {
      visited_path: $index <= vm.max - 1 && $index !== vm.current - 1,
      active_path: $index === vm.current - 1,
      non_active_path: $index > vm.max - 1 && $index !== vm.current - 1
    };

    classes["path_priority" + (vm.steps.length - $index)] = true;

    return classes;
  }
}

angular.module("firmaren")
  .component("firmarenSteps", {
    templateUrl: "/app/shared/menu-steps/menu-steps.html",
    controller: Steps,
    controllerAs: "vm",
    bindings: {
      current: "=",
      max: "=",
      form: "=",
      valid: "=",
      steps: "=",
      call: "=",
      param: "="
    }
  });
}());
