;(function() {
/* @ngInject */
function ZalozenieZivnostiKrok1Controller(ZalozenieZivnostiKrok1Service, $timeout, BusinessSubjects, routes, $rootScope,
                                          $window, $scope, ModalFinishLater, $location, $filter, normalize, $translate,
                                          ModalNameDay, ModalTatraBanka) {
  let vm = this;
  let server = ZalozenieZivnostiKrok1Service;

  vm.maxStep = 1;
  vm.valid = false;
  vm.subjects = BusinessSubjects;
  vm.onSaveAndContinueClick = onSaveAndContinueClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.onTagClick = onTagClick;
  vm.totalPrice = 0;
  vm.onLeftClick = onLeftClick;
  vm.onRightClick = onRightClick;
  vm.showNameDayModal = showNameDayModal;
  vm.showTatraBankaModal = showTatraBankaModal;
  vm.nextStep = 2;
  vm.lang = $translate.use();

  window.showSpinner(4);
  server.getSubjects().then( (subjects) =>  { BusinessSubjects.init(subjects); updateSubjectsFromUrl(); window.hideSpinnerWhenAllResolved(); } );
  server.getSelectedSubjects().then(onSelectedSubjectsLoad);
  server.getTotalPrice().then( (tp) =>{ vm.totalPrice = tp; window.hideSpinnerWhenAllResolved(); });
  server.getPrice(vm.lang).then((tp) => vm.totalPrice = tp);
  server.getMaxStep().then(onMaxStepLoad);

  vm.categories = [
    {name: "graphic", image: "/assets/images/graphic.png"},
    //{name: "blog", image: "/assets/images/designer.png"},
    {name: "handmade", image: "/assets/images/handmade.png"},
    //{name: "bar", image: "/assets/images/pilic.png"},
    //{name: "restaurant", image: "/assets/images/eshop.png"},
    //{name: "bakery", image: "/assets/images/bakery.png"},//
    {name: "traffic", image: "/assets/images/traffic.png"},
    {name: "administration", image: "/assets/images/administration.png"},
    {name: "architecture", image: "/assets/images/architecture.png"},
    {name: "science", image: "/assets/images/science.png"},
    {name: "agriculture", image: "/assets/images/agriculture.png"},
    //{name: "clothing", image: "/assets/images/clothing.png"},//
    {name: "accounting", image: "/assets/images/accounting.png"},
    {name: "health", image: "/assets/images/health.png"},
    //{name: "language", image: "/assets/images/pilic.png"},
    //{name: "finance", image: "/assets/images/eshop.png"},
    {name: "advertisement", image: "/assets/images/marketing.png"},
    //{name: "software", image: "/assets/images/murar.png"},
    {name: "course", image: "/assets/images/course.png"},
    //{name: "courier", image: "/assets/images/designer.png"},
    //{name: "eshop", image: "/assets/images/murar.png"},
    {name: "business", image: "/assets/images/business.png"}
  ];

  BusinessSubjects.onChange($scope, ($event, subjects) => onSelectionChange(subjects));

  let res = $location.path().split("/");

  function updateSubjectsFromUrl() {
    if(res.length > 1) {
      angular.forEach(vm.categories, function (value) {
        if(res[1] === normalizeParam($filter("translate")('businessSubjects.categories.' + value.name))) {
          $rootScope.$broadcast('update-subjects', value.name);
        }
      })
    }
  }

  function showNameDayModal() {
    if(vm.lang != "en") {
      server.getNameDay("trade")
          .then((name) => {
            if(name && name.length > 0)
              ModalNameDay($filter("translate")('zalozenieZivnosti.nameDay.title', {name: name}), 'zalozenieZivnosti.nameDay.subtitle','zalozenieZivnosti.nameDay.descStep1')
                  .add()
                  .result
                  .then();
          });
    }
  }

  function showTatraBankaModal() {
    if(vm.lang != "en") {
      ModalTatraBanka('modalTatraBanka.zivnost.title', 'modalTatraBanka.zivnost.text', 18)
      .add()
      .result
      .then(res => {
        server.setTBDiscount((res && res === "confirm").toString()).then(server.getPrice(vm.lang).then((tp) => vm.totalPrice = tp));
      });
    }
  }

  function normalizeParam(tag) {
    return normalize(tag.toLowerCase().replace(new RegExp(" ", 'g'), "_").replace(/\./g,''));
  }

  function onMaxStepLoad(ms) {
    vm.maxStep = ms;
    window.hideSpinnerWhenAllResolved();
  }

  function onSelectedSubjectsLoad(ss) {
    if (angular.isDefined(ss)) {
      BusinessSubjects.select(ss.subjects);
    }
      window.hideSpinnerWhenAllResolved();
  }

  function onSelectionChange(subjects) {
    server.setSelectedSubjects({ subjects })
      .then(tp => vm.totalPrice = tp);
  }

  function onSaveAndContinueClick(steps) {
    onSelectionChange(vm.subjects.selected);

    server.saveAndContinue(true).then(data => {
      if (data.success) {
        if (steps) {
          vm.valid = true;
        } else {
          if (vm.lang === 'en')
            $window.location = routes.en.zivnosti.zalozenie.krok2;
          else $window.location = routes.zivnosti.zalozenie.krok2;
        }
      }
      else {
        vm.input.hasError = true;
        vm.input.message = data;
      }
    });
  }

  function onTagClick(tag) {
    BusinessSubjects.selectByTag(tag);
  }

  function onSaveForLaterClick() {
    ModalFinishLater(server)
      .add(server)
      .result
      .then();
  }

  function onLeftClick() {
  }

  function onRightClick() {

  }
}

angular.module("firmaren")
  .controller("ZalozenieZivnostiKrok1Controller", ZalozenieZivnostiKrok1Controller);
}());
