;(function() {
/* @ngInject */
function CennikSluziebController(CennikService, _) {

  let vm = this;

  vm.findByName = findByName;
  let server = CennikService;

  server.getItems().then(onItemsLoad);
  server.getPlaceOfBusiness().then(onPlaceOfBusinessLoad);

  function onItemsLoad(items) {
    vm.items = items;
    console.log(vm.items);
  }

  function onPlaceOfBusinessLoad(places) {
    vm.places = places;
    console.log(places);
  }

  function findByName(name) {
    if(vm.items)
      return _.find(vm.items, function(obj) { return obj.item === name}).price;
  }
}

angular.module("firmaren")
    .controller("CennikSluziebController", CennikSluziebController);
}());
