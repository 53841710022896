;(function() {
/* @ngInject */
function ZalozenieSroKrok2Controller(ZalozenieSroKrok2Service, Helpers, ModalFounder, ModalExecutiveManager, $window, _, ModalFinishLater, $scope, ModalConfirmDelete, ModalStreetNumber) {
  let vm = this;
  let server = ZalozenieSroKrok2Service;

  vm.maxStep = 2;
  vm.valid = false;
  vm.cart = { };
  vm.order = {};
  vm.onAddFounderClick = onAddFounderClick;
  vm.onEditFounderClick = onEditFounderClick;
  vm.onRemoveFounderClick = onRemoveFounderClick;
  vm.onAddExecutiveManagerClick = onAddExecutiveManagerClick;
  vm.onEditExecutiveManagerClick = onEditExecutiveManagerClick;
  vm.onRemoveExecutiveManagerClick = onRemoveExecutiveManagerClick;
  vm.onSaveAndContinueClick = onSaveAndContinueClick;
  vm.onBackClick = onBackClick;
  //vm.canGoNext = canGoNext;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.scrollTo = Helpers.scrollTo;
  vm.recalculate = recalculate;
  vm.findDepositAdmin = findDepositAdmin;
  vm.getFullName = Helpers.getFullName;

  vm.founders = [];
  vm.executiveManagers = [];
  vm.executiveFounders = [];
  vm.executionTypes = ["onTheirOwn", "together", "other"];
  vm.endUser = ["founders", "other"];
  vm.order = {
    capital: {
      amount: 5000,
      extent: 5000
    },
    executionType: "onTheirOwn",
    endUserType: "founders"
  };
  vm.validate = {
    amount: _.reduce(vm.founders, function(memo,founder){ return memo + founder.capital}, 0 ),
    extent: _.reduce(vm.founders, function(memo,founder){ return memo + founder.paid}, 0 ),
    orderAmount: vm.order.capital.amount,
    founders: vm.founders.length
  };
  vm.errorFounders = {
    executiveManager: false,
    founder: false,
    depositAdmin: false
  };
  vm.requiredMessage = false;
  vm.nextStep = 3;
  vm.showStreetNumberModals = true;
  vm.isSinglePerson = false;

  let cities = [];
  let countries = [];

  vm.names = [];

  window.showSpinner(6);
  server.getMaxStep().then((ms) =>{ vm.maxStep = ms;  window.hideSpinnerWhenAllResolved(); });
  server.getCart().then(onCartLoad);
  server.getCities().then( (cs) => { cities = cs; window.hideSpinnerWhenAllResolved(); });
  server.getCountries().then( (cs) => { countries = cs; window.hideSpinnerWhenAllResolved() });
  server.getFoundersManagers().then(onFoundersManagersLoad);
  server.getLandingSinglePerson().then((isSingle) => { vm.isSinglePerson = isSingle; window.hideSpinnerWhenAllResolved(); });

  function saveSession() {
    server.saveSession().then();
  }

  function findDepositAdmin() {
    for(var i = 0; i < vm.founders.length; i++) {
      if(vm.founders[i].type === "person") {
        if (vm.founders[i].isDepositAdministrator)
          return true;
      } else {
        if (vm.founders[i].representativePerson.isDepositAdministrator)
          return true;
      }
    }
    return false;
  }

  function recalculate() {
    if(vm.isSinglePerson) {
      vm.order.capital.extent = vm.order.capital.amount;
      vm.validate.amount = 0;
      vm.validate.extent = 0;

      if(vm.founders.length){
        vm.founders[0].share = 100;
        vm.founders[0].paid = vm.order.capital.amount;
        vm.founders[0].capital = vm.order.capital.amount;
      }
    }
    else {
      let i = 0;
      for(i = 0; i < vm.founders.length; i++) {
        vm.founders[i].share = (vm.founders[i].capital / vm.order.capital.amount) * 100;

        if(vm.validate.founders === 0) {
          vm.validate.amount = vm.validate.amount + vm.founders[i].capital;
          vm.validate.extent = vm.validate.extent + vm.founders[i].paid;
        }
      }
      if(vm.validate.founders === 0)
        vm.validate.founders = i;

      vm.validate.orderAmount = vm.order.capital.amount;
    }
  }

  function onCartLoad(c) {
    vm.cart.price = c.totalPrice;
    vm.cart.items = c.items;
    vm.cart.placeOfWork = c.placeOfBusinessPrice;
    vm.cart.size = c.subjectCount;

      window.hideSpinnerWhenAllResolved();
  }

  function onAddFounderClick() {

    let isDepositAdmin = false;
    for(var i = 0; i < vm.founders.length; i++) {
      if(vm.founders[i].isDepositAdministrator)
        isDepositAdmin = true;
    }

    ModalFounder(cities, countries, vm.order.capital, vm.validate, isDepositAdmin, vm.isSinglePerson, server)
      .add()
      .result
      .then(f => addingFounder(f), function () {recalculate()});
  }

  function addExecutiveFounder(f) {
    if (f.isExecutiveManager && f.type === "person") {
      let e = {};
      e.address = f.address;
      e.dateOfBirth = f.dateOfBirth;
      e.idNumber = f.idNumber;
      e.name = f.name;
      e.surname = f.surname;
      e.otherInfo = f.otherInfo;
      e.titleBefore = f.titleBefore;
      e.titleAfter = f.titleAfter;
      vm.executiveFounders.push(e);

      vm.errorFounders.executiveManager = false;
    }
  }

  function changeEndUserType() {
    let companies = vm.founders.filter(onlyCompanies);

    if(companies.length === vm.founders.length) {
      vm.endUser = ["other"];
      vm.order.endUserType = "other";
    } else {
      vm.endUser = ["founders", "other"];
      vm.order.endUserTypeOther && vm.order.endUserTypeOther.length > 0 ? vm.order.endUserType = "other" : vm.order.endUserType = "founders";
    }
  }

  function addingFounder(f) {
    vm.founders.push(f);
    if(f.isExecutiveManager) {
      vm.executiveFounders = [];
      vm.founders.forEach(addExecutiveFounder);
    }

    saveSession();
    changeEndUserType();
    remainingCapital();
  }

  function remainingCapital() {
    vm.validate.amount = _.reduce(vm.founders, function(memo,founder){ return memo + founder.capital}, 0 );
    vm.validate.extent = _.reduce(vm.founders, function(memo,founder){ return memo + founder.paid}, 0 );
    vm.validate.orderAmount = vm.order.capital.amount;
    vm.validate.founders = vm.founders.length;

    if(vm.executiveManagers.length !== 0 || vm.executiveFounders.length !== 0)
      vm.errorFounders.executiveManager = false;
    if(vm.founders.length !== 0){
        vm.errorFounders.founder = false;
        vm.errorFounders.depositAdmin = !findDepositAdmin();
    }
  }

  function onEditFounderClick(index) {
    vm.validate.amount = vm.validate.amount - vm.founders[index].capital;
    vm.validate.extent = vm.validate.extent - vm.founders[index].paid;
    vm.validate.orderAmount = vm.order.capital.amount;
    vm.validate.founders = vm.validate.founders - 1;

    let existDepositAdmin = false;
    for(var i = 0; i < vm.founders.length; i++) {
      if(vm.founders[i].isDepositAdministrator)
        existDepositAdmin = true;
    }

    if(existDepositAdmin) {
      existDepositAdmin = !vm.founders[index].isDepositAdministrator;
      vm.errorFounders.depositAdmin = false;
    }

    ModalFounder(cities, countries, vm.order.capital, vm.validate, existDepositAdmin, vm.isSinglePerson)
      .edit(vm.founders[index])
      .result
      .then(f => editFounder(f, index), function () {recalculate()});

  }

  function editFounder(f, index) {
    vm.founders[index] = f;
    vm.executiveFounders = [];
    vm.founders.forEach(addExecutiveFounder);

    saveSession();
    changeEndUserType();
    remainingCapital();
  }

  function onRemoveFounderClick(index) {
      ModalConfirmDelete("confirmDelete.infoFounder").add().result.then(x => {
        vm.founders.splice(index, 1);
      vm.executiveFounders = [];
      vm.founders.forEach(addExecutiveFounder);

      saveSession();
      changeEndUserType();
      remainingCapital();
  });

  }

  function onAddExecutiveManagerClick() {
    ModalExecutiveManager(cities, countries, false)
      .add()
      .result
      .then(em => {
        vm.executiveManagers.push(em);
        saveSession();
        if (em)
          vm.errorFounders.executiveManager = false;
      });
  }

  function onEditExecutiveManagerClick(index) {
    ModalExecutiveManager(cities, countries, false)
      .edit(vm.executiveManagers[index])
      .result
      .then(f => {vm.executiveManagers[index] = f; saveSession();});
  }

  function onRemoveExecutiveManagerClick(index) {
      ModalConfirmDelete("confirmDelete.infoExecutiveManager").add().result.then(x => {
          vm.executiveManagers.splice(index, 1);

          saveSession();
          remainingCapital();
      });
  }

  function onlyPersons(p) {
    return p.type == "person";
  }

  function onlyCompanies(p) {
    return p.type != "person";
  }

  function checkStreetNumber() {
    let addresses = [];

    vm.founders.forEach(f => {
      addresses.push(f.address);
    });

    vm.executiveManagers.forEach(e => {
      addresses.push(e.address);
    });

    var result = false;

    addresses.forEach(address => {
      if (address && address.streetNo && (!address.streetNoPrefix || address.streetNoPrefix === ""))
        result = true;
    });

    return result;
  }

  function onSaveAndContinueClick(steps) {
    if($scope.step2.$invalid || !(vm.founders.length > 0 && (vm.executiveManagers.length > 0 || vm.executiveFounders.length > 0) && findDepositAdmin())) {
      if(!findDepositAdmin())
        vm.errorFounders.depositAdmin = true;
      if(vm.executiveManagers.length === 0 && vm.executiveFounders.length === 0)
        vm.errorFounders.executiveManager = true;
      if(vm.founders.length === 0)
        vm.errorFounders.founder = true;
      vm.requiredMessage = true;

      if(vm.errorFounders.founder || vm.errorFounders.depositAdmin)
        vm.scrollTo("error_founders");
      else if (vm.errorFounders.executiveManager)
        vm.scrollTo("error_executors");
      else vm.scrollTo("content");

      vm.submitted=true;
    } else {
      if (vm.showStreetNumberModals && checkStreetNumber()) {
        ModalStreetNumber()
            .add()
            .result
            .then();

        vm.showStreetNumberModals = false;
      } else {
        vm.order.executiveManagers = vm.executiveManagers;
        vm.order.founders = vm.founders.filter(onlyPersons);
        vm.order.foundersCompanies = vm.founders.filter(onlyCompanies);

        server.setFoundersManagers(vm.order).then(() => {
            if(steps){
              vm.valid = true;
            } else {
              $window.location = "krok3";
            }
          // }
        })
        .catch((e) => console.log(`todo: ${e}`));
      }
    }
  }

  function onBackClick() {
    vm.order.executiveManagers = vm.executiveManagers;
    vm.order.founders = vm.founders.filter(onlyPersons);
    vm.order.foundersCompanies = vm.founders.filter(onlyCompanies);

    server.setBackContent(JSON.stringify(vm.order)).then(({success, data}) => {
      if (success) {
        $window.location = "krok1";
      } else {
        // todo validation
      }
    });
  }

  function onFoundersManagersLoad(order) {
    if(order) {
      vm.order = order;
      vm.founders = order.founders.concat(order.foundersCompanies);
      vm.executiveManagers = order.executiveManagers;
      vm.executiveFounders = [];
      vm.founders.forEach(addExecutiveFounder);
      //console.log(order);
    } else {
      server.getStringContent().then((data) => {
        if(data) {
          let order = JSON.parse(data);
          vm.order = order;
          vm.founders = order.founders.concat(order.foundersCompanies);
          vm.executiveManagers = order.executiveManagers;
          vm.executiveFounders = [];
          vm.founders.forEach(addExecutiveFounder);
        }
      });
    }
    recalculate();

    changeEndUserType();

    window.hideSpinnerWhenAllResolved();
  }

  // function canGoNext() {
  //   let capital = remainingCapital();
  //   return vm.founders.length > 0 && (vm.executiveManagers.length > 0 || vm.executiveFounders.length > 0) && capital.amount === 0 && capital.extent === 0;
  // }

  function onSaveForLaterClick() {
    vm.order.executiveManagers = vm.executiveManagers;
    vm.order.founders = vm.founders.filter(onlyPersons);
    vm.order.foundersCompanies = vm.founders.filter(onlyCompanies);

    ModalFinishLater(server, JSON.stringify(vm.order))
      .add(server, JSON.stringify(vm.order))
      .result
      .then();
  }


}

angular.module("firmaren")
  .controller("ZalozenieSroKrok2Controller", ZalozenieSroKrok2Controller);
}());
