;(function() {
/* @ngInject */
function RozsirenieZivnostiKrok3Controller(RozsirenieZivnostiKrok3Service, UserService, routes, _, $window, Helpers, $filter, ModalFinishLater, $scope) {
  let vm = this;
  let server = RozsirenieZivnostiKrok3Service;
  vm.server = server;

  let userService = UserService;

  vm.maxStep = 3;
  vm.cart = { };
  vm.selected = [];
  vm.agreements = {
      agrees: false,
      representation: true
  };
  vm.upsells = {
    tb: {
      active: false,
      agree: false
    },
    superfaktura: {
      active: false,
      agree: false
    },
    znamka: {
      active: false
    }
  };
  vm.requiredCheckbox = false;
  vm.orderId = 0;
  vm.user = {};
  vm.zvyhodnena = false;
  vm.ip = "";

  vm.onBackClick = onBackClick;
  vm.onFinishClick = onFinishClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.scrollTo = Helpers.scrollTo;
  vm.mergeStreetNo = Helpers.mergeStreetNo;
  vm.getFullName = Helpers.getFullName;
  vm.dateToString = Helpers.dateToString;
  vm.idNumberToString = Helpers.idNumberToString;
  vm.phoneNumberToString = Helpers.phoneNumberToString;
  vm.formatCityZipCode = Helpers.formatCityZipCode;
  vm.formatStreet = Helpers.formatStreet;
  vm.businessAddress = {};

  window.showSpinner(8);
  server.getMaxStep().then( (ms) => {vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
  server.getSelectedSubjects().then(onSelectedSubjectsLoad);
  server.getTotalPrice().then(onTotalPriceLoad);
  server.getOrder().then(onOrderLoad);
  server.getAgreementAndRepresentation().then(onAgreementAndRepresentationLoad);
  server.getOrderId().then((id) => { vm.orderId = id; window.hideSpinnerWhenAllResolved(); });
  server.getUpsell().then((upsell) => { vm.upsells = upsell; window.hideSpinnerWhenAllResolved(); });
  server.getIPAddress().then((ip) => vm.ip = ip);
  userService.getLoggedUser().then((user) => {vm.user = user; window.hideSpinnerWhenAllResolved()});
  userService.isOldUser().then((ms) => vm.zvyhodnena = ms);

  $scope.$on('on-price-change', function(event, args) {
    onPriceChange();
  });

  vm.inProgress = false;

  function onPriceChange() {
    server.getCart().then(onCartLoad);
  }

  function onCartLoad(cart) {
    vm.cart = cart;
  }

  function onSelectedSubjectsLoad(ss) {
    if (angular.isDefined(ss)) {
      //console.log(ss.subjects);
      vm.selected = ss.subjects;
      vm.cart.size = ss.subjects.length;
    }
    //console.log(vm.selected);
    window.hideSpinnerWhenAllResolved();
  }

  function onOrderLoad(order) {
    if (order.contractorInfo.businessAddress.type === "same") {
      vm.businessAddress = order.personalInfo.address;
    } else if (order.contractorInfo.businessAddress.type === "own") {
      vm.businessAddress = order.contractorInfo.businessAddress.address;
    } else {
      vm.businessAddress = order.contractorInfo.businessAddress;
    }
    if (angular.isDefined(order)) {
      let name = vm.getFullName(order.personalInfo);

      vm.order = angular.extend(order, {
        personalInfo: angular.extend(order.personalInfo, {
          fullname: name,
          address: vm.formatStreet(order.personalInfo.address),
          city: vm.formatCityZipCode(order.personalInfo.address),
          country: order.personalInfo.address.country,
          dateOfBirth: vm.dateToString(order.personalInfo.dateOfBirth),
          idNumber: vm.idNumberToString(order.personalInfo.idNumber),
          phoneNumber: vm.phoneNumberToString(order.personalInfo.phoneNumber)
        }),
        contractorInfo: angular.extend(order.contractorInfo, {
          name: (order.contractorInfo.name) ? `${name} - ${order.contractorInfo.name}` : name,
          businessAddress: formatTypedAddress(order.contractorInfo.businessAddress),
          licenceDeliveryAddress: formatTypedAddress(order.contractorInfo.licenceDeliveryAddress)
        })
      });

      onPriceChange();

      window.hideSpinnerWhenAllResolved();
    }
  }

  function onTotalPriceLoad(tp) {
    vm.totalPrice = tp;
    vm.cart.price = tp;

    window.hideSpinnerWhenAllResolved();
  }

  function onAgreementAndRepresentationLoad(aar) {
    if (angular.isDefined(aar)) {
      vm.agrees = aar.agrees;
      vm.requestsRepresentation = aar.requestsRepresentation;
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onBackClick() {
    $window.location = "krok2";
  }

  function finishSave() {
    // todo: data + validation
    server.finish({agrees: vm.agreements.agrees, requestsRepresentation: vm.agreements.representation})
        .then(({success, data, error}) => {
        if (success) {
          $window.location = routes.zivnosti.rozsirenie.objednane;
        }
        vm.inProgress = false;
        window.hideSpinnerWhenAllResolved();
      })
      .catch(error => {
        console.log(error);
        vm.inProgress = false;
        window.hideSpinnerWhenAllResolved();
      });
  }

  function onFinishClick() {
    if (!vm.agreements.agrees) {
      vm.requiredCheckbox = true;
      vm.scrollTo("scroll_checkbox");
    } else {
      var dataLayer = Helpers.getDataLayer(vm.orderId,
          vm.zvyhodnena ? 'rozsirenie_zivnost_zvyhodnena' : 'rozsirenie_zivnost',
          'zivnost', '', vm.user);
      $window.localStorage.setItem('dataLayer', JSON.stringify(dataLayer));

      vm.inProgress = true;
      window.showSpinner(1);

      if (vm.upsells.tb.active) {
        let personInfo = vm.order.personalInfo;
        exponea.track( "contact_form_external ",{
          "company_name": personInfo.fullname,
          "contact_person": personInfo.name + ' ' + personInfo.surname,
          "agreement_memorandum": 1,
          "agreement_mkt": vm.upsells.tb.agree ? 1 : 0,
          "IP_address": vm.ip,
          "agreement_mkt_timestamp": vm.upsells.tb.agree && vm.upsells.tb.timestampAgree ? vm.upsells.tb.timestampAgree : "",
          "phone": personInfo.phoneNumber,
          "version":"2",
          "identify_type":"contact_form_external",
          "ico": vm.order.contractorInfo.ico,
          "psc": vm.businessAddress.zipCode
        }, function (param) { //successCallback return {status: "ok"}
          if (param.status && param.status === "ok") {
            vm.upsells.tb.success = true;
          } else vm.upsells.tb.success = false;

          finishSave();
        },
        function () {
          vm.upsells.tb.success = false;

          finishSave();
        });
      } else finishSave();
    }
  }

  function formatTypedAddress(address) {
    if(address) {
      if(address.type === "firmaren") {
        return address.street + ", " + address.city + " " + address.zipCode;
      } else if (address.type === "otherLicence") {
        return address.place;
      } else if (address.type === "work") {
        return $filter("translate")('zalozenieZivnosti.krok2.contractorInfo.originalWorkPlace');
      } else if (address.type === "same") {
        return $filter("translate")('zalozenieZivnosti.krok2.contractorInfo.originalHome');
      }
    }
  }

  function onSaveForLaterClick() {
    ModalFinishLater(server)
      .add(server)
      .result
      .then();
  }
}

angular.module("firmaren")
  .controller("RozsirenieZivnostiKrok3Controller", RozsirenieZivnostiKrok3Controller);
}());
