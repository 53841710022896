;(function() {
/* @ngInject */
function ModalImg($uibModal, $rootScope) {
    let img;

    return function init(img_) {
        img = img_;

        return { add };
    };

    function open(options = {}) {
        return $uibModal.open(angular.extend({
            templateUrl: "/app/shared/modal-img/modal-img.html",
            controller: "ModalImgController",
            controllerAs: "vm",
            bindToController: true,
            resolve: {
                img: () => img
            },
            size: "lg",
        }, options));
    }

    function add(scope = {}) {
        return open({ scope: angular.extend($rootScope.$new(true), scope) })
    }
}

/* @ngInject */
function ModalImgController($uibModalInstance, img) {
    let vm = this;

    vm.img = img;

    vm.close = () => $uibModalInstance.close("cancel");

}

angular.module("firmaren")
    .factory("ModalImg", ModalImg)
    .controller("ModalImgController", ModalImgController);
}());
