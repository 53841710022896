;(function() {
/* @ngInject */
function FaqController(IndexService, $location, Helpers, $window, $interval, $scope, normalize, ModalSearchFaq) {

  let vm = this;
  let server = IndexService;

  vm.cat = "sro";
  vm.index = 0;

  let res = $location.path().split("/");
  if(res.length > 1) {
    vm.cat = res[1];
    vm.activeTab = res[2];
    vm.activeQuestion = res[3];
  }

  vm.loadingUrl = false;
  vm.searchText = "";

  vm.changeQuestions = changeQuestions;
  vm.changeTab = changeTab;
  vm.setPrevElem = setPrevElem;
  vm.search = search;
  vm.normalizeTab = normalizeTab;
  vm.scrollTo = Helpers.scrollTo;

  window.showSpinner(1);
  server.getFaq().then(onFaqLoad);

  vm.reloadPage = false;

  $scope.$watch(function(){
    return $location.path();
  }, function(value){
    if (vm.reloadPage) {
      $window.location.reload();
    }

    vm.reloadPage = true;
  });

  function normalizeTab(tab) {
    return normalize(tab.toLowerCase().replace(new RegExp(" ", 'g'), "_").replace(/\./g,''));
  }

  function search() {
    if(vm.searchText.length > 0) {
      vm.results = [];

      angular.forEach(vm.items, function (category, catIndex) {
        angular.forEach(category.subcategory, function (subcategory) {
          angular.forEach(subcategory.content, function (content, contentIndex) {
            if (normalize(content.question).search(vm.searchText) !== -1 || normalize(content.answer).search(vm.searchText) !== -1) {
              vm.results.push({category: category.category, subcategory: subcategory.title, content: content, index: catIndex, question: "" + contentIndex});
            }
          })
        })
      });

      ModalSearchFaq(vm.results, vm.searchText)
        .add()
        .result
        .then(item => {

          vm.index = item[0];
          vm.cat = normalizeTab(item[1]);
          vm.tabs = vm.items[vm.index].subcategory;
          vm.activeTab = normalizeTab(item[2]);
          vm.activeQuestion = item[3];

          vm.loadingUrl = true;
          vm.reloadPage = false;
          $location.path("/" + vm.cat + "/" + vm.activeTab + "/" + vm.activeQuestion);

          if (vm.prevElem)
            vm.prevElem.isCollapsed = false;

          $interval(function () {
            vm.scrollTo("question_" + vm.activeTab + vm.activeQuestion);
            let doc = $window.document.getElementById("question_" + vm.activeTab + vm.activeQuestion);
            let elem = angular.element(doc).scope();
            elem.isCollapsed = true;
            vm.prevElem = elem;
          }, 100, 1);
        });
    }
  }

  function setPrevElem(elem, index) {
    if(vm.prevElem !== elem) {
      if (vm.prevElem)
        vm.prevElem.isCollapsed = false;

      if (!elem.isCollapsed)
        vm.prevElem = elem;
    }

    vm.reloadPage = false;
    if(!elem.isCollapsed)
      $location.path("/" + vm.cat + "/" + vm.activeTab + "/" + index);
    else $location.path("/" + vm.cat + "/" + vm.activeTab);
  }

  function changeTab(tab) {
    vm.activeTab = normalizeTab(tab);

    vm.reloadPage = false;

    if(vm.loadingUrl) {
      //$location.search({'id': vm.index, 'tab': vm.activeTab, 'q' : vm.activeQuestion});
      $location.path("/" + vm.cat + "/" + vm.activeTab + "/" + vm.activeQuestion);
      vm.loadingUrl = false;
    } else {
      if(vm.prevElem)
        vm.prevElem.isCollapsed = false;

      $location.path("/" + vm.cat + "/" + vm.activeTab);
    }
  }

  function changeQuestions(cat, index) {
    vm.index = index;
    vm.cat = normalizeTab(cat);
    vm.tabs = vm.items[vm.index].subcategory;

    vm.activeTab = normalizeTab(vm.tabs[0].title);

    vm.reloadPage = false;
    $location.path("/" + vm.cat + "/" + vm.activeTab);
  }

  function onFaqLoad(items) {
    vm.items = items;

    vm.items.forEach(function (item, index) {
      if(normalizeTab(item.category) === vm.cat)
        vm.index = index;
    });

    vm.tabs = vm.items[vm.index].subcategory;

    if(!vm.activeTab)
      vm.activeTab = normalizeTab(vm.tabs[0].title);

    if(vm.activeQuestion) {
      $interval(function () {
        vm.scrollTo("question_" + vm.activeTab + vm.activeQuestion);
        let doc = $window.document.getElementById("question_" + vm.activeTab + vm.activeQuestion);
        let elem = angular.element(doc).scope();
        elem.isCollapsed = true;
        vm.prevElem = elem;
      }, 100, 1);
      vm.loadingUrl = true;
    }

    vm.reloadPage = true;
    window.hideSpinnerWhenAllResolved();
  }

}

angular.module("firmaren")
  .controller("FaqController", FaqController);
}());
