;(function() {
/* @ngInject */
function ModalBanner($uibModal, $rootScope) {
  let text;
  return function init(text_) {
    text = text_;
    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-banner/modal-banner.html",
      controller: "ModalBannerController",
      controllerAs: "vm",
      bindToController: true,
      size: "banner-size",
      backdropClass: "video-backdrop",
      resolve: {
        text: () => text
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) , text})
  }
}

/* @ngInject */
function ModalBannerController($uibModalInstance, text) {
  let vm = this;

  vm.text = text;

  vm.close = () => $uibModalInstance.dismiss("cancel");

}

angular.module("firmaren")
  .factory("ModalBanner", ModalBanner)
  .controller("ModalBannerController", ModalBannerController);
}());
