;(function() {
/* @ngInject */
function ResponsiblePersonController(IndexService, Helpers, $scope, $window, $interval, $filter) {
  let vm = this;
  let server = IndexService;

  let minDate = new Date();

  this.$onInit = function () {
    const trader = $filter('translate')('responsiblePerson.trader');
    const other = $filter('translate')('responsiblePerson.other');
    vm.responsiblePersonTypes = [{value: "0", name: trader}, {value: "other", name: other}];

    for (let i = 0; i < vm.selected.length; i++) {
      if (vm.selected[i].id === vm.subject.id)
        vm.subject.responsiblePerson = vm.selected[i].responsiblePerson;
    }

    if (vm.founders) {
      vm.responsiblePersonTypes = [];
      vm.founders.forEach(changeResponsiblePersonTypes);
      vm.responsiblePersonTypes.push({value: "other", name: "Iná osoba"});
    }

    if (!vm.subject.responsiblePerson) {
      vm.subject.responsiblePerson = {
        businessPlaceType: "no",
        businessPlace: {},
        person: {
          address: {}
        }
      };

      vm.subject.responsiblePerson.type = vm.responsiblePersonTypes[0].value;
      vm.subject.responsiblePerson.name = vm.responsiblePersonTypes[0].name;
    }

    vm.isCollapsed = {
      isOpen: false
    };

    vm.days = createArray(1, 31, false);
    vm.months = createArray(1, 12, false);
    vm.years = createArray(1900, minDate.getFullYear() - 18, true);



    function changeResponsiblePersonTypes(t) {
      let name = t.name + " " + t.surname;
      let value = angular.isDefined(t.index) ? t.index.toString() : name;
      vm.responsiblePersonTypes.push({value: value, name: name});
    }

    vm.onZipCodeChange = onZipCodeChange;
    vm.onElementFocus = Helpers.onElementFocus;
    vm.onResetCountry = onResetCountry;
    vm.submit = submit;
    vm.scrollTo = Helpers.scrollTo;

    server.getCities().then(onCitiesLoad);
    server.getCountries().then(onCountriesLoad);

    function submit() {
      if ($scope.responsiblePerson.$invalid) {
        vm.isCollapsed.isOpen = true;
        vm.submitted = true;
      } else {
        //console.log(vm.subject.responsiblePerson);
        vm.select(vm.subject);
        vm.subject.isShownForm = false;

        if (vm.summary)
          vm.scrollTo("selected_subjects_section");
      }
    }

    function createArray(start, end, reverse) {
      let array = [];
      for (var i = start; i <= end; i++) {
        array.push(i);
      }
      if (reverse)
        return array.reverse();
      else return array;
    }

    function onCountriesLoad(countries) {
      vm.countries = countries;
      if (!vm.subject.responsiblePerson.person) {
        vm.subject.responsiblePerson.person = {
          address: {}
        }
      }

      vm.subject.responsiblePerson.person.address.country = vm.countries[0]._2;
    }

    function onCitiesLoad(cities) {
      vm.cities = cities;
    }

    function onZipCodeChange(elem, $item) {
      if (elem === "businessPlace")
        vm.subject.responsiblePerson.businessPlace.zipCode = $item && $item._3;
      if (elem === "address")
        vm.subject.responsiblePerson.person.address.zipCode = $item && $item._3;
    }

    function onResetCountry() {
      vm.subject.responsiblePerson.person.address.country = "";
      $interval(function () {
        let element = $window.document.getElementById("address");
        if (element)
          element.focus();
      }, 100, 1);
    }
  }

}

angular.module("firmaren")
  .component("responsiblePerson", {
    templateUrl: "/app/shared/responsible-person/responsible-person.html",
    controller: ResponsiblePersonController,
    controllerAs: "vm",
    bindings: {
      subject: "=",
      select: "=",
      founders: "=",
      selected: "=",
      summary: "=",
      isChangeCompany: "="
    }
  });
}());
