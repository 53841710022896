;(function() {
/* @ngInject */
function ZalozenieOZKrok3Controller(ZalozenieOZKrok3Service, UserService, $window, Helpers, ModalFinishLater, $scope) {
  let vm = this;
  let server = ZalozenieOZKrok3Service;
  vm.server = server;

  let userService = UserService;

  vm.order = {};

  vm.loadingSteps = false;
  vm.orderId = 0;
  vm.user = {};

  function getSelfBoolean() {
    let res = $window.location.pathname.split("/");
    if (res[1] === "zalozenie-obcianskeho-zdruzenia")
      vm.order.isSelf = false;
    else vm.order.isSelf = true;

    vm.loadingSteps = true;
  }

  getSelfBoolean();

  server.getMaxStep().then( (ms) => { vm.maxStep = ms});
  server.getPersons().then(onPersonsLoad);
  server.getOzInfo().then(onGetOzInfo);
  server.getCart().then(onCartLoad);
  server.getOrderId().then((id) => { vm.orderId = id });
  server.getUpsell().then((upsell) => { vm.upsells = upsell });
  userService.getLoggedUser().then((user) => {vm.user = user});

  vm.onFinishClick = onFinishClick;
  vm.onBackClick = onBackClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.dateToString = Helpers.dateToString;
  vm.idNumberToString = Helpers.idNumberToString;
  vm.phoneNumberToString = Helpers.phoneNumberToString;
  vm.scrollTo = Helpers.scrollTo;
  vm.getFullName = Helpers.getFullName;
  vm.mergeStreetNo = Helpers.mergeStreetNo;

  $scope.$on('on-price-change', function(event, args) {
    server.getCart().then(onCartLoad);
  });

  vm.agreements = {
    agrees: false,
    representation: true
  };
  vm.inProgress = false;
  vm.requiredCheckbox = false;

  vm.upsells = {};

  function onSaveForLaterClick() {
    ModalFinishLater(server)
      .add(server)
      .result
      .then();
  }

  function onPersonsLoad(value) {
    vm.order.step2 = value;
  }

  function onCartLoad(cart) {
    vm.cart = cart;
  }

  function onGetOzInfo(ps) {
    if(ps) {
      vm.order.step1 = ps;
    }
  }

  function onBackClick() {
    $window.location = "krok2";
  }

  function onFinishClick() {
    if(!vm.agreements.agrees) {
      vm.requiredCheckbox = true;
      vm.scrollTo("scroll_checkbox");
    } else {
      var dataLayer = Helpers.getDataLayer(vm.orderId,
          vm.order.isSelf ? 'uss_oz' : 'zalozenie_oz',
          vm.order.isSelf ? 'uss' : 'oz', '', vm.user);
      $window.localStorage.setItem('dataLayer', JSON.stringify(dataLayer));
      vm.inProgress = true;
      window.showSpinner(1);
      // todo: data + validation
      server.finish({agrees: vm.agreements.agrees, requestsRepresentation: vm.agreements.representation})
        .then(({success, data, error}) => {
          if (success) {
            $window.location = "objednane";
          }
          vm.inProgress = false;
          window.hideSpinnerWhenAllResolved();
        })
        .catch(error => {
          console.log(error);
          vm.inProgress = false;
          window.hideSpinnerWhenAllResolved();
        });
    }
  }
}

angular.module("firmaren")
  .controller("ZalozenieOZKrok3Controller", ZalozenieOZKrok3Controller);
}());
