;(function() {
/* @ngInject */
function Tooltip() {
  return {
    restrict: 'E',
    templateUrl: "/app/shared/tooltip/tooltip-view.html",
    scope : {
      text : "@",
      tooltipClass : "@",
      position : "@",
      html : "@",
      videoLink : "@",
      videoText : "@"
    },
    controllerAs: 'vm',
    controller: "TooltipController"
  };
}

/* @ngInject */
function TooltipController($scope, $timeout, ModalVideo, IndexService, $location) {
  let vm = this;
  let service = IndexService;

  vm.playVideo = playVideo;
  vm.opened = false;

  function playVideo(videoUrl) {
    vm.opened = false;
    let url = $location.absUrl();
    service.setStatsOfClickStreetNoVideo(url).then(() => {
      ModalVideo(videoUrl)
          .add()
          .result
          .then();
    });
  }

  $timeout(function() {
    if (angular.isUndefined($scope.position)) {
      $scope.position = "right";
    }
  });
}

angular.module("firmaren")
  .controller("TooltipController", TooltipController)
  .directive("firmarenTooltip", Tooltip);
}());
