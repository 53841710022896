;(function() {
let masks = {
  phoneNumber: "999 999 999",
  ico: "99999999"
};

/* @ngInject */
function onStart($rootScope) {
  $rootScope.masks = masks;
}

angular.module("firmaren")
  .constant("masks", masks)
  .run(onStart);
}());
