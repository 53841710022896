;(function() {
/* @ngInject */
function ModalExecutionType($uibModal, $rootScope) {
  let executionType;
  let executionTypeOther;

  return function init(executionType_, executionTypeOther_) {
    executionType = executionType_;
    executionTypeOther = executionTypeOther_;

    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-execution-type/modal-execution-type.html",
      controller: "ModalExecutionTypeController",
      controllerAs: "vm",
      bindToController: true,
      size: "md",
      backdrop: "static",
      resolve: {
        executionType: () => executionType,
        executionTypeOther: () =>executionTypeOther
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) })
  }
}

/* @ngInject */
function ModalExecutionTypeController($uibModalInstance, $scope, executionType, executionTypeOther) {
  let vm = this;

  vm.executionType = executionType;
  vm.executionTypeOther = executionTypeOther;

  vm.executionTypes = ["onTheirOwn", "together", "other"];
  if(!vm.executionType)
    vm.executionType = vm.executionTypes[0];

  vm.close = () => $uibModalInstance.dismiss("cancel");

  vm.submit = () => {
    if($scope.executionType.$invalid) {
      vm.requiredMessage = true;
      vm.submitted=true;
    } else $uibModalInstance.close({executionType: vm.executionType, executionTypeOther: vm.executionTypeOther});
  }

}

angular.module("firmaren")
  .factory("ModalExecutionType", ModalExecutionType)
  .controller("ModalExecutionTypeController", ModalExecutionTypeController);
}());
