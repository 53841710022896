;(function() {
/* @ngInject */
function GoalsSearchController(ZalozenieOZKrok1Service, $document, $compile, $scope, $window, _, normalize, ModalGoals) {
  let vm = this;
  let service = ZalozenieOZKrok1Service;

  vm.input = { value: "", isFocused: false };
  vm.results = [];
  
  let body = $document.find("body").eq(0);
  let bgElem = $compile("<div class='modal-backdrop' ng-show='vm.input.isFocused' ng-click='vm.onBlur()'></div>")($scope);
  body.append(bgElem);


  vm.onFocus = onFocus;
  vm.onBlur = onBlur;
  vm.clearText = clearText;
  vm.onKeydown = onKeydown;
  vm.resultsNonEmpty = resultsNonEmpty;
  vm.filter = filter;
  vm.onListClick = onListClick;

  function onListClick() {
    ModalGoals.open({
      goals: vm.goals,
      selectedGoals: vm.selectedGoals
    });
  }

  function resultsNonEmpty() {
    return !_.isEmpty(vm.results);
  }
  
  function filter() {
    let v = normalize(vm.input.value);
    if (v !== "") {
      vm.results = _(vm.goals)
        .filter(g => {
          return (
            _.includes(normalize(g.goal), v) ||
            _.includes(normalize(g.category), v) ||
            _.includes(normalize(g.subcategory), v) ||
            _.includes(normalize(g.tags), v)
          );
        })
        .take(49)
        .value();

      vm.results.push({goal: vm.input.value, category: "", subcategory:"", tags: ""});
    }
    else {
      vm.results = [];
    }
  }

  function onKeydown(e) {
    if (e.keyCode == 27) {
      vm.input.isFocused = false;
    }
  }

  function clearText() {
    vm.input.value = "";
    let element = $window.document.getElementById('btn_search_bar');
    if(element)
      element.focus();
    filter();
  }

  function onFocus(scrollLocation) {
    vm.input.isFocused = true;
    body.addClass("modal-open modal-open-without-fixed");

    let offsetScroll = 0;
    if (window.matchMedia('(min-width: 45em)').matches)
      offsetScroll = 170;
    else offsetScroll = 50;

    $('html, body').animate({
      scrollTop: $("#" + scrollLocation).offset().top - offsetScroll
    }, 500);
  }

  function onBlur() {
    vm.input.isFocused = false;
    body.removeClass("modal-open modal-open-without-fixed");
  }
}

angular.module("firmaren")
  .component("goalsSearch", {
    templateUrl: "/app/shared/goals-search/goals-search.html",
    controller: GoalsSearchController,
    controllerAs: "vm",
    bindings: {
      goals: "=",
      selectedGoals: "=",
      errorGoals: "="
    }
  });
}());
