;(function() {
/* @ngInject */
function OchrannaZnamkaController(IndexService, $scope, $interval, Helpers, $window) {

    let vm = this;

    let server = IndexService;

    vm.onSendClick = onSendClick;

    vm.tradeMark = {};

    vm.aggrement = false;

    function initForm() {
        vm.tradeMark = {
            phoneNumber: {
                prefix: "+421"
            }
        };
    }

    initForm();

    function onSendClick() {
        if (vm.tradeMark) {
            server.sendTradeMark(vm.tradeMark).then((data) => {
                vm.success = true;
                var d = Helpers.getDataLayer(-1, 'ochranna_znamka',
                    'ine', '');

                console.log(d);

                var dataLayer = $window.dataLayer = $window.dataLayer || [];
                if (d)
                    dataLayer.push(d);

                initForm();
                $scope.order.$setUntouched();
                $interval(function () {
                    vm.success = false;
                }, 2000, 1);
            });
        }
    }
}

angular.module("firmaren")
    .controller("OchrannaZnamkaController", OchrannaZnamkaController);
}());
