;(function() {
/* @ngInject */
function normalizeFactory(removeDiacritics) {
  return normalize;

  function normalize(s) {
    return removeDiacritics(s).toLowerCase()
  }
}

angular.module("firmaren")
  .factory("normalize", normalizeFactory);
}());
