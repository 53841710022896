;(function() {
/* @ngInject */
function ReviewsController(IndexService, $interval, $scope, $window, routes) {

    let vm = this;
    
    
    vm.reviews = {};

    let server = IndexService;

    window.showSpinner(1);
    server.getOrderEmail().then(onOrderEmailLoad);

    vm.onSendReviewClick = onSendReviewClick;
    
    function onOrderEmailLoad(item) {
        if(item) {
            vm.reviews.email = item;
            window.hideSpinnerWhenAllResolved();
        } else {
            $window.location = routes.index;
        }
    }

    function onSendReviewClick() {
        server.sendReviewsMail(vm.reviews).then(() => {
            vm.success = true;
            vm.reviews.message = "";
            $scope.reviews.$setUntouched();
            $interval(function() {
                vm.success = false;
            }, 5000, 1);
        });
    }
}

angular.module("firmaren")
    .controller("ReviewsController", ReviewsController);
}());
