;(function() {
/* @ngInject */
function KonverziaDokumentovKrok2Controller(KonverziaDokumentovKrok2Service, UserService, $window, routes, $scope, Helpers, ModalFinishLater) {
    let vm = this;
    let server = KonverziaDokumentovKrok2Service;

    let userService = UserService;

    vm.maxStep = 2;
    vm.order = {};
    vm.requiredCheckbox = false;

    vm.onBackClick = onBackClick;
    vm.onFinishClick = onFinishClick;
    vm.onSaveForLaterClick = onSaveForLaterClick;
    vm.scrollTo = Helpers.scrollTo;
    vm.mergeStreetNo = Helpers.mergeStreetNo;

    vm.cart = {
        finalPrice: 0
    };

    vm.inProgress = false;

    vm.agreements = {
        agrees: false,
        representation: false
    };

    vm.user = {};

    vm.orderId = 0;
    vm.hidePrice = true;
    vm.spravnyPoplatok = 0;

    window.showSpinner(5);
    server.getOrder().then((order) => {
        vm.order = order;
        if(vm.order.service === "other") {
            vm.spravnyPoplatok = 5;
        } else vm.spravnyPoplatok = 0;
        window.hideSpinnerWhenAllResolved();
    });
    server.getMaxStep().then((ms) => {vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
    server.getPrice().then(onPriceLoad);
    server.getOrderId().then((id) => { vm.orderId = id; window.hideSpinnerWhenAllResolved(); });
    userService.getLoggedUser().then((user) => {vm.user = user; window.hideSpinnerWhenAllResolved()});

    $scope.$on('show-cart-price', function (event, args) {
        vm.hidePrice = false;
    });

    function onPriceLoad(c) {
        vm.cart.price = c.totalPriceWithTax;
        vm.cart.priceWithoutTax = c.totalPrice;

        window.hideSpinnerWhenAllResolved();
    }

    function formatDate(dateOfBirth) {
        return dateOfBirth.day.concat(".", dateOfBirth.month, ".", dateOfBirth.year);
    }

    function onBackClick() {
        $window.location = routes.sluzby.konverzia.krok1;
    }

    function onFinishClick() {
        if(!vm.agreements.agrees) {
            vm.requiredCheckbox = true;
            vm.scrollTo("scroll_checkbox");
        } else {
            var dataLayer = Helpers.getDataLayer(vm.orderId,
                'vypis_z_registrov',
                'ine', '', vm.user);
            $window.localStorage.setItem('dataLayer', JSON.stringify(dataLayer));

            vm.inProgress = true;
            window.showSpinner(1);
            // todo: data + validation
            server.finish({
                agrees: vm.agreements.agrees,
                requestsRepresentation: vm.agreements.representation
            }).then(function (_ref) {
                var success = _ref.success;
                var data = _ref.data;
                var error = _ref.error;

                if (success) {
                    $window.location = routes.sluzby.konverzia.objednane;
                }
                vm.inProgress = false;
                window.hideSpinnerWhenAllResolved();
            }).catch(function (error) {
                console.log(error);
                vm.inProgress = false;
                window.hideSpinnerWhenAllResolved();
            });
        }
    }

    function onSaveForLaterClick() {
        ModalFinishLater(server)
            .add(server)
            .result
            .then();
    }


}

angular.module("firmaren")
    .controller("KonverziaDokumentovKrok2Controller", KonverziaDokumentovKrok2Controller);
}());
