;(function() {
/* @ngInject */
function ReadyMadeKrok3Controller(ReadyMadeKrok3Service, UserService, BusinessSubjects, $scope, $window, Helpers, ModalFinishLater, routes, $filter) {
    let vm = this;
    let server = ReadyMadeKrok3Service;

    let userService = UserService;

    vm.subjects = BusinessSubjects;

    vm.maxStep = 2;

    vm.onFinishClick = onFinishClick;
    vm.onSaveForLaterClick = onSaveForLaterClick;
    vm.onBackClick = onBackClick;
    vm.scrollTo = Helpers.scrollTo;
    vm.mergeStreetNo = Helpers.mergeStreetNo;

    vm.cart = {
        finalPrice: 0
    };
    vm.order = {};
    vm.orderId = 0;
    vm.user = {};

    window.showSpinner(8);
    server.getOrder().then(onOrderLoad);
    server.getMaxStep().then( (ms) => { vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
    server.getCart().then(onCartLoad);
    server.getInvoiceInfo().then(onInvoiceInfoLoad);
    server.getPlaceOfBusiness().then(onPlaceOfBusinessLoad);
    server.getSelectedSubjects().then(onSelectedSubjectsLoad);
    server.getOrderId().then((id) => { vm.orderId = id; window.hideSpinnerWhenAllResolved(); });
    userService.getLoggedUser().then((user) => {vm.user = user; window.hideSpinnerWhenAllResolved()});

    vm.inProgress = false;
    vm.requiredCheckbox = false;

    vm.agreements = {
        agrees: false,
        representation: true
    };

    function onSelectedSubjectsLoad(ss) {
        console.log(ss);
        vm.addSubjects = ss ? ss.subjects : [];

        window.hideSpinnerWhenAllResolved();
    }

    function onBackClick() {
        $window.location = routes.sro.zmena.krok2;
    }

    function onOrderLoad(c) {
        vm.ico = c.ico;
        if(c) {
            vm.order = c;
            vm.removeSubjects = $filter('filter')(vm.order.removeSubjects, {'remove': true});
            vm.persons = {
                addExecutors: [],
                editExecutors: [],
                removeExecutors: [],
                addFounders: [],
                editFounders: [],
                addClerks: [],
                editClerks: [],
                removeClerks: []
            };

            vm.rpvsPersons = {
                add: [],
                edit: []
            };

            angular.forEach(vm.order.persons, function (person) {
                if(person.executor) {
                    if(person.change.add) vm.persons.addExecutors.push(person);
                    if(person.change.edit && person.newData) vm.persons.editExecutors.push(person);
                    if(person.change.remove) vm.persons.removeExecutors.push(person);
                }
                if(person.founder) {
                    if(person.change.add) vm.persons.addFounders.push(person);
                    if(person.change.edit && person.newData) vm.persons.editFounders.push(person);
                }
                if(person.clerk) {
                    if(person.change.add) vm.persons.addClerks.push(person);
                    if(person.change.edit && person.newData) vm.persons.editClerks.push(person);
                    if(person.change.removeClerk) vm.persons.removeClerks.push(person);
                }
            });

            angular.forEach(vm.order.rpvsPersons, function (person) {
                if(person.change.add) vm.rpvsPersons.add.push(person);
                if(person.change.edit && person.newData) vm.rpvsPersons.edit.push(person);
            });
        } else {
            server.getStringContent().then((data) => {
                if (data) {
                    vm.order = JSON.parse(data).data;
                }
            });
        }

        window.hideSpinnerWhenAllResolved();
    }

    function onInvoiceInfoLoad(o) {
        if(o) {
            vm.invoice = o;
        } else {
            server.getStringContent().then((data) => {
                if (data) {
                    vm.invoice = JSON.parse(data);
                }
            });
        }
        //console.log(vm.invoice);

        window.hideSpinnerWhenAllResolved();
    }

    function onCartLoad(c) {
        vm.cart.price = c.totalPrice;

        window.hideSpinnerWhenAllResolved();
    }

    function onFinishClick() {
        if(!vm.agreements.agrees) {
            vm.requiredCheckbox = true;
            vm.scrollTo("scroll_checkbox");
        } else {
            var dataLayer = Helpers.getDataLayer(vm.orderId,
                'ready_made',
                'sro', '', vm.user);
            $window.localStorage.setItem('dataLayer', JSON.stringify(dataLayer));
            vm.inProgress = true;
            window.showSpinner(1);
            // todo: data + validation
            server.finish({
                agrees: vm.agreements.agrees,
                requestsRepresentation: vm.agreements.representation
            }).then(function (_ref) {
                var success = _ref.success;
                var data = _ref.data;
                var error = _ref.error;

                if (success) {
                    $window.location = "objednane";
                }
                vm.inProgress = false;
                window.hideSpinnerWhenAllResolved();
            }).catch(function (error) {
                console.log(error);
                vm.inProgress = false;
                window.hideSpinnerWhenAllResolved();
            });
        }
    }

    function onSaveForLaterClick() {
        ModalFinishLater(server)
            .add(server)
            .result
            .then();
    }

    function onPlaceOfBusinessLoad(pb) {
        if (angular.isDefined(pb)) {
            if (pb.type === "own") {
                vm.placeOfBusiness = pb;
            }
            else if (pb.type === "firmaren") {
                vm.placeOfBusiness = { type: pb.type };
                vm.firmarenAddress = pb;
            }
        } else {
            server.getStringContent().then((data) => {
                if (data) {
                    vm.placeOfBusiness = JSON.parse(data).placeOfBusiness;
                    if (pb.type === "firmaren") {
                        vm.placeOfBusiness = { type: JSON.parse(data).placeOfBusiness.type };
                        vm.firmarenAddress = JSON.parse(data).placeOfBusiness;
                    }
                }
            });
        }

        window.hideSpinnerWhenAllResolved();
    }
}

angular.module("firmaren")
    .controller("ReadyMadeKrok3Controller", ReadyMadeKrok3Controller);
}());
