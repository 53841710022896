;(function() {
angular.module("firmaren").directive("numberPreventScroll", function() {
    return {
        restrict: 'A',
        link: function(scope, element) {
            element.on('wheel', function(event) {
                event.preventDefault();
            });
        }
    };
});
}());
