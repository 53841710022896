;(function() {
/* @ngInject */
function ModalPaidVideo($uibModal, $rootScope) {
    let video;
    return function init(video_) {
        video = video_;
        return { add };
    };

    function open(options = {}) {
        return $uibModal.open(angular.extend({
            templateUrl: "/app/shared/modal-paid-video/modal-paid-video.html",
            controller: "ModalPaidVideoController",
            controllerAs: "vm",
            bindToController: true,
            size: "paid-video-size",
            resolve: {
                video: () => video
            }
        }, options));
    }

    function add(scope = {}) {
        return open({ scope: angular.extend($rootScope.$new(true), scope) , video})
    }
}

/* @ngInject */
function ModalPaidVideoController($uibModalInstance, video) {
    let vm = this;

    vm.video = video;

    vm.close = () => $uibModalInstance.dismiss("cancel");

}

angular.module("firmaren")
    .factory("ModalPaidVideo", ModalPaidVideo)
    .controller("ModalPaidVideoController", ModalPaidVideoController);
}());
