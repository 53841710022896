;(function() {
angular.module("firmaren").directive('lowercased', function() {
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, ngModel) {
            element.bind("blur change input", function () {
                ngModel.$setViewValue($(this).val().toLowerCase());
            });
            element.css("text-transform","lowercase");
        }
    };
});
}());
