;(function() {
/* @ngInject */
function LoginBannerController() {
}

angular.module("firmaren")
    .component("loginBanner", {
        templateUrl: "/app/shared/login-banner/login-banner-view.html",
        controller: LoginBannerController,
        controllerAs: "vm"
    });
}());
