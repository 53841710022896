;(function() {
angular.module("firmaren").directive('redAsterisk', function($timeout) {
    return {
        link: function(scope, elem) {
            $timeout(function() {
                let labels = Array.prototype.slice.call(elem[0].getElementsByTagName('label'), 0);
                labels.forEach(label => {
                    label.innerHTML = label.innerHTML.replace('*', '<span style="color:red;">*</span>');
                });
            });
        }
    };
});
}());
