;(function() {
/* @ngInject */
function ContentController(IndexService, $window, $filter, ModalBanner, $rootScope, Helpers) {

  let vm = this;
  let server = IndexService;

  vm.findByType = findByType;
  vm.findDiscountByType = findDiscountByType;
  vm.setStatsConversion = setStatsConversion;
  vm.setStatsTB = setStatsTB;
  vm.setStatsToMF = setStatsToMF;

  server.getContentByPath({url: $window.location.pathname}).then(onContentByPathLoad);
  server.getPaymentString().then(onPaymentsLoad);
  server.getViralDiscountCode().then(onViralDiscountCodesLoad);
  server.getOrderIdFinishLater().then(onOrderIdFinishLater);

  vm.orderType = [0, 1, 2, 3, 6, 7]; //zaloz zivnost, sro, zmena sro, zivnost, ....
  vm.showBanner = false;
  vm.successPayment;

  function onOrderIdFinishLater(item) {
    if(item)
      vm.orderId = item;
  }

  function onContentByPathLoad(content) {
    vm.content = content;
  }

  function dognetTrack(totalCost, productId, orderId) {
    PostAffTracker.setAccountId('4b7d29fd');
    var sale = PostAffTracker.createSale();

    sale.setTotalCost(totalCost); //dosadiť výšku provízie
    sale.setOrderID(orderId); //dosadiť ID objednávky
    sale.setProductID(productId); //sem vložte názov objednanej služby

    PostAffTracker.register();
  }

  function onPaymentsLoad(payment) {
    vm.paymentString = payment.name;
    vm.paymentOrderType = payment.orderType;
    vm.paymentOrderId = payment.orderId;
    vm.isSingle = payment.isSingle;

    $rootScope.$broadcast('service-load', {orderType: vm.paymentOrderType, isSingle: vm.isSingle});

    let orderType = [
      {totalCost: '13.90', productId: "Založenie SRO"},
      {totalCost: '8.50', productId: "Zmeny v SRO"},
      {totalCost: '4', productId: "Založenie živnosti"},
      {totalCost: '0', productId: "---"},
      {totalCost: '0.90', productId: "Burza firiem"},
      {totalCost: '5', productId: "Založenie občianskeho združenia"},
      {totalCost: '0', productId: "---"},
      {totalCost: '3', productId: "Založenie živnosti pre stálych zákazníkov"},
      {totalCost: '0', productId: "Predaj ready made firmy"},
      {totalCost: '0', productId: "---"},
      {totalCost: '3', productId: "Urob si sám - Založenie občianskeho združenia"}];

    if(vm.paymentOrderType === 3 || vm.paymentOrderType === 6) {
      server.getChangeType({id: vm.paymentOrderId}).then((t) => {
        var price = 0.0;
        if(t === "Zrušenie živnosti")
          price = 1.60;
        else if(t === "Pozastavenie živnosti")
          price = 1.20;
        else price = 1.60;

        dognetTrack(price, t, vm.paymentOrderId);
      });
    } else if(vm.paymentOrderType !== 0 && vm.paymentOrderType !== 2 && vm.paymentOrderType !== 9) {
      dognetTrack(orderType[vm.paymentOrderType].totalCost, orderType[vm.paymentOrderType].productId, vm.paymentOrderId);
    }

    if(vm.paymentString !== "Transakcia prijatá" && vm.paymentString !== "Platba prebehla úspešne.")
      vm.successPayment = false;
    else vm.successPayment = true;

    if ([2, 3, 6, 7, 11].indexOf(vm.paymentOrderType) > -1) {
      server.getHash({id: vm.paymentOrderId}).then((hash) => {
        vm.conversionDocsHref = `/conversion-docs/${hash}`;
      });
    }
  }

  function onViralDiscountCodesLoad(item) {
    if(item)
      vm.viralCode = item;
  }

  function findByType() {

    if(vm.viralCode) {
      return $filter("translate")('viral.' + vm.viralCode.type);
    }
  }

  function findDiscountByType() {
    if(vm.viralCode) {
      return $filter("translate")('viral.discount.' + vm.viralCode.type);
    }
  }

  function setStatsConversion() {
    server.setStats({
      url: "https://www.firmaren.sk/konverzia-dokumentov/krok1/",
      source: Helpers.getUtmSource(vm.paymentOrderType),
      medium: "StrankaPoZaplateniKarta",
      campaign: "Button"
    }).then();
  }

  function setStatsTB() {
    server.setStats({
      url: "https://www.moja.firmaren.sk/zlavy-a-akcie/tatrabanka",
      source: "Firmaren-PoZaplateni",
      medium: "Tatrabanka",
      campaign: "Button"
    }).then();
  }

  function setStatsToMF(campaing) {
    server.setStats({
      url: "https://www.moja.firmaren.sk",
      source: Helpers.getUtmSource(vm.paymentOrderType),
      medium: "StrankaPoZaplateniKarta",
      campaign: campaing
    }).then();
  }
}

angular.module("firmaren")
  .controller("ContentController", ContentController);
}());
