;(function() {
/* @ngInject */
function CustomObjednaneController(CustomPaymentsService, $window) {
    let vm = this;
    let server = CustomPaymentsService;

    vm.onPayClick = onPayClick;
    vm.payment = "movement";
    vm.showMovement = false;

    server.getOrderId().then(onOrderIdLoad);

    function onOrderIdLoad(payment) {
        if(payment) {
            vm.orderId = payment.orderId;
            vm.price = payment.price;
            vm.paymentData = payment;
            vm.invoiceHref = `/invoice/download?orderId=${payment.orderId}`;
            vm.proformaHref = `/invoice/download?proforma=true&orderId=${payment.orderId}`;
            vm.documentsHref = `/objednavka/detail?o=${payment.documentsLink}`;
        } else {
            $window.location = "/";
        }
    }

    function onPayClick() {
        if(vm.payment === "movement") {
            vm.showMovement = true;
        } else {
            server.pay({payment: vm.payment}).then(({success, data, error}) => {
                if (success) {
                    console.log(data);
                    $window.location = data;
                }

            })
        }
    }
}

angular.module("firmaren")
    .controller("CustomObjednaneController", CustomObjednaneController);
}());
