;(function() {
/* @ngInject */
function BusinessSubjectsSummaryTableController() {

}

angular.module("firmaren")
  .component("businessSubjectsSummaryTable", {
    templateUrl: "/app/shared/business-subjects-summary-table/business-subjects-summary-table.html",
    controller: BusinessSubjectsSummaryTableController,
    controllerAs: "vm",
    bindings: {
      selectedSubjects: "=",
      deselect: "=",
      deselectAll: "=",
      select: "=",
      totalPrice: "=",
      founders: "=",
      lang: "="
    }
  });
}());
