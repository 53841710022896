;(function() {
/* @ngInject */
function Cart() {
  return {
    restrict: 'E',
    templateUrl: "/app/shared/cart/cart-view.html",
    scope : {
      cart : "=",
      saveAndContinueFunction: "&",
      nextStep: "=",
      hidePrice: "=",
      withoutTax: "=",
      desc: "=",
      spravnyPoplatok: "="
    }
  };
}

angular.module("firmaren")
  .directive("firmarenCart", Cart);
}());
