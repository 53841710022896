;(function() {
/* @ngInject */
function ModalPersonRemove($uibModal, $rootScope) {
  let person;
  let date;
  let isExecutor;
  return function init(person_, date_, isExecutor_) {
    person = person_;
    date = date_;
    isExecutor = isExecutor_;
    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-person-remove/modal-person-remove.html",
      controller: "ModalPersonRemoveController",
      controllerAs: "vm",
      bindToController: true,
      size: "md",
      backdrop: "static",
      resolve: {
        person: () => person,
        date: () => date,
        isExecutor: () => isExecutor
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) }, person)
  }
}

/* @ngInject */
function ModalPersonRemoveController($uibModalInstance, person, date, isExecutor, $scope) {
  let vm = this;

  vm.date = date;
  vm.isExecutor = isExecutor;
  vm.isDate = !angular.equals(vm.date, {});

  vm.person = {
    fullName: person.fullName,
    idNumber: person.idNumber
  };

  let now = new Date();
  if(!angular.equals(vm.date, {}))
    vm.person.date = vm.date;
  // else vm.person.date = now;
  vm.person.typeChange = 0;

  vm.popupOpened = false;
  vm.format = "EEEE - dd. MM. yyyy";
  vm.dateOptions = {
    showWeeks:false,
    //minDate: now,
    initDate: now
  };

  vm.close = () => $uibModalInstance.dismiss("cancel");

  vm.submit = () => {
    if($scope.executor.$invalid) {
      vm.submitted=true;
    } else {
      $uibModalInstance.close(vm.person);
    }
  }

}

angular.module("firmaren")
  .factory("ModalPersonRemove", ModalPersonRemove)
  .controller("ModalPersonRemoveController", ModalPersonRemoveController);
}());
