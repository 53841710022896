;(function() {
/* @ngInject */
function BurzaFiriemSellController(BurzaFiriemSellService, Helpers, $window, $scope) {
  let vm = this;
  vm.server = BurzaFiriemSellService;

  vm.maxStep = 1;
  vm.onSaveAndContinueClick = onSaveAndContinueClick;
  vm.setStats = setStats;
  vm.showMoreInfo = showMoreInfo;
  vm.changeSort = changeSort;
  vm.scrollTo = Helpers.scrollTo;
  vm.getCampaign = Helpers.getBurzaUtmCampaign;

  vm.totalPrice = 0;

  vm.companies = [];
  vm.allCompanies = [];

  vm.page = {
    numberOfPage: 20,
    page: 0,
    totalElements: 0
  };

  vm.isFirst = true;
  vm.isOpenedFilter = false;

  vm.sorts = [
    "Zoradiť podľa dátumu pridania",
    "Zoradiť podľa ceny",
  ];

  vm.sort = vm.sorts[0];

  initFilter();

  window.showSpinner(4);
  vm.server.getMaxStep().then((ms) => {vm.maxStep = ms; window.hideSpinnerWhenAllResolved();});
  vm.server.getPrice().then((tp) => {vm.totalPrice = tp; window.hideSpinnerWhenAllResolved();});
  vm.server.getPageCompanies(vm.filter).then(onCompaniesLoad);

  vm.parseInt = parseInt;

  $scope.$on('pagination-change-page-burza', function (event, args) {
    vm.server.getPageCompanies(vm.filter).then(onCompaniesLoad);
    vm.scrollTo("scroll-top");
  });

  $scope.$on('pagination-change-offset-burza', function (event, args) {
    vm.server.getPageCompanies(vm.filter).then(onCompaniesLoad);
  });

    $scope.$on('burza-filter-reset', function (event, args) {
        initFilter();
    });

  $scope.$watch('vm.filter', function() {
    if (!vm.isFirst) {
      window.showSpinner(1);
      vm.server.setPage(0).then(_ => {
            vm.server.getPageCompanies(vm.filter).then(onCompaniesLoad);
          }
      );
    } else {
      vm.isFirst = false;
    }
  }, true);

  $scope.$watch('vm.sort', function() {
    changeSort();
  }, true);

  function initFilter() {
    vm.filter = {
      location: "",
      price: {},
      activity: {
        yes: false,
        no: false
      },
      debt: {
        yes: false,
        no: false
      },
      register: {
        yes: false,
        no: false
      },
      year: {
        from0to1: false,
        from2to5: false,
        more: false
      },
      property: {
        yes: false,
        no: false
      },
      sales: {
        from0to5K: false,
        from5Kto100K: false,
        from100KtoMilion: false,
        more: false
      },
      sold: {
        yes: false,
        no: true
      },
      sort: {
        date: true,
        price: false
      }
    };
  }

  function changeSort() {
    if (vm.sort === vm.sorts[0]) {
      vm.filter.sort = {
        date: true,
        price: false
      }
    } else {
      vm.filter.sort = {
        date: false,
        price: true
      }
    }
  }

  function onCompaniesLoad(items) {
    vm.companies = items.content;
    onPageLoad(items.page);
    window.hideSpinnerWhenAllResolved();
  }

  function onPageLoad(items) {
    vm.page = items;
    window.hideSpinnerWhenAllResolved();
  }

  function getParam(q) {
    return (window.location.search.match(new RegExp('[?&]' + q + '=([^&]+)')) || [, null])[1];
  }

  function showMoreInfo(index) {
    return {
      hide_more_info_btn: $(".company_detail_" + index).outerHeight() < 130
    };
  }

  function onSaveAndContinueClick() {
    vm.server.setOrder(vm.order).then(({ success, data }) => {
      if (success) {
        $window.location = "krok2";
      }
      else {
        vm.input.hasError = true;
        vm.input.message = data;
      }
    });
  }

  function setStats(id, name) {
    vm.server.setStatsOfClick({campaign: vm.getCampaign(id, name), medium: "Detail-z-listu", url: "https://www.firmaren.sk/burza-firiem/detail?id=" + id}).then();
  }
}

angular.module("firmaren")
  .controller("BurzaFiriemSellController", BurzaFiriemSellController);
}());
