;(function() {
/* @ngInject */
function UpsellRowController($rootScope, $window) {
    let vm = this;

    vm.setUpsell = setUpsell;

    function setUpsell() {
        vm.upsell = !vm.upsell;
        if(vm.upsell && vm.routing)
            changeLocation();
    }

    function changeLocation() {
        $window.location = vm.routing + '?upsell=' + vm.name;
    }
}

angular.module("firmaren")
    .component("upsellRow", {
        templateUrl: "/app/shared/upsell-row/upsell-row.html",
        controller: UpsellRowController,
        controllerAs: "vm",
        bindings: {
            upsell: "=",
            name: "=",
            img: "=",
            header: "=",
            moreInfo: "=",
            routing: "=",
            price: "=",
            priceName: "=",
            withoutTax: "="
        }
    });
}());
