;(function() {
/* @ngInject */
function ModalPhotoGallery($uibModal, $rootScope) {
    let gallery, index;

    return function init(gallery_, index_) {
        gallery = gallery_;
        index = index_;

        return { add };
    };

    function open(options = {}) {
        return $uibModal.open(angular.extend({
            templateUrl: "/app/shared/modal-photo-gallery/modal-photo-gallery.html",
            controller: "ModalPhotoGalleryController",
            controllerAs: "vm",
            bindToController: true,
            backdropClass: "video-backdrop",
            size: "photo-gallery",
            resolve: {
                gallery: () => gallery,
                index: () => index,
            }
        }, options));
    }

    function add(scope = {}) {
        return open({ scope: angular.extend($rootScope.$new(true), scope) })
    }
}

/* @ngInject */
function ModalPhotoGalleryController($uibModalInstance, gallery, index) {
    let vm = this;

    vm.gallery = gallery;
    vm.index = index;

    vm.close = () => $uibModalInstance.dismiss("cancel");

}

angular.module("firmaren")
    .factory("ModalPhotoGallery", ModalPhotoGallery)
    .controller("ModalPhotoGalleryController", ModalPhotoGalleryController);
}());
