;(function() {
/* @ngInject */
function BurzaContactController($scope, $location, Helpers) {
    let vm = this;

    vm.showForm = true;
    vm.successForm = true;
    vm.showContactInfo = false;
    vm.contact = {};

    vm.sendEmail = sendEmail;
    vm.setStats = setStats;
    vm.getContactText = getContactText;
    vm.scrollTo = Helpers.scrollTo;
    vm.getCampaign = Helpers.getBurzaUtmCampaign;

    function initForm() {
        vm.contact = {
            phoneNumber: {
                prefix: "+421",
                number: ""
            },
            url: $location.absUrl(),
            idOrder: +vm.company.id,
            token: '',
            comment: vm.withoutText ? "" : "Na firmaren.sk som našiel tento inzerát: " + $location.absUrl() + ". Prosím Vás, kontaktujte ma, mám otázky ohľadom tohto inzerátu."
        };
    }

    function getContactText(item) {
        return vm.showContactInfo ? item : (item ? item.slice(0, 4) + "..." : "");
    }

    function sendEmail() {
        if ($scope.burzacontact.$invalid) {
            vm.submitted = true;
        } else {
            grecaptcha.ready(function() {
                grecaptcha.execute('6LcZ0HoaAAAAAGkLyJRbYM59jd6wKCKTaQ3-AmSF', {action: 'submit'}).then(function (token) {
                    if (vm.contact.token === "") {
                        vm.contact.token = token;
                    }
                    vm.contact.cc = vm.company.contact;
                    vm.showForm = false;
                    window.showSpinner(1);
                    vm.server.sendContactMail(vm.contact).then((data) => {
                        if (data.success) {
                            vm.successForm = true;
                            vm.server.setStatsOfClick({
                                campaign: vm.getCampaign(vm.company.id, vm.company.companyName),
                                medium: "Odoslat",
                                url: $location.absUrl()
                            }).then();
                        } else {
                            vm.successForm = false;
                        }
                    }).catch(() => {
                        vm.successForm = false;
                    }).finally(() => {
                        initForm();
                        $scope.burzacontact.$setUntouched();
                        window.hideSpinnerWhenAllResolved();
                        vm.scrollTo("content");
                    });
                });
            });
        }
    }

    function setStats(medium) {
        vm.server.setStatsOfClick({campaign: vm.getCampaign(vm.company.id, vm.company.companyName), medium: medium + "-Kontakt", url: $location.absUrl()}).then();
    }

    setTimeout(() => {
        initForm();
    });
}

angular.module("firmaren")
    .component("burzaContact", {
        templateUrl: "/app/shared/burza-contact/burza-contact.html",
        controller: BurzaContactController,
        controllerAs: "vm",
        bindings: {
            company: "=",
            server: "=",
            reverse: "=",
            withoutText: "="
        }
    });
}());
