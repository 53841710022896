;(function() {
/* @ngInject */
function UpsellsController($scope, $rootScope) {
    let vm = this;

    vm.calcPrice = calcPrice;

    vm.universalServices = ["PoistenieMajetku", "PoistenieZodpovednosti"];

    $scope.$watch('vm.upsells', function(newValue, oldValue) {
        if(newValue && newValue.tb && newValue.tb.active && newValue.tb.agree &&
            oldValue && oldValue.tb && oldValue.tb.active && !oldValue.tb.agree &&
            vm.upsells.tb && vm.upsells.tb.active) {
            let timestamp = Math.floor(new Date().getTime() / 1000);
            vm.upsells.tb.timestampAgree = timestamp;
        }
        vm.service.setUpsell(vm.upsells).then(() => $rootScope.$broadcast('on-price-change'));
    }, true);

    function calcPrice(price) {
        return vm.withoutTax ? price / 1.2 : price;
    }
}

angular.module("firmaren")
    .component("upsells", {
        templateUrl: "/app/shared/upsells/upsells.html",
        controller: UpsellsController,
        controllerAs: "vm",
        bindings: {
            upsells: "=",
            intermediateStep: "=",
            type: "=",
            form: "=",
            routing: "=",
            service: "=",
            withoutTax: "="
        }
    });
}());
