;(function() {
angular.module("firmaren").directive("typeaheadWatchChanges", function() {
  return {
    require: ["ngModel"],
    link: function(scope, element, attr, ctrls) {
      scope.$watch('country', function(value) {
        ctrls[0].$setViewValue(value);
        console.log('value', value);
        console.log('$setViewValue', ctrls[0].$setViewValue.$viewValue);
      });
    }
  };
});
}());
