;(function() {
/* @ngInject */
function RegisterDateController($timeout) {
    let vm = this;

    vm.changeRegDate = changeRegDate;

    let now = new Date();
    now.setDate(now.getDate() + 1);

    vm.popupOpened = false;
    vm.untilPopupOpened = false;
    vm.format = "EEEE - dd. MM. yyyy";
    vm.dateOptions = {
        showWeeks: false,
        minDate: now,
        initDate: now
    };

    vm.untilDateOptions = {};

    // until
    setDates(now);

    function setDates(date) {
        vm.untilDateOptions = {
            showWeeks: false,
            minDate: date,
            initDate: date
        };
    }

    function changeRegDate() {
        let date = angular.copy(vm.model.date);
        setDates(date);
    }
}

angular.module("firmaren")
    .component("registerDate", {
        templateUrl: "/app/shared/register-date/register-date.html",
        controller: RegisterDateController,
        controllerAs: "vm",
        bindings: {
            model: "=",
            submitted: "=",
            form: "=",
            label: "=",
            until: "=",
            hideAsap: "=",
            hideSpecifically: "="
        }
    });
}());
