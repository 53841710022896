;(function() {
/* @ngInject */
function VirtualneSidloController(VirtualneSidloListService, IndexService, removeDiacritics, $rootScope, $scope) {
  let vm = this;

  vm.server = VirtualneSidloListService;
  let service = IndexService;

  vm.isOpenedFilter = false;
  vm.showDropdown = false;

  vm.page = {
    numberOfPage: 20,
    page: 1,
    totalElements: 1600
  };

  vm.isFirst = true;

  vm.seats = [];

  vm.cities = [];

  vm.parseInt = parseInt;
  vm.setStats = setStats;

  initFilter();

  window.showSpinner(3);
  vm.server.getPageSeats(vm.filter).then(onSeatsLoad);
  vm.server.getLocations().then(onLocationsLoad);

  $scope.$on('virtualne-sidlo-filter-reset', function (event, args) {
    initFilter();
    initLocation();
  });

  $scope.$on('virtualne-sidlo-location-reset', function (event, args) {
    initLocation();
  });

  $scope.$on('virtualne-sidlo-update-selected', function (event, args) {
    updateSelected();
  });

  $scope.$on('pagination-change-page-sidlo', function (event, args) {
    vm.server.getPageSeats(vm.filter).then(onSeatsLoad);
    scrollTo("scroll-top");
  });

  $scope.$on('pagination-change-offset-sidlo', function (event, args) {
    vm.server.getPageSeats(vm.filter).then(onSeatsLoad);
  });

  $scope.$watch('vm.filter', function() {
    if (!vm.isFirst) {
      window.showSpinner(1);
      vm.server.getPageSeats(vm.filter).then(onSeatsLoad);
    } else {
      vm.isFirst = false;
    }
  }, true);

  function sortLocation() {
    vm.location.sort((a, b) => a.name.localeCompare(b.name));
    vm.location.forEach(region => {
      region.children.sort((a, b) => a.name.localeCompare(b.name));
      region.children.forEach(district => {
        if(district.children.length){
          district.children.sort((a, b) => a.name.localeCompare(b.name));
        }
      });
    });
  }

  function onLocationsLoad(items) {
    vm.location = items;

    sortLocation();
    initLocation();
    getCities();

    window.hideSpinnerWhenAllResolved();
  }

  function onSeatsLoad(items) {
    vm.seats = items.content;
    onPageLoad(items.page);
    window.hideSpinnerWhenAllResolved();
  }

  function onPageLoad(items) {
    vm.page = items;
    window.hideSpinnerWhenAllResolved();
  }

  function initLocation() {
    vm.filter.location = "";
    vm.location.forEach((region) => {
      region.selected = false;
      if (region.children) {
        region.children.forEach((district) => {
          district.selected = false;
          if (district.children) {
            district.children.forEach((urbanDistrict) => {
              urbanDistrict.selected = false;
            });
          }
        });
      }
    });
  }

  function updateSelected() {
    let locationArr = [];
    vm.filter.location = "";
    vm.location.forEach((region) => {
      if (region.children.length) {
        region.selected = region.children.every((district) => district.selected);
        if (region.selected) {
          locationArr.push(region.name + " kraj");
        }
        else {
          region.children.forEach((district) => {
            if (district.children.length) {
              district.selected = district.children.every((urbanDistrict) => urbanDistrict.selected);
              if (district.selected) {
                locationArr.push(district.name);
              }
              else {
                district.children.forEach((urbanDistrict) => {
                  if (urbanDistrict.selected) {
                    locationArr.push(urbanDistrict.name);
                  }
                });
              }
            }
            else if (district.selected) {
              locationArr.push(district.name);
            }
          });
        }
      }
      else if (region.selected) {
        locationArr.push(region.name + " kraj");
      }
    });

    vm.filter.location = locationArr.join(", ")
  }

  function getCities() {
    let cities = ['Bratislava', 'Trnava', 'Nitra', 'Trenčín', 'Žilina', 'Banská Bystrica', 'Prešov', 'Košice']; // sort order
    if (vm.location) {
      vm.location.forEach((region) => {
        vm.cities = vm.cities.concat(region.children.filter((e) => cities.includes(e.name)).map((e) => {
          region.url = "/assets/images/cities/" + removeDiacritics(e.name.replace(' ', '-')) + ".jpg";
          region.sortId = cities.indexOf(e.name);
          region.cityName = e.name;

          return region;
        }));
      });

      vm.cities.sort((a,b) => a.sortId - b.sortId);
    }
  }

  function initFilter() {
    vm.filter = {
      location: "",
      services: {
        virtual: true,
        coworking: false,
        office: false,
        shipment: false,
        photo: false,
        notification: false,
        // personalCollection: false,
        // online: false,
        scan: false,
        send: false,
        englishSupport: false,
        withoutCommitment: false,
      }
    };

    $rootScope.$broadcast('reset-selected-city');
  }

  function setStats() {
    service.setStats({
      url: "https://www.firmaren.sk/zalozenie-firmy/krok1",
      source: "Firmaren-VirtualneSidlo",
      medium: "ZoznamSidiel",
      campaign: "Button"
    }).then();
  }
}

angular.module("firmaren")
  .controller("VirtualneSidloController", VirtualneSidloController);
}());
