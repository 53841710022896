;(function() {
/* @ngInject */
function ReadyMadeObjednaneController(ReadyMadeObjednaneService, $window, $filter, Helpers) {
    let vm = this;
    let server = ReadyMadeObjednaneService;

    vm.onPayClick = onPayClick;
    vm.generateDocs = generateDocs;
    vm.getFileName = Helpers.getFileName;
    vm.payment = "movement";
    vm.showMovement = false;
    vm.showLoading = false;
    vm.isUnzipFiles = true;
    vm.showProforma = true;
    vm.downloadLabel = $filter("translate")('payments.documents.general');

    server.getPaymentInfo().then(onPaymentInfoLoad);

    let dataLayerStorage = $window.localStorage.getItem('dataLayer');
    $window.localStorage.removeItem('dataLayer');
    var dataLayer = $window.dataLayer = $window.dataLayer || [];
    if (dataLayerStorage)
        dataLayer.push(JSON.parse(dataLayerStorage));

    function onPaymentInfoLoad(payment) {
        if(payment) {
            vm.orderId = payment.orderId;
            vm.price = payment.price;
            vm.paymentData = payment;
            vm.invoiceHref = `/invoice/download?orderId=${payment.orderId}`;
            vm.proformaHref = `/invoice/download?proforma=true&orderId=${payment.orderId}`;
            vm.documentsHref = `/objednavka/detail?o=${payment.documentsLink}`;
        } else {
            $window.location = "krok1";
        }
    }

    function generateDocs() {
        vm.showLoading = true;
        server.regenerateDocs().then(() => {
            server.getPaymentInfo().then((p) => {
                if(p.documentsLink) {
                    let url = '/objednavka/detail?o=' + p.documentsLink;
                    $window.location = url;
                }
            });
            vm.showLoading = false;
        });
    }

    function onPayClick() {
        if(vm.payment === "movement") {
            if (vm.paymentData.hash && vm.paymentData.hash !== "") {
                let url = '/bank-transfer/' + vm.paymentData.hash;
                $window.location = url;
            } else vm.showMovement = true;
        } else {
            server.pay({payment: vm.payment}).then(({success, data, error}) => {
                if (success) {
                    console.log(data);
                    $window.location = data;
                }

            })
        }
    }
}

angular.module("firmaren")
    .controller("ReadyMadeObjednaneController", ReadyMadeObjednaneController);
}());
