;(function() {
/* @ngInject */
function formatTextFilter() {
    return function (input) {
        // Rozdelíme text podľa čiariek a pridáme každý segment do spanu
        var segments = input.split(',').map(function (segment) {
            return '<span class="highlight-block">' + segment.trim() + '</span>';
        });
        // Spojíme segmenty späť do jedného reťazca
        return segments.join('');
    }
}
angular.module("firmaren")
    .filter("formatTextFilter", formatTextFilter);
}());
