;(function() {
/* @ngInject */
function GoalsResultsTableController(normalize, $filter, $timeout) {
  let vm = this;

  vm.foundSubstr = foundSubstr;
  vm.selectOrDeselectGoal = selectOrDeselectGoal;
  vm.isSelected = isSelected;

  let promise;
  
  function isSelected(goal) {
    let eq = $filter('filter')(vm.selectedGoals, function (g) {
      return g.goal === goal.goal;
    });
    return eq.length > 0;
  }
  
  function showPopup(event, isSelected, isRemove) {
    if(event) {
      $('#info_popup').removeClass("red_popup");
      $('#info_popup_arrow').removeClass("red_popup");
      
      if(isSelected || isRemove) {
        $('#info_popup').addClass("red_popup");
        $('#info_popup_arrow').addClass("red_popup");
        if(isSelected && !isRemove)
          $('#info_popup_text').text($filter("translate")('zalozenieOZ.krok1.goals.popup.isSelected'));
        else $('#info_popup_text').text($filter("translate")('zalozenieOZ.krok1.goals.popup.remove'));
      } else $('#info_popup_text').text($filter("translate")('zalozenieOZ.krok1.goals.popup.add'));

      if(promise)
        $timeout.cancel(promise);

      if (window.matchMedia('(min-width: 45em)').matches) {
        if(isSelected && !isRemove)
          $('#info_popup').css({'top': (event.clientY - ($("#goals_search").offset().top - (window.scrollY || window.pageYOffset || document.body.scrollTop)) - 22) + 'px', 'left': event.clientX - $("#goals_search").offset().left + 'px'});
        else $('#info_popup').css({'top': (event.clientY - ($("#goals_search").offset().top - (window.scrollY || window.pageYOffset || document.body.scrollTop)) - 22) + 'px', 'left': event.target.parentNode.parentNode.parentNode.clientWidth + 'px'});
      }

      $('#info_popup').show();

      promise = $timeout(function () {
        $('#info_popup').hide();
      }, 3000);
    }
  }
  
  function selectOrDeselectGoal(goal, event, isRemove) {
    if(isSelected(goal)) {
      if(isRemove)
        vm.selectedGoals = $filter('filter')(vm.selectedGoals, function (g) {
          return g.goal !== goal.goal
        });
      showPopup(event, true, isRemove);
    } else {
      vm.errorGoals = false;
      vm.selectedGoals.push(goal);
      if(vm.isSearchBar)
        $('#btn_search_bar').select();
      showPopup(event, false, isRemove);
    }
  }

  function foundSubstr(str) {
    let searchStr = normalize(vm.results[vm.results.length-1].goal);
    let position = normalize(str).indexOf(searchStr);

    if(position !== -1) {
      var substr = str.substring(position, position + searchStr.length);
      return str.replace(substr, "<span class='found'>" + substr + "</span>");
    } else return str;
  }
}

angular.module("firmaren")
  .component("goalsResultsTable", {
    templateUrl: "/app/shared/goals-results-table/goals-results-table.html",
    controller: GoalsResultsTableController,
    controllerAs: "vm",
    bindings: {
      results: "=",
      selectedGoals: "=",
      isSearchBar: "=",
      errorGoals: "="
    }
  });
}());
