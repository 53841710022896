;(function() {
/* @ngInject */
function ModalVideo($uibModal, $rootScope) {
  let video;
  return function init(video_) {
    video = video_;
    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-video/modal-video.html",
      controller: "ModalVideoController",
      controllerAs: "vm",
      bindToController: true,
      size: "sm",
      backdropClass: "video-backdrop",
      resolve: {
        video: () => video
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) , video})
  }
}

/* @ngInject */
function ModalVideoController($uibModalInstance, video, $sce) {
  let vm = this;

  vm.video = video;

  vm.trustSrc = function(src) {
    return $sce.trustAsResourceUrl(src);
  };


  vm.close = () => $uibModalInstance.dismiss("cancel");

}

angular.module("firmaren")
  .factory("ModalVideo", ModalVideo)
  .controller("ModalVideoController", ModalVideoController);
}());
