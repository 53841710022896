;(function() {
/* @ngInject */
function PasswordController($rootScope) {
    let vm = this;
    vm.changePasswordInputType = changePasswordInputType;
    vm.onPasswordChange = onPasswordChange;

    vm.pattern = $rootScope.patterns.password;
    vm.passwordInputType = 'password';

    function changePasswordInputType() {
        return vm.passwordInputType === 'password' ? vm.passwordInputType = 'text' : vm.passwordInputType = 'password';
    }

    function onPasswordChange() {
        $rootScope.$broadcast(vm.change, vm.model);
    }

}

angular.module("firmaren")
    .component("password", {
        templateUrl: "/app/shared/password/password.html",
        controller:PasswordController,
        controllerAs: "vm",
        bindings: {
            label: "=",
            model: "=",
            submitted: "=",
            form: "=",
            disabled: "=",
            tooltip: "=",
            spinner: "=",
            showPasswordIcon: "=",
            fullSize: "=",
            change: "=",
            validator: "=",
            ref: "=",
            label: "="
        }
    });
}());
