;(function() {
/* @ngInject */
function UnsubscribeController(UserService, $rootScope, $window, routes) {

    let vm = this;
    let server = UserService;

    window.showSpinner(1);
    server.getUserId().then(onUserIdLoad);

    function onUserIdLoad(item) {
        if (!item) {
            $window.location = routes.index;
        }
        window.hideSpinnerWhenAllResolved();
    }

}

angular.module("firmaren")
    .controller("UnsubscribeController", UnsubscribeController);
}());
