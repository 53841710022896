;(function() {
/* @ngInject */
function ModalIdle($uibModal, $rootScope) {

    return function init() {

        return { add };
    };

    function open(options = {}) {
        return $uibModal.open(angular.extend({
            templateUrl: "/app/shared/modal-idle/modal-idle.html",
            controller: "ModalIdleController",
            controllerAs: "vm",
            bindToController: true,
            backdrop: "static",
            size: "md"
        }, options));
    }

    function add(scope = {}) {
        return open({ scope: angular.extend($rootScope.$new(true), scope) })
    }
}

/* @ngInject */
function ModalIdleController($window) {
    let vm = this;

    vm.close = () => $window.location.reload();

}

angular.module("firmaren")
    .factory("ModalIdle", ModalIdle)
    .controller("ModalIdleController", ModalIdleController);
}());
