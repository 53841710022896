;(function() {
/* @ngInject */
function BurzaFiriemDetailController(BurzaFiriemDetailService, $location, $window, getUrlParams, Helpers) {
    let vm = this;
    vm.server = BurzaFiriemDetailService;

    vm.getCampaign = Helpers.getBurzaUtmCampaign;

    vm.anotherCompanies = {};

    let loadType = $window.performance.navigation.type;

    let param = getUrlParams().id;

    if (param) {
        vm.companyId = param;
        vm.server.getDetail(vm.companyId.toString()).then(onDetailLoad);
        vm.server.getRandomCompanies(vm.companyId.toString()).then(data => vm.anotherCompanies = data);
    } else {
        $window.location = "kupit";
    }



    vm.company = {};

    function onDetailLoad(item) {
        if (item) {
            vm.company = item;

            if(loadType === 0) {
                setStats(vm.companyId, vm.company.companyName);
            }
        } else $window.location = "kupit";
    }

    function setStats(id, name) {
        vm.server.setStatsOfClick({campaign: vm.getCampaign(id, name), medium: "Detail", url: "https://www.firmaren.sk/burza-firiem/detail?id=" + id}).then();
    }
}

angular.module("firmaren")
    .controller("BurzaFiriemDetailController", BurzaFiriemDetailController);
}());
