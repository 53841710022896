;(function() {
/* @ngInject */
function ModalShareTransfer($uibModal, $rootScope) {
  let server, currencies, founder, allAssign, basicCapital, cities, countries, isRemove;

  return function init(server_, currencies_, founder_, allAssign_, basicCapital_, cities_, countries_, isRemove_) {
    server = server_;
    currencies = currencies_;
    founder = founder_;
    allAssign = allAssign_;
    basicCapital = basicCapital_;
    cities = cities_;
    countries = countries_;
    isRemove = isRemove_;

    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-share-transfer/modal-share-transfer.html",
      controller: "ModalShareTransferController",
      controllerAs: "vm",
      bindToController: true,
      size: "lg",
      backdrop: "static",
      resolve: {
        server: () => server,
        currencies: () => currencies,
        founder: () => founder,
        allAssign: () => allAssign,
        basicCapital: () => basicCapital,
        cities: () => cities,
        countries: () => countries,
        isRemove: () => isRemove
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) })
  }
}

/* @ngInject */
function ModalShareTransferController($scope, server, $filter, $window, $uibModalInstance, currencies, founder, basicCapital, allAssign, cities, countries, isRemove, Helpers) {
  let vm = this;

  vm.currencies = currencies;
  vm.genders = ["male", "female"];
  vm.cities = cities;
  vm.countries = countries;

  //vm.founder = founder;
  vm.isRemove = isRemove;

  if(!angular.isArray(founder)) {
    vm.founder = {
      fullName: founder.fullName,
      depositAmount: founder.depositAmount
    };
  } else {
    var name = "";
    var deposit = 0;
    founder.forEach(f => {
      if(name === "")
        name = f.fullName;
      else name = name + " + " + f.fullName;

      deposit = deposit + parseInt(f.depositAmount);
    });
    vm.founder = {
      fullName: name,
      depositAmount: deposit
    };
  }

  vm.allAssign = allAssign;
  vm.allAssign.push({
    fullName: $filter('translate')('sro.zmena.krok1.shareTransfer.modal.otherPerson'),
    index: -1
  });
  vm.basicCapital = basicCapital;
  vm.shareTransfer = {
    price: 1,
    currency: vm.currencies[0].name
  };

  if(vm.isRemove)
    vm.shareTransfer.type = "all";
  else vm.shareTransfer.type = "custom";

  vm.otherPerson = {
    type: "fo",
    dateOfBirth: {},
    idNumber: {},
    address: {
      country: vm.countries[0]._2
    }
  };

  vm.assign = vm.allAssign[0];
  vm.isCollapsed = {
    assign: true
  };
  vm.shareTransfer.newPrice = vm.assign.depositAmount;
  vm.shareTransfer.newPercent = getPercent(vm.assign.depositAmount);

  if(vm.allAssign.length === 1)
    vm.showOtherPerson = true;
  else vm.showOtherPerson = false;

  let minDate = new Date();

  vm.days = createArray(1,31, false);
  vm.months = createArray(1,12, false);
  vm.years = createArray(1900, minDate.getFullYear() - 18, true);

  vm.getPercent = getPercent;
  vm.onAssignClick = onAssignClick;
  vm.setAssign = setAssign;
  vm.calculate = calculate;
  vm.onClick = onClick;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.onElementBlur = Helpers.onElementBlur;
  vm.scrollTo = Helpers.scrollInModalTo;
  vm.getFullName = Helpers.getFullName;
  vm.onIcoChange = onIcoChange;
  vm.onZipCodeChange = onZipCodeChange;

  function onZipCodeChange($item) {
    vm.otherPerson.address.zipCode = $item && $item._3;
  }

  function onIcoChange() {
    server.getFinstatInfo({name: vm.otherPerson.ico}).then(data => {
      if (data) {
        vm.otherPerson.fullName = data.name;
        vm.otherPerson.address = data.address;
        vm.otherPerson.address.country = vm.countries[0]._2;
      }
    })
  }

  function createArray(start, end, reverse) {
    let array = [];
    for(var i = start; i <= end; i++) {
      array.push(i);
    }
    if(reverse)
      return array.reverse();
    else return array;
  }

  function onClick(item, elem) {
    if (elem === "day") {
      vm.otherPerson.dateOfBirth.day = item;
      vm.mouseoverDay = false;
    } else if (elem === "month") {
      vm.otherPerson.dateOfBirth.month = item;
      vm.mouseoverMonth = false;
    } else if (elem === "year") {
      vm.otherPerson.dateOfBirth.year = item;
      vm.mouseoverYear = false;
    } else if (elem === "currency") {
      vm.shareTransfer.currency = item;
      vm.mouseoverCurrency = false;
    }

    onIdNumberChange();
  }

  function onIdNumberChange() {
    if (vm.otherPerson) {
      if (vm.otherPerson.gender) {
        vm.otherPerson.idNumber.prefix = Helpers.calculateIdNumber(vm.otherPerson.dateOfBirth, vm.otherPerson.gender);
      } else {
        vm.onElementFocus("gender");
        vm.onElementBlur("gender");
        vm.scrollTo("gender");
      }
    }
  }

  function calculate() {
    vm.shareTransfer.percent = $filter('number')(getPercent(vm.shareTransfer.euro), 2);
    vm.shareTransfer.newPrice = parseFloat(vm.assign.depositAmount) + parseFloat(vm.shareTransfer.euro);
    vm.shareTransfer.newPercent = getPercent(vm.shareTransfer.newPrice);
  }

  function setAssign(assign) {
    vm.assign = assign;
    vm.isCollapsed.assign = true;

    if(assign.index === -1)
      vm.showOtherPerson = true;
    else vm.showOtherPerson = false;
  }

  function onAssignClick() {
    vm.isCollapsed.assign = !vm.isCollapsed.assign;
  }

  function getPercent(item) {
    return parseFloat(item)/parseFloat(vm.basicCapital)*100;
  }

  vm.close = () => $uibModalInstance.dismiss("cancel");

  vm.submit = () => {
    if ($scope.shareTransfer.$invalid) {
      vm.submitted = true;
    } else {
      if(vm.shareTransfer.type === "all") {
        vm.shareTransfer.euro = vm.founder.depositAmount;
        calculate();
      }
      if(vm.assign.index === -1) {
        vm.otherPerson.depositAmount = vm.shareTransfer.euro;
        vm.shareTransfer.newPrice = vm.shareTransfer.euro;
        vm.shareTransfer.newPercent = vm.shareTransfer.percent;
        if (!vm.otherPerson.ico)
          vm.otherPerson.fullName = vm.getFullName(vm.otherPerson);
      }
      vm.results = {
        assign: vm.assign.index,
        otherPerson: vm.otherPerson,
        shareTransfer: vm.shareTransfer
      };
      $uibModalInstance.close(vm.results);
    }
  };

}

angular.module("firmaren")
  .factory("ModalShareTransfer", ModalShareTransfer)
  .controller("ModalShareTransferController", ModalShareTransferController);
}());
