;(function() {
/* @ngInject */
function Errors() {
  return {
    restrict: "E",
    templateUrl: "/app/shared/errors/error-view.html",
    scope: {
      element: "=",
      focus: "=",
      min: "=",
      max: "=",
      submitted: "=",
      exception: "=",
      simpleSelect: "="
    }
  };
}

angular.module("firmaren")
  .directive("firmarenErrors", Errors);
}());
