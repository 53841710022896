;(function() {
/* @ngInject */
function BusinessSubjectsSearchController(ModalBusinessSubjects, normalize, _, $window, $compile, $scope, $document, $filter, $anchorScroll, $location) {
  let vm = this;

  vm.input = { value: "", isFocused: false };
  vm.onFocus = onFocus;
  vm.onBlur = onBlur;
  vm.onChange = onChange;
  vm.onKeydown = onKeydown;
  vm.onListClick = onListClick;
  vm.results = [];
  vm.resultsNonEmpty = resultsNonEmpty;
  vm.clearText = clearText;

  var content = $filter("translate")("zalozenieZivnosti.krok1.placeholder");
  vm.icoPlaceholder = "";

  var i=0;

  var timer = setInterval(function(){
    if(i<content.length)
      vm.icoPlaceholder += content[i];
    else clearInterval(timer);
    i++;
    $scope.$apply();
  }, 100);

  let body = $document.find("body").eq(0);
  let bgElem = $compile("<div class='modal-backdrop' ng-show='vm.input.isFocused' ng-click='vm.onBlur()'></div>")($scope);
  body.append(bgElem);

  function clearText() {
    vm.input.value = "";
    let element = $window.document.getElementById('btn_search_bar');
    if(element)
      element.focus();
    filter();
  }

  function onFocus(scrollLocation) {
    vm.input.isFocused = true;
    body.addClass("modal-open modal-open-without-fixed");

    let offsetScroll = 0;
    if (window.matchMedia('(min-width: 45em)').matches)
      offsetScroll = 170;
    else offsetScroll = 50;

    $('html, body').animate({
      scrollTop: $("#" + scrollLocation).offset().top - offsetScroll
    }, 500);
  }

  function onBlur() {
    vm.subjectId = 0;

    for(let i = 0; i < vm.results.length; i++) {
      if(vm.results[i].isShownForm)
        vm.subjectId = vm.results[i].id;
    }

    if(vm.subjectId !== 0) {
      $("#errorObject-" + vm.subjectId).show();
      $('.result_tbl_scroll').animate({
        scrollTop: $("#object-" + vm.subjectId)[0].getBoundingClientRect().top - $(".result_tbl")[0].getBoundingClientRect().top - 100
      }, 500);
    } else {
      vm.input.isFocused = false;
      body.removeClass("modal-open modal-open-without-fixed");
    }
  }

  function onChange() {
    filter();
  }

  function onKeydown(e) {
    if (e.keyCode == 27) {
      vm.input.isFocused = false;
    }
  }

  function onListClick() {
    ModalBusinessSubjects.open({
      subjectGroups: vm.subjectGroups,
      select: vm.select,
      deselect: vm.deselect,
      isSelected: vm.isSelected,
      founders: vm.founders,
      selected: vm.selected,
      isChangeCompany: vm.isChangeCompany,
      lang: vm.lang
    });
  }

  function resultsNonEmpty() {
    return !_.isEmpty(vm.results);
  }

  function filter() {
    let v = normalize(vm.input.value);
    if (v !== "") {
      vm.results = _(vm.subjects)
        .filter(s => {
          /*if(normalize(s.name).includes(v)) {
            let position = normalize(s.name).indexOf(v);
            var substr = s.name.substring(position, position + v.length)
            s.name = s.name.replace(substr, "<b>" + substr + "</b>");
            return true;
          } else return false;*/
          return (
            _.includes(vm.lang === 'en' ? normalize(s.nameEN) : normalize(s.name), v) ||
            _.includes(normalize(s.tags), v) //||
            // _.includes(normalize(s.content), v)
          );
        })
        .take(9)
        .value();

        if(vm.results.length < 9) {
          let resContent = _(vm.subjects)
            .filter(s => {
              return (
                _.includes(vm.lang === 'en' ? normalize(s.contentEN) : normalize(s.content), v)
              );
            })
            .take(9)
            .value();

          vm.results = vm.results.concat(resContent);
          let removeDuplicates = new Set(vm.results);
          vm.results = Array.from(removeDuplicates);

          vm.results = vm.results.slice(0, 9);
        }
      let all = vm.selected ? vm.subjects.concat(vm.selected) : vm.subjects;

      let id =  _.min(_.map(all, function(e){return e.id}));

      vm.results.push({id: id-1,
        name: vm.input.value,
        type: "3",
        tags:"",
        price:0.0,
        category:"",
        category2:"",
        category3: "",
        content:"",
        packageTags: "",
        nameEN: vm.input.value,
        contentEN: ""});
    }
    else {
      vm.results = [];
    }
  }
}

angular.module("firmaren")
  .component("businessSubjectsSearch", {
    templateUrl: "/app/shared/business-subjects-search/business-subjects-search.html",
    controller: BusinessSubjectsSearchController,
    controllerAs: "vm",
    bindings: {
      subjects: "=",
      subjectGroups: "=",
      select: "=",
      deselect: "=",
      isSelected: "=",
      selected: "=",
      founders: "=",
      isChangeCompany: "=",
      lang: "="
    }
  });
}());
