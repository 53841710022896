;(function() {
/* @ngInject */
function DateofbirthValidator() {
  return {
    require: "ngModel",
    restrict: "A",
    scope: {
      otherModelValue: "=dateofbirthValidator"
    },
    link: function (scope, element, attrs, ctrl) {
      ctrl.$validators.dateofbirth = validateDateOfBirth;

      function validateDateOfBirth() {
        let day = element.parent().parent().children(":eq(0)").children(":eq(0)").val();
        let month = element.parent().parent().children(":eq(1)").children(":eq(0)").val();
        let year = element.parent().parent().children(":eq(2)").children(":eq(0)").val();

        if(day && month && year) {
          let dateOfBirth = new Date(year, month - 1, day);
          let minDate = new Date();
          
          if (Object.prototype.toString.call(dateOfBirth) === "[object Date]") {
            if(scope.otherModelValue && dateOfBirth < minDate)
              return true;
            else if(scope.otherModelValue)
              return false;

            minDate.setFullYear(minDate.getFullYear() - 18);

            if (dateOfBirth > minDate)
              return false;
            if (year != dateOfBirth.getFullYear()) {
              return false;
            }
            if (month - 1 != dateOfBirth.getMonth()) {
              return false;
            }
            return day == dateOfBirth.getDate();
          }
          else {
            return false;
          }
        } else return true;
      }
    }
  };
}

angular.module("firmaren")
  .directive("dateofbirthValidator", DateofbirthValidator);
}());
