;(function() {
/* @ngInject */
function ModalTransferCompany($uibModal, $rootScope) {

    let server, currencies, founder, cities, countries, transferType, basicCapital;

    return function init(server_, currencies_, founder_, cities_, countries_, transferType_, basicCapital_) {
        server = server_;
        currencies = currencies_;
        founder = founder_;
        cities = cities_;
        countries = countries_;
        transferType = transferType_;
        basicCapital = basicCapital_;

        return { add };
    };

    function open(options = {}) {
        return $uibModal.open(angular.extend({
            templateUrl: "/app/shared/modal-transfer-company/modal-transfer-company.html",
            controller: "ModalTransferCompanyController",
            controllerAs: "vm",
            bindToController: true,
            size: "lg",
            backdrop: "static",
            resolve: {
                server: () => server,
                currencies: () => currencies,
                founder: () => founder,
                cities: () => cities,
                countries: () => countries,
                transferType: () => transferType,
                basicCapital: () => basicCapital
            }
        }, options));
    }

    function add(scope = {}) {
        return open({ scope: angular.extend($rootScope.$new(true), scope) })
    }
}

/* @ngInject */
function ModalTransferCompanyController($scope, $interval, $window, $uibModalInstance, Helpers, server, currencies,
                                        founder, cities, countries, transferType, $filter, basicCapital) {
    let vm = this;

    vm.currencies = currencies;
    vm.genders = ["male", "female"];
    vm.cities = cities;
    vm.countries = countries;
    vm.transferType = transferType;
    vm.basicCapital = basicCapital;
    vm.sumOfShareTransferError = false;

    vm.persons = [];

    if(founder && founder.size > 0) {
        vm.otherPerson = angular.copy(founder);
    } else {
        vm.otherPerson = {
            type: "fo",
            dateOfBirth: {},
            idNumber: {},
            address: {
                country: vm.countries[0]._2
            }
        }
    }

    vm.persons.push({
        otherPerson: vm.otherPerson,
        shareTransfer: {
            price: 1,
            currency: vm.currencies[0].name
        }
    });

    vm.transferCompany = {
        type: vm.transferType,
        typeAll: undefined
    }

    let minDate = new Date();

    vm.days = createArray(1,31, false);
    vm.months = createArray(1,12, false);
    vm.years = createArray(1900, minDate.getFullYear() - 18, true);

    vm.onClick = onClick;
    vm.onElementFocus = Helpers.onElementFocus;
    vm.onElementBlur = Helpers.onElementBlur;
    vm.scrollTo = Helpers.scrollInModalTo;
    vm.getFullName = Helpers.getFullName;
    vm.onIcoChange = onIcoChange;
    vm.onResetCountry = onResetCountry;
    vm.addPerson = addPerson;
    vm.calculate = calculate;
    vm.calculateSumOfShareTransfer = calculateSumOfShareTransfer;

    $scope.$watch('vm.transferCompany.type', function () {
        if(vm.transferCompany.type === 'custom')
            $uibModalInstance.close("share-transfer");
    }, true);

    function addPerson() {
        vm.persons.push({
            otherPerson: {
                type: "fo",
                dateOfBirth: {},
                idNumber: {},
                address: {
                    country: vm.countries[0]._2
                }
            }   ,
            shareTransfer: {
                price: 1,
                currency: vm.currencies[0].name
            }
        });
    }

    function getPercent(item) {
        return parseFloat(item)/parseFloat(vm.basicCapital)*100;
    }

    function calculate(index) {
        vm.persons[index].shareTransfer.percent = $filter('number')(getPercent(vm.persons[index].shareTransfer.euro), 2);

        if(vm.transferCompany.typeAll === "more") {
            let sumOfShareTransfers = 0;
            vm.persons.forEach(person => {
                if(person.shareTransfer.euro)
                    sumOfShareTransfers = sumOfShareTransfers + parseInt(person.shareTransfer.euro);
            });

            if(sumOfShareTransfers === vm.basicCapital)
                vm.sumOfShareTransferError = false;
        }
    }

    function onIcoChange(index) {
        server.getFinstatInfo({name: vm.persons[index].otherPerson.ico}).then(data => {
            if (data) {
                vm.persons[index].otherPerson.fullName = data.name;
                vm.persons[index].otherPerson.address = data.address;
                vm.persons[index].otherPerson.address.country = vm.countries[0]._2;
            }
        })
    }

    function createArray(start, end, reverse) {
        let array = [];
        for(var i = start; i <= end; i++) {
            array.push(i);
        }
        if(reverse)
            return array.reverse();
        else return array;
    }

    function onClick(item, elem, index) {
        if (elem === "day") {
            vm.persons[index].otherPerson.dateOfBirth.day = item;
            vm.persons[index].otherPerson.mouseoverDay = false;
        } else if (elem === "month") {
            vm.persons[index].otherPerson.dateOfBirth.month = item;
            vm.persons[index].otherPerson.mouseoverMonth = false;
        } else if (elem === "year") {
            vm.persons[index].otherPerson.dateOfBirth.year = item;
            vm.persons[index].otherPerson.mouseoverYear = false;
        } else if (elem === "currency") {
            vm.persons[index].shareTransfer.currency = item;
            vm.persons[index].mouseoverCurrency = false;
        }

        onIdNumberChange(index);
    }

    function onIdNumberChange(index) {
        if (vm.persons[index].otherPerson) {
            if (vm.persons[index].otherPerson.gender) {
                vm.persons[index].otherPerson.idNumber.prefix = Helpers.calculateIdNumber(vm.persons[index].otherPerson.dateOfBirth, vm.persons[index].otherPerson.gender);
            } else {
                vm.onElementFocus("gender" + index);
                vm.onElementBlur("gender" + index);
                vm.scrollTo("gender" + index);
            }
        }
    }

    function onResetCountry(index) {
        vm.persons[index].otherPerson.address.country = "";
        $interval(function() {
            let element = $window.document.getElementById("address.country");
            if(element)
                element.focus();
        }, 100, 1);
    }

    function calculateSumOfShareTransfer() {
        let sumOfShareTransfers = 0;
        vm.persons.forEach(person => {
            if(person.shareTransfer.euro)
                sumOfShareTransfers = sumOfShareTransfers + parseInt(person.shareTransfer.euro);
        });

        return sumOfShareTransfers;
    }

    vm.close = () => $uibModalInstance.dismiss("cancel");

    vm.submit = () => {
        let sumOfShareTransfers = 0;
        vm.persons.forEach(person => {
            if(person.shareTransfer.euro)
                sumOfShareTransfers = sumOfShareTransfers + parseInt(person.shareTransfer.euro);
        });

        if ($scope.transferCompany.$invalid || (vm.transferCompany.typeAll === "more" && sumOfShareTransfers !== vm.basicCapital)) {
            vm.submitted = true;

            if(vm.transferCompany.typeAll === "more" && sumOfShareTransfers !== vm.basicCapital)
                vm.sumOfShareTransferError = true;
        } else {
            vm.persons.forEach(person => {
                if (!person.otherPerson.ico)
                    person.otherPerson.fullName = vm.getFullName(person.otherPerson);
            });

            if(vm.persons.length === 1) {
                vm.persons[0].shareTransfer.euro = vm.basicCapital;
            }

            $uibModalInstance.close(vm.persons);
        }
    };

}

angular.module("firmaren")
    .factory("ModalTransferCompany", ModalTransferCompany)
    .controller("ModalTransferCompanyController", ModalTransferCompanyController);
}());
