;(function() {
/* @ngInject */
function BurzaFiriemKrok1Controller(BurzaFiriemKrok1Service, $window, UserService, $scope, $rootScope, ModalFinishLater, $interval, $filter, Helpers, ModalForgotPassword) {
  let vm = this;
  let service = BurzaFiriemKrok1Service;

  vm.maxStep = 1;
  vm.valid = false;
  vm.onSaveAndContinueClick = onSaveAndContinueClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.totalPrice = 0;
  vm.onEmailChange = onEmailChange;
  vm.onPasswordChange = onPasswordChange;
  vm.showModalForgotPassword = showModalForgotPassword;
  vm.onResetCountry = onResetCountry;
  vm.onIcoChange = onIcoChange;
  vm.onCityChange = onCityChange;
  vm.scrollTo = Helpers.scrollTo;
  vm.onClick = onClick;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.changePhone = changePhone;

  vm.order = {
    companyInfo: {
      phoneNumber: {
        prefix : "+421"
      },
      showPhone: true,
      price: {
        type: "custom"
      },
      activity: "1",
      debt: "0",
      register: "0",
      property: {
        type: "withProperty"
      },
      sales: "0",
      date: {}
    },
    paymentInfo: {
      address: {}
    },
    additionalInfo: {},
    files : {},
    contactInfo: {
      address: {}
    }
  };
  vm.cart = {
    text: "/mesiac"
  };

  vm.locations = [
    "Bratislavský kraj",
    "Trnavský kraj",
    "Trenčiansky kraj",
    "Nitriansky kraj",
    "Žilinský kraj",
    "Banskobystrický kraj",
    "Prešovský kraj",
    "Košický kraj"
  ];

  let minDate = new Date();
  vm.years = createArray(1900, minDate.getFullYear(), true);

  window.showSpinner(5);
  service.getMaxStep().then( (ms) => { vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
  service.getPrice().then(onPriceLoad);
  service.getOrder().then(onOrderLoad);
  service.getCities().then(onCitiesLoad);
  service.getCountries().then(onCountriesLoad);

  let server = UserService;

  vm.loggedUser = {};
  vm.user = {};
  vm.errorMessage = null;
  vm.showData = "";
  vm.userExists = false;
  vm.requiredMessage = false;
  vm.contactInfo = {};
  vm.nextStep = 2;
  vm.loginSpinner = false;
  vm.loginDisabled = true;

  userLoad();

  $scope.$on('user-changed', function(event, args) {
    userLoad();
  });

  $scope.$on('on-password-change', function(event, data) {
    if(vm.order.contactInfo)
      vm.order.contactInfo.password = data;

    onPasswordChange();
  });

  $scope.$on('on-email-change', function(event, args) {
    onEmailChange();
  });

  $scope.$on('on-phone-number-change', function(event, args) {
    changePhone();
  });

  function changePhone() {
    if (vm.order.companyInfo && vm.order.companyInfo.phoneNumber)
      vm.order.contactInfo.phoneNumber = vm.order.companyInfo.phoneNumber.prefix + vm.order.companyInfo.phoneNumber.number;
  }

  function createArray(start, end, reverse) {
    let array = [];
    for(var i = start; i <= end; i++) {
      array.push(i);
    }
    if(reverse)
      return array.reverse();
    else return array;
  }

  function userLoad() {
    onEmailChange();
    onPasswordChange();
    onUserChange();
  }

  function onClick(item, elem) {
    if(elem === "day") {
      vm.order.companyInfo.date.day = item;
      vm.mouseoverDay = false;
    } else if(elem === "month") {
      vm.order.companyInfo.date.month = item;
      vm.mouseoverMonth = false;
    } else if(elem === "year") {
      vm.order.companyInfo.date.year = item;
      vm.mouseoverYear = false;
    }
  }

  function showModalForgotPassword() {
    ModalForgotPassword(vm.order.contactInfo.email)
        .add()
        .result
        .then();
  }

  function onCityChange($item) {
    vm.order.paymentInfo.address.zipCode = $item && $item._3;
  }

  function onIcoChange(id, ico) {
    service.getFinstatInfo({name: ico}).then( data => {
      if(data) {
        if(id === 'companyInfo') {
          vm.order.companyInfo.companyName = data.name;
          vm.order.paymentInfo.ico = vm.order.companyInfo.ico;

          vm.locations.forEach(location => {
            if (location.indexOf(data.region) != -1)
              vm.order.companyInfo.location = location;
          });

          if (data.created)
            vm.order.companyInfo.date = data.created;

          if (vm.order.contactInfo)
            vm.order.contactInfo.address = data.address;
        }

        vm.order.paymentInfo.companyName = data.name;
        vm.order.paymentInfo.dic = data.dic;
        vm.order.paymentInfo.icdph = data.icdph;
        vm.order.paymentInfo.address = data.address;

        vm.order.paymentInfo.address.country = vm.countries[0]._2;
      }
    });
  }

  function onCitiesLoad(ps) {
    vm.cities = ps;

      window.hideSpinnerWhenAllResolved();
  }

  function onCountriesLoad(ps) {
    vm.countries = ps;
    vm.order.paymentInfo.address.country = vm.countries[0]._2;

    if(vm.order.contactInfo.address)
      vm.order.contactInfo.address.country = vm.countries[0]._2;

    window.hideSpinnerWhenAllResolved();
  }

  function onSaveAndContinueClick(steps) {
    if(vm.order.companyInfo && vm.order.companyInfo.details)
      vm.order.companyInfo.details = $filter("removeEmojiFilter")(vm.order.companyInfo.details);
    if(vm.order.additionalInfo && vm.order.additionalInfo.comment)
      vm.order.additionalInfo.comment = $filter("removeEmojiFilter")(vm.order.additionalInfo.comment);

    if($scope.step1.$invalid) {
      vm.requiredMessage = true;
      //scrollTo("content");
      vm.submitted = true;
    } else {
      userLoad();
      service.setOrder(vm.order).then(({success, data}) => {
        if (success) {
          if (steps) {
            vm.valid = true;
          } else {
            $window.location = "krok2";
          }
        }
        else {
          vm.input.hasError = true;
          vm.input.message = data;
        }
      });
    }
  }

  function onPriceLoad(tp) {
    console.log(tp);
    vm.cart.totalPrice = tp;

      window.hideSpinnerWhenAllResolved();
  }

  function onSaveForLaterClick() {
    ModalFinishLater(service, JSON.stringify(vm.order))
      .add(service, JSON.stringify(vm.order))
      .result
      .then();
  }

  function onOrderLoad(order) {
    if(order) {
      vm.order = order;
      userLoad();
    } else {
      service.getStringContent().then((data) => {
        if(data) {
          vm.order = JSON.parse(data);
          userLoad();
        }
      });
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onPasswordChange(){
    if(vm.order.contactInfo && vm.order.contactInfo.email) {
      server.login({
        email: vm.order.contactInfo.email,
        password: vm.order.contactInfo.password
      }).then(({success, data}) => {
        if (success) {
          vm.errorMessage = null;
          onUserChange();
          $rootScope.$broadcast('user-changed-contact');
        } else {
          vm.errorMessage = "bad";
        }
      }).catch(vm.errorMessage = "bad");
    }
  }

  function onUserChange() {
    vm.errorMessage = null;
    server.getLoggedUser().then(onLoggedUserLoad);
  }

  function onLoggedUserLoad(user) {
    vm.loggedUser = user;
    if(!vm.order.contactInfo) {
      vm.order.contactInfo = {};
    }
    if(user) {
      vm.order.contactInfo.email = user.email;
      vm.order.contactInfo.phoneNumber = $filter("whitespaceFilter")(user.phoneNumber);
      vm.order.contactInfo.name = user.firstName;
      vm.order.contactInfo.surname = user.lastName;
      vm.order.contactInfo.address = user.address;
      vm.order.contactInfo.dateOfBirth = user.dateOfBirth;
      vm.order.contactInfo.hasCompany = user.hasCompany;
      vm.order.contactInfo.companyName = user.companyName;
      vm.order.contactInfo.ico = user.ico;
      vm.order.contactInfo.dic = user.dic;
      vm.order.contactInfo.icDph = user.icDph;

      if(user.firstName !== "" && user.lastName !== "") {
        vm.showContactInfoForm = true;
        vm.showContactInfoSelect = false;
      }
    }

    if(!vm.order.contactInfo.phoneNumber) {
      vm.order.contactInfo.phoneNumber = "+421";
    }
  }

  function onEmailChange() {
    vm.loginDisabled = true;
    vm.errorMessage = "";
    if(vm.order.contactInfo && vm.order.contactInfo.email) {
      vm.loginSpinner = true;
      server.isFree({email: vm.order.contactInfo.email}).then(({success, data}) => {
        vm.loginSpinner = false;
        vm.loginDisabled = false;
        if (success) {
          vm.userExists = false;
        } else {
          if (vm.order.contactInfo && vm.order.contactInfo.password && vm.order.contactInfo.password !== "")
            onPasswordChange();
          vm.userExists = true;
        }
      }).catch(vm.userExists = false);
    }
  }

  function onResetCountry() {
    vm.order.paymentInfo.address.country = "";
    $interval(function() {
      let element = $window.document.getElementById("address");
      if(element)
        element.focus();
    }, 100, 1);
  }
}

angular.module("firmaren")
  .controller("BurzaFiriemKrok1Controller", BurzaFiriemKrok1Controller);
}());
