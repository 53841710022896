;(function() {
/* @ngInject */
function BurzaElementController($window, $location, $timeout) {
    let vm = this;

    vm.showTooltip = false;
    vm.angular = angular;

    vm.copyLink = copyLink;
    vm.isNumber = isNumber;
    vm.openModalFB = openModalFB;

    function isNumber(item) {
        return !isNaN(Number(item));
    }

    function openModalFB() {
        $window.open('https://www.facebook.com/sharer.php?u=https%3A%2F%2Fwww.firmaren.sk%2Fburza-firiem%2Fdetail%3Fid%3D' + vm.company.id, 'sharer','toolbar=0,status=0,width=626,height=436');
        return false;
    }

    function copyLink() {
        angular.element('<textarea/>')
            .css({ 'opacity' : '0', 'position' : 'fixed' })
            .text($location.absUrl())
            .appendTo(angular.element($window.document.body))
            .select()
            .each(function() { document.execCommand('copy') })
            .remove();

        vm.showTooltip = true;

        $timeout(function() {
            vm.showTooltip = false;
        }, 2000);
    }
}

angular.module("firmaren")
    .component("burzaElement", {
        templateUrl: "/app/shared/burza-element/burza-element.html",
        controller: BurzaElementController,
        controllerAs: "vm",
        bindings: {
            company: "=",
            showDetail: "="
        }
    });
}());
