;(function() {
/* @ngInject */
function ModalExecutiveManager($uibModal, $rootScope) {
  let cities, countries, isChange;

  return function init(cities_, countries_, isChange_) {
    cities = cities_;
    countries = countries_;
    isChange = isChange_;

    return { add, edit };
  };

  function open(options = {}, executiveManager = { }) {
    //console.log(executiveManager);
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-executive-manager/modal-executive-manager.html",
      controller: "ModalExecutiveManagerController",
      controllerAs: "vm",
      bindToController: true,
      size: "lg",
      backdrop: "static",
      resolve: {
        cities: () => cities,
        countries: () => countries,
        isChange: () => isChange,
        executiveManager: () => executiveManager
      }
    }), options);
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(), scope) })
  }

  function edit(executiveManager, scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) }, executiveManager);
  }
}

/* @ngInject */
function ModalExecutiveManagerController(Helpers, $filter, $uibModalInstance, cities, countries, isChange, executiveManager, $interval, $window, $scope) {
  let vm = this;

  vm.genders = ["male", "female"];
  //console.log(executiveManager);
  vm.cities = cities;
  vm.countries = countries;
  vm.isChange = isChange;
  vm.executiveManager = executiveManager;
  vm.isCollapsed = {
    isOpen: false
  };
  vm.executiveManager.gender = $filter("translate")('gender.' + vm.genders[0]);
  //console.log(vm.executiveManager);
  if(!vm.executiveManager.idNumber) {
    vm.executiveManager.idNumber = {};
  }

  if(vm.isChange) {
    let now = new Date();
    vm.executiveManager.date = now;

    if(vm.executiveManager.name)
      vm.executiveManager.typeChange = 2;
    else vm.executiveManager.typeChange = 1;

    vm.popupOpened = false;
    vm.format = "EEEE - dd. MM. yyyy";
    vm.dateOptions = {
      showWeeks: false,
      minDate: now,
      initDate: now
    };
  }

  vm.close = () => $uibModalInstance.dismiss("cancel");
  //vm.submit = () => $uibModalInstance.close(vm.executiveManager);
  vm.submit = () => {
    if($scope.executiveManager.$invalid) {
      vm.isCollapsed.isOpen = true;
      vm.submitted=true;
    } else {
      $uibModalInstance.close(vm.executiveManager);
    }
  };

  vm.onIdNumberChange = onIdNumberChange;
  vm.onResetCountry = onResetCountry;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.onZipCodeChange = onZipCodeChange;
  vm.onFormerSurnameChange = onFormerSurnameChange;

  vm.formerSurnameChange = false;

  if(!vm.executiveManager.address) {
    vm.executiveManager.address = {
      country: ""
    };
    vm.executiveManager.address.country = vm.countries[0]._2;
  }

  let minDate = new Date();

  vm.days = createArray(1,31, false);
  vm.months = createArray(1,12, false);
  vm.years = createArray(1900, minDate.getFullYear() - 18, true);

  function createArray(start, end, reverse) {
    let array = [];
    for(var i = start; i <= end; i++) {
      array.push(i);
    }
    if(reverse)
      return array.reverse();
    else return array;
  }

  function onFormerSurnameChange() {
    vm.formerSurnameChange = true;
  }

  function onIdNumberChange() {
    if(vm.executiveManager) {
      vm.executiveManager.idNumber.prefix = Helpers.calculateIdNumber(vm.executiveManager.dateOfBirth, vm.executiveManager.gender);
    }
  }

  function onZipCodeChange($item) {
    vm.executiveManager.address.zipCode = $item && $item._3;
  }

  function onResetCountry() {
    vm.executiveManager.address.country = "";
    $interval(function() {
      let element = $window.document.getElementById("address.country");
      if(element)
        element.focus();
    }, 100, 1);
  }

}

angular.module("firmaren")
  .factory("ModalExecutiveManager", ModalExecutiveManager)
  .controller("ModalExecutiveManagerController", ModalExecutiveManagerController);
}());
