;(function() {
/* @ngInject */
function VirtualneSidloObjednaneController(VirtualneSidloObjednaneService, $window, $filter) {
    let vm = this;
    let server = VirtualneSidloObjednaneService;

    vm.onPayClick = onPayClick;
    vm.payment = "movement";
    vm.showMovement = false;
    vm.downloadLabel = $filter("translate")('payments.documents.general');

    server.getPaymentInfo().then(onPaymentInfoLoad);

    function onPaymentInfoLoad(payment) {
        if(payment) {
            vm.orderId = payment.orderId;
            vm.price = payment.price;
            vm.paymentData = payment;
            vm.invoiceHref = `/invoice/download?orderId=${payment.orderId}`;
            vm.proformaHref = `/invoice/download?proforma=true&orderId=${payment.orderId}`;
            vm.documentsHref = `/objednavka/detail?o=${payment.documentsLink}`;
        } else {
            $window.location = "krok1";
        }
    }

    function onPayClick() {
        if(vm.payment === "movement") {
            if (vm.paymentData.hash && vm.paymentData.hash !== "") {
                let url = '/bank-transfer/' + vm.paymentData.hash;
                $window.location = url;
            } else vm.showMovement = true;
        } else {
            server.pay({payment: vm.payment}).then(({success, data, error}) => {
                if (success) {
                    $window.location = data;
                }
            })
        }
    }
}

angular.module("firmaren")
    .controller("VirtualneSidloObjednaneController", VirtualneSidloObjednaneController);
}());
