;(function() {
/* @ngInject */
function FaqComponentController(IndexService) {
    let server = IndexService;
    let vm = this;

    vm.items = [];
    vm.allItems = [];

    vm.isAll = false;

    vm.toggle = toggle;
    vm.showAll = showAll;

    setTimeout(() => {
        server.getSpecifiFaq({category: vm.category, subcategory: vm.subcategory}).then(onFaqSpecificLoad);
    });

    function onFaqSpecificLoad(items) {
        items.forEach(i => {
            vm.allItems.push({
                question: i.question,
                answer: i.answer,
                toggle: false
            });
        });

        if (vm.allItems.length > 0) {
            vm.allItems[0].toggle = true;
            vm.items = vm.allItems.slice(0, 4);
        }
    }

    function toggle(item) {
        vm.items.forEach(i => {
            i.toggle = false;
        });
        item.toggle = !item.toggle;
    }

    function showAll() {
        vm.isAll = true;
        vm.items = vm.allItems;
    }
}

angular.module("firmaren")
    .component("faqComponent", {
        templateUrl: "/app/shared/faq-component/faq-component.html",
        controller: FaqComponentController,
        controllerAs: "vm",
        bindings: {
            category: "=",
            subcategory: "="
        }
    });
}());
