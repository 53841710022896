;(function() {
/* @ngInject */
function CouponValidator() {

  return {
    require: "ngModel",
    scope: {
      otherModelValue: "=couponValidator"
    },
    link: function(scope, element, attributes, ngModel) {

      ngModel.$validators.couponExpired = function(modelValue) {
        return !modelValue || scope.otherModelValue !== "expired";
      };

      ngModel.$validators.couponUsed = function(modelValue) {
        return !modelValue || scope.otherModelValue !== "used";
      };

      ngModel.$validators.couponInvalid = function(modelValue) {
        return !modelValue || scope.otherModelValue !== "invalid";
      };

      ngModel.$validators.couponTemp = function(modelValue) {
        return !modelValue || scope.otherModelValue !== "temp";
      };

      scope.$watch("otherModelValue", function() {
        ngModel.$validate();
      });
    }
  };
};

angular.module("firmaren")
  .directive("couponValidator", CouponValidator);
}());
