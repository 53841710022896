;(function() {
/* @ngInject */
function NameController(Helpers, $filter) {
    let vm = this;

    vm.onElementFocus = Helpers.onElementFocus;
    vm.onElementBlur = Helpers.onElementBlur;
    vm.onClick = onClick;

    vm.genders = ["male", "female"];

    function onClick(item) {
      vm.model.gender = $filter('translate')('gender.' + item);
      vm.mouseover = false;

      onIdNumberChange();
    }

    function onIdNumberChange() {
      if (vm.model) {
        if (vm.model.gender && vm.model.idNumber && vm.model.dateOfBirth) {
          vm.model.idNumber.prefix = Helpers.calculateIdNumber(vm.model.dateOfBirth, vm.model.gender);
        } else {
          vm.onElementFocus(vm.genderId ? vm.genderId : "gender");
          vm.onElementBlur(vm.genderId ? vm.genderId : "gender");
        }
      }
    }

  }

  angular.module("firmaren")
    .component("name", {
      templateUrl: "/app/shared/name/name.html",
      controller: NameController,
      controllerAs: "vm",
      bindings: {
        model: "=",
        form: "=",
        submitted: "=",
        id: "=",
        required: "=",
        genderAndTitle: "=",
        genderId: "=",
        showGender: "=",
        onChange: "=",
        getDomainInfo: "=",
        disableName: "=",
        disableSurname: "=",
        focusSurname: "=",
        focusName: "="
      }
    });
}());
