;(function() {
/* @ngInject */
function ModalForgotPassword($uibModal, $rootScope) {
    let email;

    return function init(email_) {
        email = email_;

        return { add };
    };

    function open(options = {}) {
        return $uibModal.open(angular.extend({
            templateUrl: "/app/shared/modal-forgot-password/modal-forgot-password.html",
            controller: "ModalForgotPasswordController",
            controllerAs: "vm",
            bindToController: true,
            size: "lg",
            backdrop: "static",
            resolve: {
                email: () => email
            }
        }, options));
    }

    function add(scope = {}) {
        return open({ scope: angular.extend($rootScope.$new(true), scope) }, email)
    }
}

/* @ngInject */
function ModalForgotPasswordController($uibModalInstance, UserService, $window, email) {
    let vm = this;

    let server = UserService;
    vm.email = email;

    vm.close = () => $uibModalInstance.dismiss("cancel");
    vm.submit = () => {
        server.forgotPassword({email: vm.email}).then(({ success, data }) => {

        }).catch();
        $uibModalInstance.close();
    }
}

angular.module("firmaren")
    .factory("ModalForgotPassword", ModalForgotPassword)
    .controller("ModalForgotPasswordController", ModalForgotPasswordController);
}());
