;(function() {
/* @ngInject */
function GeneratorController(Helpers, UserService, ModalNotLoggedUser, NameGenerator, $timeout, IndexService, $window,
                             $rootScope) {
    let vm = this;

    let userService = UserService;
    let nameGenerator = NameGenerator;
    let indexService = IndexService;

    vm.description = "";
    vm.token = "";
    vm.checkBoxes = [];

    vm.checkName = "";
    vm.isCheckPage = false;

    vm.editorContent = {
        values: [],
        lastValue: ""
    };

    vm.boxes = [];
    vm.enumChecks = [];

    vm.socialNetworkBoxes = [];
    vm.enumSocialNetworkChecks = [];

    vm.custom = "";
    vm.customNameError = false;

    vm.examples = undefined;

    vm.onElementFocus = Helpers.onElementFocus;
    vm.generate = generate;
    vm.check = check;
    vm.checkOne = checkOne;
    vm.otherService = otherService;
    vm.createService = createService;
    vm.setCompanyName = setCompanyName;
    vm.clear = clear;
    vm.back = back;
    vm.generateFavourite = generateFavourite;
    vm.hasFavourite = hasFavourite;
    vm.setFavourite = setFavourite;

    nameGenerator.getNameAvailabilityCheck().then(onNameAvailabilityCheckLoad);
    nameGenerator.getNameAvailabilitySocialNetworkCheck().then(onNameAvailabilitySocialNetworkCheckLoad);

    function setFavourite(name) {
        let count = 0;
        vm.names.forEach(name => {
            if(name.favourite) {
                count = count + 1;
            }
        });

        if(count < 10 || name.favourite) {
            name.favourite = !name.favourite;
        }
    }

    function onNameAvailabilityCheckLoad(data) {
        vm.enumChecks = angular.copy(data);
        vm.boxes = angular.copy(data);
    }

    function onNameAvailabilitySocialNetworkCheckLoad(data) {
        vm.enumSocialNetworkChecks = angular.copy(data);
        vm.socialNetworkBoxes = angular.copy(data);
    }

    function hasFavourite() {
        return vm.names.some(name => {
            return name.favourite === true;
        });
    }

    function generateFavourite() {
        let examples = [];
        vm.names.forEach(name => {
           if(name.favourite) {
               examples.push(name.name);
           }
        });
        generate(examples);
    }

    function back() {
        vm.isCheckPage = !vm.isCheckPage;
        vm.boxes = angular.copy(vm.enumChecks);
        vm.socialNetworkBoxes = angular.copy(vm.enumSocialNetworkChecks);
    }

    function clear() {
        $rootScope.$broadcast('clear-keywords');
        vm.description = "";
        vm.generator.$setPristine();
        vm.generator.$setUntouched();
        vm.submitted = false;
        vm.names = undefined;
        Helpers.scrollTo("top");
    }

    function setCompanyName(box) {
        indexService.setCompanyName({name: vm.checkName, service: box.check});
    }

    function createService(box) {
        if(box.check === "WebSupportSk") {
            return "https://www.websupport.sk/cart/domain-checker?ref=NDwvGl07&qd=" + vm.checkName + ".sk";
        } else if(box.check === "WebSupportCom") {
            return "https://www.websupport.sk/cart/domain-checker?ref=NDwvGl07&qd=" + vm.checkName + ".com";
        } else if(box.check === "SpolocnostSRucenimObmedzenym") {
            return "/zalozenie-firmy/krok1";
        } else if(box.check === "ObcianskeZdruzenie") {
            return "/zalozenie-obcianskeho-zdruzenia/krok1";
        } else if(box.check === "Zivnost") {
            return "/zalozenie-zivnosti/krok1";
        } else if(box.check === "EuipoTrademark") {
            return "/ochranna-znamka";
        } else if(box.check === "Facebook") {
            return "https://www.facebook.com/reg/";
        } else if(box.check === "Instagram") {
            return "https://www.instagram.com/accounts/login/";
        } else if(box.check === "Youtube") {
            return "https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Fwww.youtube.com%2Fsignin%3Faction_handle_signin%3Dtrue%26app%3Ddesktop%26hl%3Dsk%26next%3Dhttps%253A%252F%252Fwww.youtube.com%252Faccount%253Fcbrd%253D1%26feature%3Dredirect_login&hl=sk&ifkv=ARpgrqffkSNrTLIPG-J2FOPL27Dbp-Q9j1ney59xJFSzZ2zFnbGurY48IEGaPeCld3durWuSjE-xxg&passive=true&service=youtube&uilel=3&flowName=GlifWebSignIn&flowEntry=ServiceLogin&dsh=S858611531%3A1727254026302903&ddm=0";
        } else if(box.check === "Linkedin") {
            return "https://www.linkedin.com/signup";
        } else if(box.check === "Twitter") {
            return "https://twitter.com/i/flow/signup";
        } else if(box.check === "Tiktok") {
            return "https://www.tiktok.com/signup";
        }
    }

    function otherService(box) {
        if(box.check === "WebSupportSk") {
            return "https://www.websupport.sk?ref=NDwvGl07";
        } else if(box.check === "WebSupportCom") {
            return "https://www.websupport.sk?ref=NDwvGl07";
        } else if(box.check === "SpolocnostSRucenimObmedzenym") {
            return "/zmeny-vo-firme";
        } else if(box.check === "ObcianskeZdruzenie") {
            return "/zmeny-v-obcianskom-zdruzeni";
        } else if(box.check === "Zivnost") {
            return "/zmena-zivnosti";
        } else if(box.check === "EuipoTrademark") {
            return "/ochranna-znamka";
        }
    }

    function checkOne(name, check, index, isSocial) {
        isSocial ? vm.socialNetworkBoxes[index].error = undefined : vm.boxes[index].error = undefined;
        nameGenerator.check({name: name, nameAvailabilityCheck: check}).then((result) => {
            if (result) {
                isSocial ? vm.socialNetworkBoxes[index] = result : vm.boxes[index] = result;
            } else {
                isSocial ? vm.socialNetworkBoxes[index].error = true : vm.boxes[index].error = true;
            }
        });
    }

    function check(name) {
        if(name.length > 0) {
            vm.isCheckPage = true;
            Helpers.scrollTo("top");
            vm.checkName = name;

            vm.boxes.forEach((box, index) => {
                checkOne(name, box.check, index, false);
            });

            vm.socialNetworkBoxes.forEach((box, index) => {
                checkOne(name, box.check, index, true);
            });
        } else {
            vm.customNameError = true;
        }
    }

    function generate(examples) {
        vm.examples = examples;
        nameGenerator.setGenerationAttemptCounter().then((count) => {
           if(count >= 4) {
               userService.getLoggedUser().then(onLoggedUserLoad);
           } else callGenerateService();
        });
    }

    function onLoggedUserLoad(user) {
        if (user) {
            callGenerateService();
        } else showLogginInfo();
    }

    function callGenerateService() {
        var element = document.querySelector('[name="vm.generator"]');
        var firstInvalid;

        if(vm.editorContent.values.length === 0 && vm.editorContent.lastValue.length === 0) {
            firstInvalid = element.querySelector("#keywordsId");
        } else {
            firstInvalid = element.querySelector('.ng-invalid');
        }

        if(firstInvalid) {
            vm.submitted=true;

            $timeout(function () {
                // if we find one, set focus
                if (firstInvalid) {
                    $('html, body').animate({
                        scrollTop: $("#" + firstInvalid.id.replace(/\./g, "\\\.")).offset().top - 200
                    }, 500);
                }
            });
        } else {
            grecaptcha.ready(function () {
                grecaptcha.execute('6LcZ0HoaAAAAAGkLyJRbYM59jd6wKCKTaQ3-AmSF', {action: 'submit'}).then(function (token) {
                    if (vm.token === "") {
                        vm.token = token;
                    }
                    let model = {
                        style: "Serious",
                        keywords: vm.editorContent.values,
                        description: vm.description,
                        token: vm.token
                    }

                    if(vm.examples && vm.examples.length > 0) {
                        model.examples = vm.examples;
                    }

                    if (vm.editorContent.lastValue !== "")
                        model.keywords.push(vm.editorContent.lastValue);

                    window.showSpinner(1);
                    nameGenerator.generate(model).then((result) => {
                        if (result) {
                            vm.names = [];
                            result.forEach(name => {
                                vm.names.push({name: name, favourite: false});
                            });
                            // vm.names = result;
                            vm.token = "";

                            Helpers.scrollTo("generated-names");
                        }
                        window.hideSpinnerWhenAllResolved();
                    });
                });
            });
        }
    }

    function showLogginInfo() {
        ModalNotLoggedUser("generator.modal.title", "generator.modal.desc", "Name-Generator")
            .add()
            .result
            .then();
    }
}

angular.module("firmaren")
    .controller("GeneratorController", GeneratorController);
}());
