;(function() {
/* @ngInject */
function ZalozenieSroKrok4Controller(ZalozenieSroKrok4Service, Helpers, $filter, $window, UserService, $scope, $rootScope, ModalFinishLater, $interval, ModalForgotPassword, ModalStreetNumber) {
  let vm = this;

  let service = ZalozenieSroKrok4Service;
  vm.service = service;

  vm.maxStep = 5;
  vm.valid = false;
  vm.cart = {};
  vm.order = {
    invoiceTo: {
      type: "",
      address: {},
      sendInvoice: true
    },
    contactInfo: {
      address: {}
    },
    additionalInfo: {},
    contactMe: true
  };
  vm.registerDate = {
    type: "asap"
  };
  vm.nextStep = 5;

  let now = new Date();
  now.setDate(now.getDate() + 13);   // allow 14 days in future

  vm.popupOpened = false;
  vm.format = "EEEE - dd. MM. yyyy";
  vm.dateOptions = {
    showWeeks:false,
    minDate: now,
    initDate: now
    //dateDisabled: disabled
  };
  vm.requiredMessage = false;

  vm.isCredit = false;

  vm.isCollapsed = {
    prokura: true,
    registerDate: true
  };
  vm.loginSpinner = false;
  vm.loginDisabled = true;
  vm.showStreetNumberModals = true;
  vm.isSinglePerson = false;
  vm.intermediateStep = Helpers.getUrlParameter('upsell') ? true : false;
  vm.upsells = {};
  vm.isTbCampaign = false;

  vm.onBackClick = onBackClick;
  vm.onSaveAndContinueClick = onSaveAndContinueClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.onResetCountry = onResetCountry;
  vm.scrollTo = Helpers.scrollTo;
  vm.onCodeChange = onCodeChange;
  vm.onCityChange = onCityChange;
  vm.onIcoChange = onIcoChange;
  vm.showModalForgotPassword = showModalForgotPassword;
  vm.onPersonalInfoClick = onPersonalInfoClick;
  vm.fullName = Helpers.fullName;
  vm.onEmailChange = onEmailChange;
  vm.onPasswordChange = onPasswordChange;

  $scope.$on('sro-save-content', function(event, args) {
    contactInfoToOrder();
    server.setBackContent(JSON.stringify(vm.order))
  });

  $scope.$on('on-price-change', function(event, args) {
    service.getCart().then(onCartLoad);
  });

  $scope.$watch('vm.isCredit', function (newValue, oldValue) {
    if (newValue !== oldValue) {
      onCreditChange();
    }
  }, true);

  window.showSpinner(12);
  service.getMaxStep().then((ms) => {vm.maxStep = ms; window.hideSpinnerWhenAllResolved() } );
  service.getCart().then(onCartLoad);
  service.getCompanyName().then(onCompanyNameLoad);
  service.getCities().then(onCitiesLoad);
  service.getCountries().then(onCountriesLoad);
  service.getOtherInfo().then(onOtherInfoLoad);
  service.getPlaceOfBusiness().then(onPlaceOfBusinessLoad);
  service.getVoucher().then(onVoucherLoad);
  service.getFoundersManagers().then(onFoundersManagersLoad);
  service.getSinglePerson().then((isSingle) => { vm.isSinglePerson = isSingle; window.hideSpinnerWhenAllResolved(); });
  service.getUpsell().then((upsell) => { if(angular.isDefined(upsell)) vm.upsells = upsell; console.log(vm.upsells); window.hideSpinnerWhenAllResolved(); });
  service.getTBCampaign().then((isTbCampaign) => { vm.isTbCampaign = isTbCampaign; window.hideSpinnerWhenAllResolved() });

  let server = UserService;

  vm.loggedUser = {};
  vm.user = {};
  vm.errorMessage = null;
  vm.showData = "";
  vm.userExists = false;
  vm.contactInfo = {};
  vm.showContactInfoForm = false;
  vm.showContactInfoSelect = true;

  userLoad();

  $scope.$on('user-changed', function(event, args) {
    userLoad();
  });

  $scope.$on('on-password-change', function(event, data) {
    if(vm.order.contactInfo)
      vm.order.contactInfo.password = data;

    onPasswordChange();
  });

  $scope.$on('on-email-change', function(event, args) {
    onEmailChange();
  });

  function contactInfoToOrder() {
    if(!vm.showContactInfoForm) {
      vm.order.contactInfo.name = vm.contactInfo.name;
      vm.order.contactInfo.surname = vm.contactInfo.surname;
      vm.order.contactInfo.address = vm.contactInfo.address;
      vm.order.contactInfo.dateOfBirth = vm.contactInfo.dateOfBirth;
    }
  }

  function onPersonalInfoClick(elem, item) {
    if(elem === "other") {
      vm.showContactInfoForm = true;
      vm.order.contactInfo.type = $filter('translate')('paymentInfo.other');
    } else {
      vm.showContactInfoForm = false;
      vm.contactInfo = item;
      vm.order.contactInfo.type = vm.fullName(item);
    }
    vm.mouseoverContactInfoType = false
  }
  function onFoundersManagersLoad(item) {
    console.log(item);

    vm.persons = [];
    vm.options = [];
    item.executiveManagers.forEach(person => {
      vm.persons.push(person);
    });
    item.founders.forEach(person => {
      vm.persons.push(person);
      vm.options.push({type: "founder", name: person.name + " " + person.surname});
    });
    item.foundersCompanies.forEach(person => {
      vm.options.push({type: "founder", name: person.companyName});
    });

    if(vm.persons.length > 0) {
      vm.contactInfo = vm.persons[0];
      vm.order.contactInfo.type = vm.fullName(vm.contactInfo);
    }

    window.hideSpinnerWhenAllResolved();
  }

  function showModalForgotPassword() {
    ModalForgotPassword(vm.order.contactInfo.email)
        .add()
        .result
        .then();
  }

  function onIcoChange() {
    service.getFinstatInfo({name: vm.order.invoiceTo.ico}).then( data => {
      if(data) {
        vm.order.invoiceTo.companyName = data.name;
        vm.order.invoiceTo.address = data.address;
        // vm.city = data.address.city;
        vm.order.invoiceTo.dic = data.dic;
        vm.order.invoiceTo.icdph = data.icdph;
      }
      vm.order.invoiceTo.address.country = vm.countries[0]._2;
    });
  }

  function onCityChange($item) {
    vm.order.invoiceTo.address.zipCode = $item && $item._3;
  }

  function onPlaceOfBusinessLoad(pb) {
    if (angular.isDefined(pb)) {
      //console.log(pb.email);
      vm.order.contactInfo.email = pb.email;
      onEmailChange();
    }

      window.hideSpinnerWhenAllResolved();
  }


  function onVoucherLoad(voucher) {
    if(voucher && voucher.voucher) {
      if(! vm.order.additionalInfo) {
        vm.order.additionalInfo = {};
      }
      vm.order.additionalInfo.voucher = voucher.voucher;
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onCodeChange() {
    if(vm.order.additionalInfo.voucher) {
      service.setDiscount(vm.order.additionalInfo).then(({ success, error }) => {
        if(success) {
          // TODO docasne FIRM-2342
          vm.voucherError = "temp";
        } else {
          vm.voucherError = error;
        }
      });
    }
    onPriceChange();
  }

  function onPriceChange() {
    service.getCart().then(onCartLoad);
  }

  function onCreditChange() {
    service.setCredit(vm.isCredit.toString()).then(onPriceChange);
  }

  function onCartLoad(c) {
    vm.cart.price = c.totalPrice;
    vm.cart.items = c.items;
    vm.cart.placeOfWork = c.placeOfBusinessPrice;
    vm.cart.size = c.subjectCount;

    window.hideSpinnerWhenAllResolved();
  }

  function onCompanyNameLoad(cn) {
    if (angular.isDefined(cn)) {
      vm.companyName = cn.name + cn.suffix;
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onCitiesLoad(cs) {
    vm.cities = cs;
    window.hideSpinnerWhenAllResolved();
  }

  function onCountriesLoad(cs) {
    vm.countries = cs;

    if(vm.order.invoiceTo.address)
      vm.order.invoiceTo.address.country = vm.countries[0]._2;

    if(vm.order.contactInfo.address)
      vm.order.contactInfo.address.country = vm.countries[0]._2;

    window.hideSpinnerWhenAllResolved();
  }

  function onBackClick() {
    if(vm.intermediateStep) {
      vm.intermediateStep = false;
    } else {
      contactInfoToOrder();
      service.setBackContent(JSON.stringify(vm.order)).then(({success, data}) => {
        if (success) {
          $window.location = "krok3";
        } else {
          // todo validation
        }
      });
    }
  }

  function checkStreetNumber() {
    let addresses = [vm.order.invoiceTo && vm.order.invoiceTo.address,
      vm.order.contactInfo && vm.order.contactInfo.address];

    var result = false;

    addresses.forEach(address => {
      console.log(address);
      if (address && address.streetNo && (!address.streetNoPrefix || address.streetNoPrefix === ""))
        result = true;
    });

    return result;
  }

  function onSaveAndContinueClick(steps) {
    if (vm.intermediateStep){
      if(steps){
        vm.valid = true;
      } else {
        if (vm.upsells.tb && vm.upsells.tb.active && !vm.upsells.tb.agreeFirmaren){
          $scope.step4.tbCheckboxFirmaren.required = true;
          vm.scrollTo("tbCheckbox");
        } else if (vm.upsells.superfaktura && vm.upsells.superfaktura.active && !vm.upsells.superfaktura.agree) {
          $scope.step4.superfakturaCheckbox.required = true;
          vm.scrollTo("superfakturaCheckbox");
        } else if (vm.upsells.znamka && vm.upsells.znamka.active && !vm.upsells.znamka.agree) {
          $scope.step4.znamkaCheckbox.required = true;
          vm.scrollTo("znamkaCheckbox");
        } else if (vm.upsells.universal && vm.upsells.universal.active && (vm.upsells.universal.type.length === 0 || !vm.upsells.universal.agree || !vm.upsells.universal.agreeFirmaren)) {
          if(vm.upsells.universal.type.length === 0) {
            $scope.step4.upsellServicesError = true;
            vm.scrollTo("upsell-services");
          } else if(!vm.upsells.universal.agree) {
            $scope.step4.universalCheckbox.required = true;
            vm.scrollTo("universalCheckbox");
          } else if(!vm.upsells.universal.agreeFirmaren) {
            $scope.step4.universalCheckboxFirmaren.required = true;
            vm.scrollTo("universalCheckbox");
          }
        } else if (vm.upsells.saltpay && vm.upsells.saltpay.active && !vm.upsells.saltpay.agree) {
          $scope.step4.saltpayCheckbox.required = true;
          vm.scrollTo("saltpayCheckbox");
        } else if (vm.upsells.saltpay && vm.upsells.saltpay.active && !vm.upsells.saltpay.agreeFirmaren) {
          $scope.step4.saltpayCheckboxFirmaren.required = true;
          vm.scrollTo("saltpayCheckbox");
        } else {
          $scope.step4.$submitted = true;
          $window.location = "krok5";
        }
      }
    } else {
      if(!vm.order.additionalInfo) {
        vm.order.additionalInfo = {};
      }
      if(vm.order.additionalInfo.comment)
        vm.order.additionalInfo.comment = $filter("removeEmojiFilter")(vm.order.additionalInfo.comment);

      if($scope.step4.$invalid) {
        vm.requiredMessage = true;
        vm.submitted=true;
      } else {
        if (vm.showStreetNumberModals && checkStreetNumber()) {
          ModalStreetNumber()
              .add()
              .result
              .then();

          vm.showStreetNumberModals = false;
        } else {

          userLoad(true);

          contactInfoToOrder();
          if (vm.order.contactInfo && vm.order.contactInfo.address && !vm.order.contactInfo.address.country)
            vm.order.contactInfo.address.country = vm.countries[0]._2;

          service.setOtherInfo(vm.order)
              .then(() => {
                if(steps){
                  vm.valid = true;
                } else {
                  vm.intermediateStep = true;
                  vm.scrollTo("upsells");
                }
              })
              .catch((e) => console.log(`todo: ${e}`));
        }
      }
    }
  }

  function onSaveForLaterClick() {
    contactInfoToOrder();
    ModalFinishLater(service, JSON.stringify(vm.order))
      .add(service, JSON.stringify(vm.order))
      .result
      .then();
  }

  function userLoad(lastStep) {
    onEmailChange();
    onPasswordChange(lastStep);
    if(!lastStep)
      onUserChange();
  }


  function onOtherInfoLoad(order) {
    if(order) {
      vm.order = order;
      if(vm.order.registerDate)
        vm.order.registerDate = new Date(vm.order.registerDate);

      if(vm.order && vm.order.contactMe === undefined)
        vm.order.contactMe = true;

      userLoad();
    } else {
      service.getStringContent().then((data) => {
        if(data) {
          vm.order = JSON.parse(data);

          if(vm.order.contactInfo) {
            vm.contactInfo.name = vm.order.contactInfo.name;
            vm.contactInfo.surname = vm.order.contactInfo.surname;
            vm.contactInfo.address = vm.order.contactInfo.address;
            vm.contactInfo.dateOfBirth = vm.order.contactInfo.dateOfBirth;
          }

          if(vm.order && vm.order.contactMe === undefined)
            vm.order.contactMe = true;

          userLoad();
        }
      });
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onPasswordChange(lastStep){
    if(vm.order.contactInfo && vm.order.contactInfo.email) {
      server.login({
        email: vm.order.contactInfo.email,
        password: vm.order.contactInfo.password
      }).then(({success, data}) => {
        if (success) {
          vm.errorMessage = null;
          if(!lastStep)
            onUserChange();
          $rootScope.$broadcast('user-changed-contact');
        } else {
          vm.errorMessage = "bad";
        }
      }).catch(vm.errorMessage = "bad");
    }
  }

  function onUserChange() {
    vm.errorMessage = null;
    server.getLoggedUser().then(onLoggedUserLoad);
  }

  function onLoggedUserLoad(user) {
    vm.loggedUser = user;
    if(!vm.order.contactInfo) {
      vm.order.contactInfo = {};
    }
    if(user) {
      vm.order.contactInfo.email = user.email;
      vm.order.contactInfo.phoneNumber = $filter("whitespaceFilter")(user.phoneNumber);
      vm.order.contactInfo.name = user.firstName;
      vm.order.contactInfo.surname = user.lastName;
      vm.order.contactInfo.address = user.address;
      vm.order.contactInfo.dateOfBirth = user.dateOfBirth;
      vm.order.contactInfo.hasCompany = user.hasCompany;
      vm.order.contactInfo.companyName = user.companyName;
      vm.order.contactInfo.ico = user.ico;
      vm.order.contactInfo.dic = user.dic;
      vm.order.contactInfo.icDph = user.icDph;

      if(vm.loggedUser.credit > 0) {
        service.getUsedCredits().then((credits) => {
          if(credits > 0) {
            vm.isCredit = true;
          }
        });
      }

      if(user.firstName !== "" && user.lastName !== "") {
        vm.showContactInfoForm = true;
        vm.showContactInfoSelect = false;
      }
    }

    if(!vm.order.contactInfo.phoneNumber) {
      vm.order.contactInfo.phoneNumber = "+421";
    }
  }

  function onEmailChange() {
    vm.loginDisabled = true;
    vm.errorMessage = "";
    if(vm.order.contactInfo && vm.order.contactInfo.email) {
      vm.loginSpinner = true;
      server.isFree({email: vm.order.contactInfo.email}).then(({success, data}) => {
        vm.loginSpinner = false;
        vm.loginDisabled = false;
        if (success) {
          vm.userExists = false;
        } else {
          if (vm.order.contactInfo && vm.order.contactInfo.password && vm.order.contactInfo.password !== "")
            onPasswordChange();
          vm.userExists = true;
        }
      }).catch(vm.userExists = false);
    }
  }

  function onResetCountry() {
    vm.order.invoiceTo.address.country = "";
    $interval(function () {
      let element = $window.document.getElementById("address.country");
      if (element)
        element.focus();
    }, 100, 1);
  }
}

angular.module("firmaren")
  .controller("ZalozenieSroKrok4Controller", ZalozenieSroKrok4Controller);
}());
