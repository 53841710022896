;(function() {
/* @ngInclude */
function loadingFactory($timeout, $q) {
  return loading;

  /**
   * Returns a promise that is resolved / rejected as provided `promise`
   * but not sooner than `delay` milliseconds. Useful for showing loading
   * indicators that don't flash user too quickly.
   */
  function loading(promise, delay) {
    return $q.all([
      $timeout(() => {}, delay),
      promise
    ]).then(([undef, result]) => result);
  }
}

angular.module("firmaren")
  .factory("loading", loadingFactory);
}());
